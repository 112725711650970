import React, { useEffect, useMemo, useState } from 'react';

import { urlLabel } from '@/enum/PermissionEnum';
import validateData from '@/helpers/validationHelpers/validationSchema';
import customToast from '@/new-components/CustomNotification';
import CustomStepper from '@/new-components/CustomStepper';
import { Box, Button, Dialog } from '@material-ui/core';
import {
  capitalize,
  cloneDeep,
  findIndex,
  get,
  isBoolean,
  isEmpty
} from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Redirect, useHistory } from 'react-router-dom';
import { packageDispatcher } from '../package-management';
import dispatcher from './action';
import GeneralInformation from './components/GeneralInformation';
import ModalImportTemplate from './components/ModalImportTemplate';
import OperationDate from './components/OperationDate';
import SetupTime from './components/SetupTime';
import { FORMAT_DATEOFF, MAX_TIME_PICK, MIN_TIME_PICK } from './constants';
import { dayOfWeeks, errsPosition, formatTimeToSendServer } from './utils';
import CustomPaperContainer from '@/new-components/CustomPaperContainer';
import CustomHeader from '@/new-components/CustomPaperContainer/CustomHeader';
import locationDispatcher from './action';
import { StepperButton } from '@/new-components/CustomStepper/styled';
import RestrictServices from './components/RestrictServices';
import serviceDispatcher from '../services/action';

const FILE_SIZE = 2 * 1024 * 1024; // ~2mb

const CreateAndEditLocation = props => {
  const {
    location: { state }
  } = props;

  const [formGeneral, setFormGeneral] = useState({
    unit: '',
    address: '',
    postalCode: '',
    name: '',
    locationType: '',
    locationStatus: 'Active',
    imageUrl: ''
  });

  const [dataTimeSlots, setDataTimeSlot] = useState(
    dayOfWeeks.map(i => ({
      ...i,
      startTime: MIN_TIME_PICK,
      endTime: MAX_TIME_PICK,
      timeSlots: []
    }))
  );

  const [dataDayOffs, setDataDayOffs] = useState([]);
  const [maxTimeImport, setMaxTimeImport] = useState(MAX_TIME_PICK);
  const [errors, setErrors] = useState({});
  const [errorsIndex, setErrorsIndex] = useState([]);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [currentSubTab, setCurrentSubTab] = useState(0);
  const [openModalImportTemplate, setOpenModalImportTemplate] = useState(false);
  const [listPackages, setListPackages] = useState([]);
  const [listProcedures, setListProcedures] = useState([]);
  const [listRadiologies, setListRadiologies] = useState([]);

  const [restrictedPackageIds, setRestrictedPackageIds] = useState([]);
  const [restrictedTestIds, setRestrictedTestIds] = useState([]);

  const [currentStep, setCurrentStep] = useState(0);

  const _tempEditUrl = '/setup/location/edit-location';

  let isEdit = !!state;

  const goBackPreviousPage = () => {
    /** Handle remove localStorage item `editLocationId` */
    handleLocalStorage('editLocationId');
    history.push(`/${urlLabel.locationManagement}`);
  };

  /**
   *  Function to handle to cache editing item id on Edit mode.
   *  Notes:
   *    . Without param `value` equal to remove localStorage item based on `key` param
   */
  const handleLocalStorage = (key, value) => {
    if (key?.length && Boolean(value)) {
      window.localStorage.setItem(key, value);
      return;
    }
    window.localStorage.removeItem(key);
  };

  useEffect(() => {
    const { timeSlots: currentTimeSlots, startTime } = dataTimeSlots[
      currentSubTab
    ];
    if (currentTimeSlots.length === 0) {
      setMaxTimeImport(startTime);
    } else {
      setMaxTimeImport(currentTimeSlots[currentTimeSlots.length - 1].endTime);
    }
  }, [dataTimeSlots, currentSubTab]);

  useEffect(() => {
    if (history.location.pathname === _tempEditUrl) {
      !Boolean(window.localStorage.getItem('editLocationId')) &&
        handleLocalStorage('editLocationId', state?.id);
    } else handleLocalStorage('editLocationId');

    const isEditing =
      (state && state.id) || history.location.pathname === _tempEditUrl;
    if (Boolean(isEditing)) {
      isEdit = true;
      dispatcher.getDetailLocation(
        state?.id || window.localStorage.getItem('editLocationId'),
        data => {
          const {
            timeSlots,
            startTime,
            endTime,
            dayOfWeeks,
            dayOffs,
            restrictedPackageIds,
            restrictedTestIds,
            ...restParam
          } = data;
          setRestrictedPackageIds(restrictedPackageIds);
          setRestrictedTestIds(restrictedTestIds);
          setFormGeneral({ ...restParam });
          setDataTimeSlot(cloneDeep(dayOfWeeks));
          setDataDayOffs(cloneDeep(dayOffs));
        }
      );
    }
    packageDispatcher.getAllPackages(result => setListPackages(result));
    serviceDispatcher.getAllServicesWithNoneStation('Procedures', result =>
      setListProcedures(result)
    );
    serviceDispatcher.getAllServicesWithNoneStation('Radiologies', result =>
      setListRadiologies(result)
    );

    return () => {
      if (history.location.pathname !== _tempEditUrl) {
        handleLocalStorage('editLocationId');
      }
    };
  }, []);

  const title =
    isEdit || window.localStorage.getItem('editLocationId')
      ? 'Edit location'
      : 'Create new location';

  const handleChangeFormGeneral = key => e => {
    if (!setFormGeneral) return;
    let value;
    switch (key) {
      case 'imageUrl':
        if (get(e, 'size') > FILE_SIZE) {
          customToast('error', 'Image is too large');
          return;
        }
        value = e;
        break;
      case 'locationStatus':
        value = e.target.checked ? 'Active' : 'InActive';
        break;
      case 'postalCode':
        // Regex labnum number contain only number without dot and comma symbol
        const regexNumber = /^[0-9\b]+$/;
        let newValue = get(e, 'target.value');
        if (regexNumber.test(newValue)) value = newValue;
        break;
      default:
        value = e.target.value;
        break;
    }

    if (formGeneral?.locationType !== 'Clinic') {
      setFormGeneral(prevState => ({
        ...prevState,
        imageUrl: '',
        [key]: value
      }));
    }

    setFormGeneral(prevState => ({
      ...prevState,
      [key]: value
    }));
  };

  const handleOnChangeNumber = (key, value) => {
    if (!value) setFormGeneral({ ...formGeneral, [key]: '' });

    if (value && !value.match(/\D/)) {
      setFormGeneral({ ...formGeneral, [key]: value });
    }
  };

  const handleChangeTag = value => {
    setFormGeneral({
      ...formGeneral,
      tag: formGeneral.tag.includes(value)
        ? [...formGeneral?.tag?.filter(item => item !== value)]
        : [...formGeneral?.tag, value]
    });
  };

  const handleChangeFormTimeSlot = (key, value) => {
    let newDataTimeSlot = cloneDeep(dataTimeSlots);
    newDataTimeSlot[currentSubTab][key] = value;
    setDataTimeSlot(newDataTimeSlot);
  };

  const renderToast = (isCreate, data, mainType) => {
    return customToast(
      'success',
      <span>
        <strong style={{ fontWeight: 600 }}>{data}</strong> has been
        successfully {isCreate ? 'created' : 'updated'}.
      </span>,
      isCreate ? `New ${mainType} created` : ` ${capitalize(mainType)} updated`
    );
  };
  const onSubmit = data =>
    !isEdit
      ? locationDispatcher.createLocation(data, () => {
          setLoading(false);
          goBackPreviousPage();
          renderToast(!isEdit, data.name, 'location');
        })
      : locationDispatcher.editLocation(formGeneral.id, data, () => {
          setLoading(false);
          goBackPreviousPage();
          renderToast(!isEdit, data.name, 'location');
        });

  const handleSubmit = async () => {
    let params = {
      ...formGeneral,
      dayOfWeeks: dataTimeSlots.map(
        ({ startTime, endTime, timeSlots, ...restParams }) => ({
          startTime: formatTimeToSendServer(startTime),
          endTime: formatTimeToSendServer(endTime),
          timeSlots: timeSlots.map(({ startTime, endTime, ...restParams }) => ({
            startTime: formatTimeToSendServer(startTime),
            endTime: formatTimeToSendServer(endTime),
            ...restParams
          })),
          ...restParams
        })
      ),
      dayOffs: dataDayOffs,
      locationStatus: formGeneral.locationStatus,
      restrictedPackageIds,
      restrictedTestIds
    };
    try {
      await validateData('locationSchema', params, data => {
        /** Handle remove localStorage item `editLocationId` */
        handleLocalStorage('editLocationId');
        setErrorsIndex([]);
        setErrors({});
        setLoading(true);
        onSubmit(data);
      });
    } catch (errs) {
      const errsIndex = errsPosition(errs);
      setErrors(errs);
      setErrorsIndex(errsIndex);
    }
  };

  const importDataTemplate = ({ timeSlots, selectedDay }) => {
    let newDataTimeSlots = cloneDeep(dataTimeSlots);

    selectedDay.forEach(day => {
      const indexTimeslot = findIndex(
        newDataTimeSlots,
        it => it.dayOfWeek === day
      );
      if (!newDataTimeSlots[indexTimeslot]) {
        newDataTimeSlots[indexTimeslot] = {};
      }
      newDataTimeSlots[indexTimeslot].timeSlots = timeSlots.map(slot => ({
        ...slot,
        id: undefined
      }));
    });

    setDataTimeSlot(newDataTimeSlots);
  };

  const onSwitchTimeSlotsTab = val => {
    onCancelEditTimeSlot(currentSubTab);
    setCurrentSubTab(val);
  };

  const onSaveDataTimeSlotsNew = currentDay => currentValue => {
    let newDataTimeSlots = cloneDeep(dataTimeSlots);
    const index = findIndex(
      dataTimeSlots[currentDay].timeSlots,
      slot =>
        moment(slot.startTime).diff(
          moment(currentValue.startTime),
          'minute'
        ) === 0
    );
    if (index >= 0) {
      newDataTimeSlots[currentDay].timeSlots[index] = {
        ...currentValue,
        isEdit: undefined
      };
    } else {
      newDataTimeSlots[currentDay].timeSlots.push({
        ...currentValue
      });
    }
    newDataTimeSlots[currentDay].timeSlots.sort((leftValue, rightValue) =>
      moment.utc(leftValue.startTime).diff(moment.utc(rightValue.startTime))
    );
    setDataTimeSlot(newDataTimeSlots);
  };

  const onEditTimeSlot = currentDay => currentValue => {
    let newDataTimeSlots = cloneDeep(dataTimeSlots);
    newDataTimeSlots[currentDay].timeSlots = newDataTimeSlots[
      currentDay
    ].timeSlots.map(slot => {
      if (slot?.startTime.diff(currentValue?.startTime) === 0) {
        return { ...slot, isEdit: true };
      }
      return { ...slot, isEdit: undefined };
    });
    setDataTimeSlot(newDataTimeSlots);
  };

  const onCancelEditTimeSlot = currentDay => {
    let newDataTimeSlots = cloneDeep(dataTimeSlots);
    newDataTimeSlots[currentDay].timeSlots = newDataTimeSlots[
      currentDay
    ].timeSlots.map(slot => ({ ...slot, isEdit: undefined }));
    setDataTimeSlot(newDataTimeSlots);
  };

  const onDeleteTimeSlot = currentDay => currentValue => {
    let newDataTimeSlots = cloneDeep(dataTimeSlots);
    newDataTimeSlots[currentDay].timeSlots = dataTimeSlots[
      currentDay
    ].timeSlots.filter(slot => {
      if (
        moment(slot.startTime).diff(
          moment(currentValue.startTime),
          'minute'
        ) === 0
      )
        return null;
      return slot;
    });
    setDataTimeSlot(newDataTimeSlots);
  };

  const onSelectDayOff = value => {
    const formattedValue = value.format(FORMAT_DATEOFF);
    const foundIndex = dataDayOffs.findIndex(d => d === formattedValue);
    if (foundIndex < 0) setDataDayOffs([...dataDayOffs, formattedValue]);
    else {
      const newDataDayOffs = cloneDeep(dataDayOffs);
      newDataDayOffs.splice(foundIndex, 1);
      setDataDayOffs(newDataDayOffs);
    }
  };

  const listTabs = [
    {
      name: 'General information',
      validKey: ['name', 'unit', 'address', 'postalCode', 'locationType'],
      body: (
        <GeneralInformation
          values={formGeneral}
          setFormGeneral={setFormGeneral}
          handleChangeForm={handleChangeFormGeneral}
          handleOnChangeNumber={handleOnChangeNumber}
          handleChangeTag={handleChangeTag}
          errors={errors}
        />
      ),
      error: !isEmpty(errors)
    },
    {
      name: 'Set up time',
      validKey: ['dayOfWeeks'],
      body: (
        <>
          <SetupTime
            dayOfWeeks={dayOfWeeks}
            currentSubTab={currentSubTab}
            dataTimeSlotsAllWeek={dataTimeSlots}
            onSwitchTimeSlotsTab={onSwitchTimeSlotsTab}
            onOpenModalImportTemplate={e => {
              e && e.preventDefault();
              setOpenModalImportTemplate(true);
            }}
            handleChangeForm={handleChangeFormTimeSlot}
            dataTimeSlots={dataTimeSlots[currentSubTab]}
            data={dataTimeSlots[currentSubTab]?.timeSlots}
            onEditTimeSlot={onEditTimeSlot(currentSubTab)}
            onDeleteTimeSlot={onDeleteTimeSlot(currentSubTab)}
            onSaveDataTimeSlotsNew={onSaveDataTimeSlotsNew(currentSubTab)}
            minTime={dataTimeSlots[currentSubTab]?.startTime}
            maxTime={dataTimeSlots[currentSubTab]?.endTime}
            errors={errors}
            setDataTimeSlot={setDataTimeSlot}
          />
        </>
      )
    },
    {
      name: 'Closing dates',
      body: (
        <OperationDate
          selectedDates={dataDayOffs}
          onSelectDate={onSelectDayOff}
        />
      )
    },
    {
      name: 'Restrict services',
      body: (
        <RestrictServices
          listProcedures={listProcedures}
          listRadiologies={listRadiologies}
          listPackages={listPackages}
          restrictedPackageIds={restrictedPackageIds}
          setRestrictedPackageIds={setRestrictedPackageIds}
          restrictedTestIds={restrictedTestIds}
          setRestrictedTestIds={setRestrictedTestIds}
        />
      )
    }
  ];

  const isEmptyFilterParams = useMemo(() => {
    if (listTabs[currentStep].validKey) {
      const filterKeys = listTabs[currentStep].validKey;
      if (filterKeys.includes('dayOfWeeks')) {
        const timeSlots = dataTimeSlots.map(item => item.timeSlots);
        const timeSlotsaHasData = timeSlots.filter(item => !isEmpty(item));
        let timeSlotsQuota = [].concat(...timeSlotsaHasData);
        timeSlotsQuota = timeSlotsQuota.map(item => item.quotaBase);

        if (!timeSlots.every(it => !it.length)) {
          return !isEmpty(timeSlotsQuota.filter(it => isEmpty(String(it))));
        }
        return timeSlots.every(it => !it.length);
      }

      return filterKeys.some(key =>
        isBoolean(formGeneral[key])
          ? !formGeneral[key]
          : isEmpty(formGeneral[key])
      );
    }
  }, [listTabs, currentStep, formGeneral, dataTimeSlots]);

  const StepperButtons = () => (
    <Box display="flex" justifyContent="flex-end" p={1.25}>
      <StepperButton
        className="secondary"
        disabled={currentStep === 0}
        onClick={() => setCurrentStep(currentStep - 1)}
      >
        Back
      </StepperButton>

      <StepperButton
        disabled={isEmptyFilterParams}
        onClick={() => {
          if (currentStep === listTabs.length - 1) {
            handleSubmit();
          } else setCurrentStep(currentStep + 1);
        }}
      >
        {currentStep === listTabs.length - 1
          ? !isEdit
            ? 'Create'
            : 'Save'
          : 'Next'}
      </StepperButton>
    </Box>
  );

  if (isEdit && !state) {
    return <Redirect to={`/${urlLabel.locationManagement}`} />;
  }
  return (
    <>
      <CustomPaperContainer
        header={
          <CustomHeader
            title={title}
            isSearch={false}
            renderButton={() => (
              <>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={goBackPreviousPage}
                >
                  Cancel
                </Button>
              </>
            )}
          />
        }
      >
        <CustomStepper
          disableNext
          steps={listTabs}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          renderButtons={StepperButtons}
        >
          <Box height="calc(100vh - 282px)" overflow="scroll">
            {listTabs[currentStep]?.body}
          </Box>
        </CustomStepper>
      </CustomPaperContainer>

      <Dialog
        maxWidth="lg"
        open={openModalImportTemplate}
        disableEnforceFocus
        onClose={() => setOpenModalImportTemplate(false)}
      >
        <ModalImportTemplate
          dayIndex={dayOfWeeks[currentSubTab]?.dayOfWeek}
          currentSubTab={currentSubTab}
          maxTimeImport={maxTimeImport}
          dataTimeSlots={dataTimeSlots}
          isModalOpen={openModalImportTemplate}
          onClose={() => setOpenModalImportTemplate(false)}
          saveDataImport={importDataTemplate}
        />
      </Dialog>
    </>
  );
};

CreateAndEditLocation.propTypes = {
  location: PropTypes.object.isRequired
};

export default CreateAndEditLocation;
