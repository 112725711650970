import { synthesize } from 'redux-dispatcher';

import { chain, isEmpty } from 'lodash';
import { ID_NO_STATION } from '@/module/checkin-patient-information/constants';
import moment from 'moment';
import { STATUS_STATION } from '../constants';
import { formatDataMeasurements } from '../utils';
import { createDownloadableLink, mergeArrayByKey } from '@/helpers';

const mapDispatchToAC = {
  getData: (id, cb) => async ({ Api }) => {
    let { result, status } = await Api.get(
      `PaxScreenings/staff-get-by-id/${id}`
    );
    if (status === 200) {
      const { lstTests } = result;
      const newLstTests = lstTests.map(test => {
        const { items } = test;
        const newItems = items.map(item => ({
          ...item,
          testName: item.name || '',
          testCode: item.code || '',
          testPrice: item.price || 0
        }));
        return { ...test, items: newItems };
      });
      cb && cb({ ...result, lstTests: newLstTests });
    }
  },
  updateData: (id, data, cb) => async ({ Api, getState }) => {
    let { status } = await Api.put(`PaxScreenings/${id}`, data);
    if (status === 200) {
      cb && cb(true);
    } else {
      cb && cb(false);
    }
  },
  getAllListTests: (data, cb) => async ({ Api }) => {
    let { result, status } = await Api.get(`Projects/staff-get-item-of-minc`, {
      ...data
    });
    if (status === 200) {
      cb && cb(result);
    }
  },
  completeCheckIn: (id, callback) => async ({ Api }) => {
    const { status } = await Api.put(`PaxScreenings/${id}/check-in`);
    status === 200 && callback && callback();
  },
  changeStatusToDone: ({ queueId, testId }, cb) => async ({ Api }) => {
    const { status } = await Api.put(`StationQueues/${queueId}/done`, {
      testId
    });
    if (status === 200) {
      cb && cb(true);
    } else {
      cb && cb(false);
    }
  },
  changeStatusToDoneNoneStation: ({ paxScreeningId, testId }, cb) => async ({
    Api
  }) => {
    const { status } = await Api.put(
      `PaxScreenings/${paxScreeningId}/non-station-tests/${testId}/done`,
      {}
    );
    if (status === 200) {
      cb && cb(true);
    } else {
      cb && cb(false);
    }
  },
  notifyPatient: id => async ({ Api, customToast }) => {
    const { status } = await Api.post(`StationQueues/${id}/notify-patient`, {
      id
    });
    status === 200 && customToast('success', 'Notify to patient success');
  },
  getTestProfiles: (projectId = '', callback) => async ({ Api }) => {
    let { result, status } = await Api.get(`Projects/staff-get-item-of-minc`, {
      isProfile: true,
      projectId
    });

    status === 200 && callback && callback(result);
  },
  getTestPackages: (projectId = '', callback) => async ({ Api }) => {
    let { result, status } = await Api.get(`Projects/staff-get-item-of-minc`, {
      IsPackage: true,
      projectId
    });

    status === 200 && callback && callback(result);
  },
  getListStationUnAssign: (paxScreeningId, cb) => async ({ Api }) => {
    let { result, status } = await Api.get(`Stations/un-assigned`, {
      paxScreeningId
    });

    status === 200 && cb && cb(result || []);
  },
  getAllListTestStation: (
    { healthScreeningId, patientId, paxScreeningId },
    callback
  ) => async ({ Api }) => {
    const statuses = [
      STATUS_STATION.Awaiting,
      STATUS_STATION.Done,
      STATUS_STATION.UnAssigned
    ];
    const responseStationQueue = await Api.post(`StationQueues/filter`, {
      paxScreeningId,
      healthScreeningId,
      patientId,
      statuses
    });
    let dataTests = [];
    const idStationOthers = ID_NO_STATION;
    const nameStationOthers = 'No Station';

    let lstStations = [];

    if (!responseStationQueue) return;

    if (responseStationQueue.status === 200) {
      const listTestStationQueue = responseStationQueue.result?.list || [];
      let dataTestTemps = [];
      listTestStationQueue.forEach(station => {
        const {
          stationName,
          stationId,
          status,
          tests,
          id,
          queueNo,
          stationOrder
        } = station;
        lstStations.push({
          id: stationId,
          name: stationName,
          status,
          queueId: id,
          queueNo,
          stationOrder
        });
        if (paxScreeningId === station.paxScreeningId) {
          dataTestTemps = tests.map(test => ({
            ...test,
            stationName,
            stationId,
            queueId: id
          }));
          dataTests = dataTests.concat(dataTestTemps);
        }
      });

      lstStations = [
        ...new Map(lstStations.map(item => [item['id'], item])).values()
      ];
    }
    const responseNoneStationTest = await Api.get(
      `PaxScreenings/${paxScreeningId}/non-station-tests`
    );
    if (!responseNoneStationTest) return;

    if (responseNoneStationTest.status === 200) {
      const listNoneStationTest = responseNoneStationTest.result || [];
      const stationOthers = {
        id: idStationOthers,
        name: nameStationOthers,
        queueId: '',
        status: STATUS_STATION.Done
      };
      dataTests = dataTests.concat(
        listNoneStationTest.map(it => {
          if (!it.done) {
            stationOthers.status = STATUS_STATION.Awaiting;
          }
          return {
            ...it,
            stationId: idStationOthers,
            stationName: nameStationOthers,
            status: it.done ? STATUS_STATION.Done : STATUS_STATION.Awaiting
          };
        })
      );
      if (listNoneStationTest.length > 0) {
        lstStations.push(stationOthers);
      }
    }
    // let dataTestTransform = transformDataTest(dataTests);
    // Remove station name: "Registration", special station for service without contain tests
    // Put station with AWAITING status to the top
    lstStations = lstStations
      .sort((a, b) => a.stationOrder - b.stationOrder)
      .filter(station => station.name !== 'Registration');
    // .concat(
    //   lstStations.filter(
    //     station => station.status !== STATUS_STATION.Awaiting
    //   )
    // );

    // Format data measurement
    const newDataMeasurement = dataTests.filter(
      test => test.category === 'Measurements'
    );
    dataTests = dataTests
      .filter(test => test.category !== 'Measurements')
      .concat(formatDataMeasurements(newDataMeasurement, []));

    // Remove station name: "Registration", special station for service without contain tests
    lstStations = lstStations.filter(
      station => station.name !== 'Registration'
    );

    // Filter test by station
    const dataTestsByStation = chain(dataTests)
      .filter(perm => perm)
      .groupBy('stationName')
      .map((value, key) => ({
        name: key,
        lstTests: value
      }))
      .value();
    lstStations = mergeArrayByKey(lstStations, dataTestsByStation, 'name');

    callback && callback({ dataTests, lstStations });
  },
  checkInStation: ({ paxScreeningId, stationId }, cb) => async ({ Api }) => {
    let { status } = await Api.post(`StationQueues/assign`, {
      paxScreeningId,
      stationId
    });

    if (status === 200) {
      cb && cb({ isSuccess: true });
    } else {
      cb && cb({ isSuccess: false });
    }
  },
  updateTestValue: ({
    category,
    queueId,
    paxScreeningId,
    measurementType,
    tests,
    cb = () => {}
  }) => async ({ Api }) => {
    let response = {};
    const params = {
      category,
      measurementType,
      tests
    };

    if (!queueId) {
      response = await Api.put(
        `PaxScreenings/${paxScreeningId}/non-station-tests`,
        params
      );
    } else {
      response = await Api.put(`StationQueues/${queueId}/update-test`, params);
    }
    const { status } = response;
    if (status === 200) {
      cb(true);
    } else {
      cb(false);
    }
  },
  updateOptOut: ({ queueId, testIds }, cb) => async ({ Api }) => {
    const { status } = await Api.put(`StationQueues/${queueId}/opt-out`, {
      testIds
    });
    if (status === 200) {
      cb && cb(true);
    } else {
      cb && cb(false);
    }
  },
  makeDoneAllTests: ({ stationQueueIds, category }, cb) => async ({ Api }) => {
    const { status } = await Api.put(`StationQueues/done-all`, {
      stationQueueIds,
      category
    });
    if (status === 200) {
      cb && cb(true);
    } else {
      cb && cb(false);
    }
  },
  makeDoneAllTestsNoneStation: ({ paxScreeningId, category }, cb) => async ({
    Api
  }) => {
    const { status } = await Api.put(
      `PaxScreenings/${paxScreeningId}/non-station-tests/done-category`,
      {
        category
      }
    );
    if (status === 200) {
      cb && cb(true);
    } else {
      cb && cb(false);
    }
  },
  updateOptOutNoneStation: ({ paxScreeningId, testId }, cb) => async ({
    Api
  }) => {
    const { status } = await Api.put(
      `PaxScreenings/${paxScreeningId}/non-station-tests/${testId}/opt-out`,
      {}
    );
    if (status === 200) {
      cb && cb(true);
    } else {
      cb && cb(false);
    }
  },
  getUserDetailById: (id, cb) => async ({ Api }) => {
    const { result, status } = await Api.get(`Users/${id}`, {});
    if (status === 200) {
      cb && cb(result);
    } else {
      cb && cb(false);
    }
  },
  setState: (name, value) => ({ name, value }),
  updateAfterRegistration: (paxScreeningId, params, cb) => async ({ Api }) => {
    const { status } = await Api.put(
      `PaxScreenings/${paxScreeningId}/edit-after-registration`,
      params
    );
    if (status === 200) {
      cb && cb(true);
    } else {
      cb && cb(false);
    }
  },
  rescheduleAppointment: (paxScreeningId, params, cb) => async ({ Api }) => {
    const { status } = await Api.put(
      `PaxScreenings/${paxScreeningId}/reschedule`,
      {
        ...params,
        date: moment(params.date).format('YYYY-MM-DD')
      }
    );
    if (status === 200) {
      cb && cb(true);
    } else {
      cb && cb(false);
    }
  },
  patientCheckIn: (id, data, callback) => async ({ Api }) => {
    const newData = { ...data };

    let dataLstTests = [];
    data.patientData.lstTests.forEach(it => {
      if (it.total) {
        dataLstTests = dataLstTests.concat(it.total);
      } else {
        dataLstTests = dataLstTests.concat(it);
      }
    });
    newData.patientData.lstTests = dataLstTests;
    const { status } = await Api.put(`PaxScreenings/${id}/check-in`, newData);
    if (status === 200) {
      callback && callback();
    }
  },
  generateLabNumber: (id, labProvider, callback) => async ({
    Api,
    customToast
  }) => {
    const response = await Api.post(`PaxScreenings/${id}/lab-number`, {
      labProvider
    });

    const { status } = response;
    if (status === 200) {
      customToast('success', 'Generate success');
      callback && callback(true);
    }
  },
  getAllPaxConsents: (data, callback) => async ({ Api }) => {
    const { result, status } = await Api.post(`PaxConsents/filter`, data);
    status === 200 && callback && callback(result);
  },
  getConsentsByPatientID: (id, callback) => async ({ Api }) => {
    const { result, status } = await Api.get(
      `PaxConsents/by-pax-screening/${id}`
    );
    status === 200 && callback && callback(result);
  },
  makeDoneAllTestsByStationId: (stationId, cb) => async ({ Api }) => {
    const { status } = await Api.put(
      `StationQueues/${stationId}/done-valid-tests`
    );
    if (status === 200) {
      cb && cb(true);
    } else {
      cb && cb(false);
    }
  },
  downloadIRReport: (id, callback) => async ({ Api, customToast }) => {
    const { status, result } = await Api.get(
      `AnalysisPaxScreenings/${id}/download-ir-report`
    );
    if (status === 200) {
      customToast('success', 'Download success');
      createDownloadableLink(result, 'Health Screening Report', 'pdf');
    }
  },
  regenerateIRReport: id => async ({ Api, customToast }) => {
    const { status } = await Api.put(
      `AnalysisPaxScreenings/${id}/regenerate-ir-report`
    );
    if (status === 200) {
      customToast('success', 'Regenerate success');
    }
  },
  doneAllConsents: (id, callback) => async ({ Api, customToast }) => {
    const { status } = await Api.put(`PaxScreenings/${id}/done-all-consents`);
    if (status === 200) {
      customToast('success', 'Update all consents success');
      callback && callback();
    }
  },
  createLabNumber: (id, params, callback) => async ({ Api, customToast }) => {
    const { status, message } = await Api.post(
      `PaxScreenings/${id}/input-lab-number`,
      params
    );

    if (status === 200) {
      customToast('success', 'Generate successs');
      callback && callback();
    }
    if (status === 400) {
      callback && callback(message);
    }
  },
  getRestrictLocationByIds: (data, callback) => async ({ Api }) => {
    const { result, status } = await Api.post(
      `Projects/get-restricted-locations`,
      data
    );
    status === 200 && callback && callback(result);
  },
  getRelatedPackagesByIds: ({ isRequired, testIds }, callback) => async ({
    Api
  }) => {
    const { result, status } = await Api.post(`Tests/related-packages`, {
      isRequired,
      testIds
    });
    status === 200 && callback && callback(result);
  },
  precalculateCost: (payload, cb) => async ({ Api }) => {
    const { result, status } = await Api.post(
      `PaxScreenings/pre-calculate`,
      payload
    );
    if (status === 200) {
      cb && cb(result);
    } else {
      cb && cb(null);
    }
  }
};

const allScreeningsDispatcher = synthesize('allScreenings', mapDispatchToAC);
export default allScreeningsDispatcher;
