import { configure } from '..';
import { roomStatus } from '../enum/RoomStatusEnum';
import minkConfig from '../mink.config.json';
import waitingRoomDispatcher from '../module/waiting-room/action/waitingRoom';
import NewAgoraInstance from '@/agora/agoraInstance4x';
import { ROLES_NAMES_SPECIAL, ROLE_TYPES } from '@/constants/roles';
import customToast from '@/new-components/CustomNotification';
import { isEmpty, transform, camelCase } from 'lodash';

const signalR = require('@aspnet/signalr');
let hubConnection;
let memberId;
let requestMemberId;

export const initHubConnection = (token = '') => {
  try {
    waitingRoomDispatcher.resetState();
    stopConnection();
    hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(process.env.REACT_APP_WAITING_ROOM_HUB_URL, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
        accessTokenFactory: () => token
      })
      .build();
    registerEvents();
    hubConnection.start().then(() => joinGroup());
    return hubConnection;
  } catch (err) {
    console.log(err);
  }
};

async function tryReconnect(connection) {
  try {
    // Add triggle to call rejoin connection when reconnecting internet
    waitingRoomDispatcher.setState('reconnectRejoinCall', true);
    let rs = await connection.start().then(() => joinGroup());
    console.log('Reconnected successful!');
    return rs;
  } catch (e) {
    await new Promise(resolve => setTimeout(resolve, 5000));
    return await tryReconnect(connection);
  }
}

export const stopConnection = () => {
  try {
    if (hubConnection) hubConnection.stop();
  } catch (err) {
    console.log(err);
  }
};

export const joinGroup = () => {
  try {
    hubConnection.invoke('DoctorJoinGroup');
  } catch (error) {
    console.log(error);
  }
};

export const registerEvents = () => {
  let stopTimer;
  hubConnection.onclose(function(errors) {
    if (!errors) return;
    const isCalling = configure.store.getState().waitingRoom.calling;
    if (isCalling) tryReconnect(hubConnection);
    customToast('error', 'Your connection has been disconnected.');
  });

  hubConnection.on('PatientWaitingList', list => {
    try {
      if (isEmpty(list)) return;
      const currentUser = configure.store.getState().auth.userInfo;
      let newList = list;

      const newWaitingListTeleArt = list.filter(
        i => i.ConsultType === 'TeleArt'
      );
      const newWaitingListTeleDoc = list.filter(
        i => !['TeleArt', 'TeleCounsel'].includes(i.ConsultType)
      );

      const newWaitingListAdmin = list.filter(
        i => i.ConsultType !== 'TeleCounsel'
      );

      if (currentUser.roleName === 'Doctor') newList = newWaitingListTeleDoc;
      if (currentUser.roleName === 'Swabber') newList = newWaitingListTeleArt;
      if (!ROLES_NAMES_SPECIAL.includes(currentUser.roleName))
        newList = newWaitingListAdmin;

      newList.forEach(i => {
        let data = [i];
        let status = data[0].PatientRoomStatus;

        switch (status) {
          case roomStatus.Join:
            data.map(m => customToast('info', `${m.FullName} has joined room`));
            waitingRoomDispatcher.onPTJoinRoom(data, true);
            break;
          case roomStatus.Leave:
            data.map(m => customToast('info', `${m.FullName} has left room`));
            waitingRoomDispatcher.onPTJoinRoom(
              data.map(d => d.UserId),
              false
              // requestMemberId === data[0].UserId
            );
            break;
          case roomStatus.Calling:
            data.map(m =>
              customToast(
                'info',
                `${m.FullName} has picked up by another doctor`
              )
            );
            waitingRoomDispatcher.onRemovePT(
              data[0],
              true
              // requestMemberId === data[0].UserId
            );
            break;
          case roomStatus.EndCall: {
            customToast('info', `${data[0].FullName} has ended the consult`);
            NewAgoraInstance.instance.leaveChannel({
              leaveOnlyShareScreen: false
            });
            clearTimeout(stopTimer);
            waitingRoomDispatcher.setState('consConfirmVisibility', true);
            waitingRoomDispatcher.setState('rejoinCallData', null);
            waitingRoomDispatcher.setState('callId', data[0]?.CallId);
            break;
          }

          case roomStatus.ApproveIdentity:
            data.map(m =>
              customToast('info', `${m.FullName} has been approved`)
            );
            waitingRoomDispatcher.onRemovePT(data[0]);
            break;
          case roomStatus.RejectIdentity:
            data.map(m =>
              customToast('info', `${m.FullName} has been rejected by SO`)
            );
            waitingRoomDispatcher.onRemovePT(data[0]);
            break;
          // case roomStatus.AcceptCall:
          //   waitingRoomDispatcher.setState('patientId', data[0].UserId);
          //   joinCall(data[0].UserId);
          //   waitingRoomDispatcher.onRemovePT(data[0]);
          //   break;
          case roomStatus.RejectCall:
            waitingRoomDispatcher.setState('waitingModalVisibility', false);
            customToast('info', `${data[0].FullName} has rejected the consult`);
            break;
          case roomStatus.CancelRejoinCall:
            waitingRoomDispatcher.setState('rejoinCallData', null);

            customToast('info', `Your patient has cancelled the consult`);
            clearTimeout(stopTimer);
            NewAgoraInstance.instance.leaveChannel({
              leaveOnlyShareScreen: false
            });
            break;
          case roomStatus.PatientRejoinCall:
            customToast('info', `Your patient has rejoined`);
            clearTimeout(stopTimer);
            break;
          case roomStatus.CallProblem:
            stopTimer = setTimeout(() => {
              customToast(
                'info',
                `The consult was cancelled due to connection problem`
              );
              NewAgoraInstance.instance.leaveChannel({
                leaveOnlyShareScreen: false
              });
            }, minkConfig.PATIENT_REJOIN_TIMEOUT * 1000);
            customToast('info', `CallProblem happen`);
            break;
          default:
            waitingRoomDispatcher.onGetWaitingList(data);
            break;
        }
      });
    } catch (err) {
      console.log(err);
    }
  });

  hubConnection.on('CallInformation', data => {
    waitingRoomDispatcher.setState('startCallTime', data.StartCallTime);
    const currentUser = configure.store.getState().auth.userInfo;
    const accessToken = configure.store.getState().auth.accessToken;
    try {
      switch (data.NotificationType) {
        case roomStatus.WaitingRejoinCall:
          waitingRoomDispatcher.setState('rejoinCallData', data);
          break;
        case roomStatus.SuccessRejoinCall:
          waitingRoomDispatcher.setState('channelId', data.ChannelId);
          waitingRoomDispatcher.setState('callId', data.CallId);
          waitingRoomDispatcher.setState('calling', true);
          waitingRoomDispatcher.setState('rejoinCallData', null);
          // save memberAppointments
          let memberAppointments = data?.MemberAppointments?.map(
            upperCaseData => {
              return transform(upperCaseData, function(result, val, key) {
                result[camelCase(key)] = val;
              });
            }
          );
          waitingRoomDispatcher.setState(
            'memberAppointment',
            memberAppointments
          );
          break;
        case roomStatus.PatientUnavailable:
          customToast('info', `Your consult is expired`);
          clearTimeout(stopTimer);
          waitingRoomDispatcher.setState('callId', data.CallId);
          waitingRoomDispatcher.setState('consConfirmVisibility', true);
          waitingRoomDispatcher.setState('rejoinCallData', null);
          break;
        case roomStatus.CallInfo:
          waitingRoomDispatcher.setState('callId', data.CallId);
          waitingRoomDispatcher.setState('waitingModalVisibility', false);
          waitingRoomDispatcher.setState('channelId', data.ChannelId);
          waitingRoomDispatcher.setState('calling', true);
          waitingRoomDispatcher.onRemovePT(data);
          // bulk selectMemberAppointment
          const memberAppointment = configure.store.getState().waitingRoom
            .memberAppointment;
          bulkSelectMemberAppointment(memberAppointment);
          break;

        case roomStatus.PlatoError:
          customToast('error', `The Plato API not responding!`);
          //resset state waitingRoom
          // waitingRoomDispatcher.resetState();
          NewAgoraInstance.instance.leaveChannel({
            leaveOnlyShareScreen: false,
            consConfirmVisibility: false
          });
          // reconnect hub
          initHubConnection(accessToken);
          break;

        default:
          break;
      }
    } catch (err) {
      console.log(err);
    }
  });
};

export const requestCall = async memberId => {
  try {
    requestMemberId = memberId;
    const rs = await hubConnection.invoke('RequestCallPatient', memberId);
    return rs;
  } catch (error) {
    console.log(error);
  }
};

export const joinCall = async channelId => {
  try {
    memberId = channelId;
    waitingRoomDispatcher.setState('patientId', channelId);
    waitingRoomDispatcher.onPTJoinRoom([channelId], false);
    const rs = await hubConnection.invoke('CallPatient', memberId);
    return rs;
  } catch (error) {
    console.log(error);
  }
};

export const rejoinCall = async (isJoin = true) => {
  try {
    const reconnectRejoinCall = configure.store.getState().waitingRoom
      .reconnectRejoinCall;
    if (reconnectRejoinCall)
      waitingRoomDispatcher.setState('reconnectRejoinCall', false);
    const rs = await hubConnection.invoke('RejoinCall', isJoin);
    return rs;
  } catch (error) {
    console.log(error);
  }
};

export const shareScreenToPatient = async (streamId = '') => {
  try {
    const memberId = configure.store.getState().waitingRoom.patientId;
    let rs = await hubConnection.invoke('ShareScreen', memberId, streamId);
    return rs;
  } catch (error) {
    console.log(error);
  }
};

export const endCall = async memberId => {
  try {
    let rs = await hubConnection.invoke('EndCallPatient', memberId);
    return rs;
  } catch (error) {
    console.log(error);
  }
};

export const approvePatient = async memberId => {
  try {
    let rs = await hubConnection.invoke('NotifyApprovedPatient', memberId);
    customToast('success', 'Approve Successful');
    return rs;
  } catch (error) {
    console.log(error);
  }
};

export const rejectPatient = async memberId => {
  try {
    let rs = await hubConnection.invoke('RejectPatient', memberId);
    waitingRoomDispatcher.onRemovePT(memberId);
    waitingRoomDispatcher.setState('consConfirmVisibility', false);
    customToast('success', 'Reject Successful');
    return rs;
  } catch (error) {
    console.log(error);
  }
};

export const selectMemberAppointment = async (apptId, isSelected) => {
  try {
    let rs = await hubConnection.invoke('SelectMemberAppt', apptId, isSelected);
    return rs;
  } catch (error) {
    console.log(error);
  }
};

export const bulkSelectMemberAppointment = async memberAppointment => {
  try {
    let rs = await Promise.all(
      memberAppointment
        ?.filter(data => data.isSelected)
        ?.map(data =>
          selectMemberAppointment(data?.appointmentId, data?.isSelected)
        )
    );
  } catch (error) {
    console.log(error);
  }
};

export const unSelectAlltMemberAppointment = async () => {
  try {
    let rs = await hubConnection.invoke('SelectAllMemberAppts', false);
    return rs;
  } catch (error) {
    console.log(error);
  }
};
