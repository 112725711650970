import isEmpty from 'lodash/isEmpty';
import { synthesize } from 'redux-dispatcher';
import { uploadImage } from '../../../firebase';
import customToast from '@/new-components/CustomNotification';

const mapDispatchToAC = {
  updatePatient: (payload, callbackSuccess) => async ({ Api, toastr }) => {
    let corporatePlans = [];

    if (!isEmpty(payload.corporatePlans)) {
      corporatePlans = await Promise.all(
        payload.corporatePlans.map(async file => {
          if (
            file.frontPhoto?.constructor === File &&
            file.backPhoto?.constructor === File
          ) {
            let fontUrl = await uploadImage(file.frontPhoto);
            let backUrl = await uploadImage(file.backPhoto);

            return { ...file, frontPhoto: fontUrl, backPhoto: backUrl };
          } else {
            // Front Photo
            if (file.frontPhoto?.constructor === File) {
              let url = await uploadImage(file.frontPhoto);
              return { ...file, frontPhoto: url };
            }
            // Back Photo
            else if (file.backPhoto?.constructor === File) {
              let url = await uploadImage(file.backPhoto);
              return { ...file, backPhoto: url };
            } else return file;
          }
        })
      );
    }

    const { status } = await Api.put('Patients/info', {
      ...payload,
      corporatePlans
    });
    if (status === 200) {
      callbackSuccess();
      return customToast('success', 'Your profile is updated successfully');
    }
  },
  getPatientList: (
    search,
    paging,
    sortOption = {},
    filterOption = {}
  ) => async ({ Api, getState }) => {
    const pagingData = paging || getState().patient.paging;
    const { pageIndex, pageSize } = pagingData;

    const { result, status } = await Api.post(`Patients/filter`, {
      pageIndex,
      pageSize,
      search,
      ...sortOption,
      ...filterOption
    });

    status === 200 && patientDispatcher.getPatientListSuccess(result);
  },
  getPatientListSuccess: payload => payload,
  approvePatient: (payload, callbackSuccess) => async ({
    Api,
    customToast
  }) => {
    const { status } = await Api.post(`Patients/approve`, payload);
    if (status === 200) {
      patientDispatcher.approvePatientSuccess(payload);
      callbackSuccess(payload.userId);
      customToast('success', 'Success');
      return;
    }
  },
  approvePatientSuccess: payload => payload,
  deletePatient: (userId, callbackSuccess) => async ({ Api, customToast }) => {
    let { status } = await Api.delete(`Users/${userId}`);
    if (status === 200) {
      customToast('success', 'Deleted successfully');
      callbackSuccess();
    }
  },
  inActiveOrActivePatient: payload => async ({ Api, customToast }) => {
    const { status } = await Api.post(`Patients/active`, payload);
    if (status === 200) {
      patientDispatcher.inActiveOrActivePatientSuccess(payload);
      customToast('success', 'Success');
    }
  },
  inActiveOrActivePatientSuccess: payload => payload
};

const patientDispatcher = synthesize('patient', mapDispatchToAC);
export default patientDispatcher;
