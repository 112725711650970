import {
  USER_GENDER,
  FORMAT_DD_MM_YYYY,
  ID_TYPE,
  EditableHsStatus
} from '../../constants';
import { DetailWrapped, WrapperButtonUpload } from './styled';
import { AddImageOutlineIcon } from '@/assets/svg';
import PhoneInput from '@/components/PhoneInput';
import { doctorStatus } from '@/enum/RequestEnum';
import { renderUnitNumber } from '@/helpers';
import { UploadSingle } from '@/module/common/component';
import ControlDatepicker from '@/module/common/component/ControlDatepicker';
import DividerHeader from '@/module/common/componentUI/DividerHeader';
import {
  Typography,
  Grid,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment
} from '@material-ui/core';
import { LocalizationProvider } from '@material-ui/pickers';
import MomentUtils from '@material-ui/pickers/adapter/moment';
import { isEmpty, get, cloneDeep, set } from 'lodash';
import React from 'react';

const PersonalInformation = ({
  personalInfo,
  setPersonalInfo,
  errors,
  lstIdentityUrl
}) => {
  const handleChange = key => e => {
    let value;
    switch (key) {
      case 'dateOfBirth':
        value = e.toISOString();
        break;
      default:
        value = get(e, 'target.value');
        break;
    }
    setPersonalInfo({
      ...personalInfo,
      [key]: value,
      countryCode: isEmpty(personalInfo.countryCode)
        ? 'SG'
        : key === 'countryCode'
        ? value
        : personalInfo.countryCode
    });
  };

  const handleChangeIdentityType = e => {
    setPersonalInfo({
      ...personalInfo,
      identityType: e.target.value,
      nric: ''
    });
  };

  const handleChangeAddress = data => {
    setPersonalInfo({
      ...personalInfo,
      address: data.description || '',
      latitude: data.lat || 0,
      longitude: data.lng || 0
    });
  };
  const handleChangeIdentity = (data, field) => {
    const payload = cloneDeep(personalInfo);
    set(payload, field, data);
    setPersonalInfo(payload);
  };

  const unitNumber = renderUnitNumber(
    personalInfo.floor,
    personalInfo.unitNumber
  );

  return (
    <DetailWrapped>
      <LocalizationProvider dateAdapter={MomentUtils}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <DividerHeader title="General Information" />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Full Name"
              value={personalInfo.fullName || ''}
              error={!!errors.fullName}
              helperText={errors.fullName}
              onChange={handleChange('fullName')}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="User ID"
              value={personalInfo.patientShortId || ''}
              disabled
              variant="filled"
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel id="id-label-gender">Gender</InputLabel>
            <Select
              fullWidth
              labelId="id-label-gender"
              value={personalInfo.gender || ''}
              onChange={handleChange('gender')}
            >
              {USER_GENDER.map(item => (
                <MenuItem value={item.value}>{item.label}</MenuItem>
              ))}
            </Select>
            {errors.gender && (
              <Typography color="error">{errors.gender}</Typography>
            )}
          </Grid>
          <Grid item xs={6}>
            <ControlDatepicker
              value={personalInfo.dateOfBirth}
              onChange={handleChange('dateOfBirth')}
              label="Date Of Birth"
              error={!!errors.dateOfBirth}
              inputFormat={FORMAT_DD_MM_YYYY}
              disableFuture
              grid={12}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Company Code"
              disabled={!EditableHsStatus.includes(personalInfo.lastHsStatus)}
              value={personalInfo.projectCode || ''}
              onChange={handleChange('projectCode')}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="number"
              label="User Value"
              disabled
              variant="filled"
              value={personalInfo.totalMoneyUsed}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel id="id-label-identity">Type of ID</InputLabel>
            <Select
              fullWidth
              labelId="id-label-identity"
              value={personalInfo.identityType || ''}
              onChange={handleChangeIdentityType}
            >
              {ID_TYPE.map(item => (
                <MenuItem value={item.value}>{item.label}</MenuItem>
              ))}
            </Select>
            {errors.identityType && (
              <Typography color="error">{errors.identityType}</Typography>
            )}
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="NRIC/Passport"
              value={personalInfo.nric || ''}
              error={!!errors.nric}
              helperText={errors.nric}
              onChange={handleChange('nric')}
            />
          </Grid>

          {personalInfo.approveStatus === doctorStatus.Approved ? null : (
            <Grid container spacing={4} style={{ margin: 0 }}>
              <Grid item xs={6}>
                <UploadSingle
                  typeUpload="normal"
                  accept=".jpg,.png,.jpeg"
                  onChange={data =>
                    handleChangeIdentity(data, 'lstIdentityUrl[0]')
                  }
                  imageUrlSelected={
                    !isEmpty(lstIdentityUrl) && lstIdentityUrl[0]
                  }
                  uploadButton={
                    <WrapperButtonUpload>
                      <span className="icon">
                        <AddImageOutlineIcon color="#ccc" />
                      </span>{' '}
                      <span className="text">Add ID Picture</span>
                    </WrapperButtonUpload>
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <UploadSingle
                  typeUpload="normal"
                  accept=".jpg,.png,.jpeg"
                  onChange={data =>
                    handleChangeIdentity(data, 'lstIdentityUrl[1]')
                  }
                  imageUrlSelected={
                    !isEmpty(lstIdentityUrl) && lstIdentityUrl[1]
                  }
                  uploadButton={
                    <WrapperButtonUpload>
                      <span className="icon">
                        <AddImageOutlineIcon color="#ccc" />
                      </span>{' '}
                      <span className="text">Add ID Picture</span>
                    </WrapperButtonUpload>
                  }
                />
              </Grid>
              {errors.lstIdentityUrl && (
                <Typography style={{ marginLeft: 16 }} color="error">
                  {errors.lstIdentityUrl}
                </Typography>
              )}
            </Grid>
          )}

          <Grid item xs={12}>
            <DividerHeader title="Contact" />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Email"
              value={personalInfo.email || ''}
              error={!!errors.email}
              helperText={errors.email}
              onChange={handleChange('email')}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              disabled={!personalInfo.isParent}
              error={!!errors.mobile}
              helperText={errors.mobile}
              id="phone-input"
              label="Mobile number"
              value={personalInfo.mobile}
              onChange={handleChange('mobile')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneInput
                      country={personalInfo.countryCode}
                      disabled={!personalInfo.isParent}
                      onChange={handleChange('countryCode')}
                    />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Address"
              value={personalInfo.address || ''}
              onChange={handleChange('address')}
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Unit Number"
              value={unitNumber || ''}
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Postal Code"
              type="number"
              value={personalInfo.postalCode || ''}
              onChange={handleChange('postalCode')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">S</InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <DividerHeader title="Other" />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Highlight"
              value={personalInfo.highlight || ''}
              onChange={handleChange('highlight')}
            />
          </Grid>
        </Grid>
      </LocalizationProvider>
    </DetailWrapped>
  );
};

export default PersonalInformation;
