import Enum from '@/enum/Enum';
import moment from 'moment';

const FORMAT_DATE_YEAR = 'MMMM, yyyy';
const FORMAT_TIME = 'HH:mm';
const FORMAT_TIME_LT = 'LT';
const FORMAT_DATE = 'DD-MM-YYYY';
const FORMAT_DD_MM_YYYY = 'DD/MM/yyyy';
const FORMAT_YYYY_MM_DD = 'YYYY-MM-DD';
const DEFAULT_START_TIME = '13:00';
const DEFAULT_END_TIME = '18:00';
const FORMAT_DATE_TIME = 'DD-MM-YYYY:HH:mm';
const DAYS_PER_WEEK = 7;
const TIME_ZONE_SINGAPORE = 8;
const MIN_TIME_PICK = moment().startOf('date');

const MAX_TIME_PICK = moment().endOf('date');

const DAYS_OF_WEEK = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 0
};

export const DAYS_OF_WEEK_TEMPLATE = [
  { key: 'Mon', value: 'Monday' },
  { key: 'Tue', value: 'Tuesday' },
  { key: 'Wed', value: 'Wednesday' },
  { key: 'Thu', value: 'Thursday' },
  { key: 'Fri', value: 'Friday' },
  { key: 'Sat', value: 'Saturday' },
  { key: 'Sun', value: 'Sunday' }
];

const VALUE_DAY_OF_WEEK = [
  {
    value: 0,
    name: 'Sunday'
  },
  {
    value: 1,
    name: 'Monday'
  },
  {
    value: 2,
    name: 'Tuesday'
  },
  {
    value: 3,
    name: 'Wednesday'
  },
  {
    value: 4,
    name: 'Thursday'
  },
  {
    value: 5,
    name: 'Friday'
  },
  {
    value: 6,
    name: 'Saturday'
  }
];

const TYPE_BOOKING_TIME = Enum({
  Other: 'Other',
  SupervisedArt: 'SupervisedArt',
  TeleCounsel: 'TeleCounsel'
});

export {
  FORMAT_DD_MM_YYYY,
  FORMAT_DATE_YEAR,
  FORMAT_TIME,
  FORMAT_DATE,
  DEFAULT_END_TIME,
  DEFAULT_START_TIME,
  FORMAT_TIME_LT,
  FORMAT_DATE_TIME,
  DAYS_PER_WEEK,
  TIME_ZONE_SINGAPORE,
  FORMAT_YYYY_MM_DD,
  MIN_TIME_PICK,
  MAX_TIME_PICK,
  VALUE_DAY_OF_WEEK,
  DAYS_OF_WEEK,
  TYPE_BOOKING_TIME
};
