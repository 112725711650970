import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Typography,
  withStyles
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

import CustomSelectContainer from '@/new-components/CustomSelectOld/CustomSelectContainer';
import { CustomTextField } from '@/module/all-screenings/component/ViewPatientRegistration/ModalInputServices';

import { ReloadIcon } from '@/assets/svg';
import validateData from '@/helpers/validationHelpers/validationSchema';
import { generateCurrentLabNumber } from '@/module/all-screenings/utils';

const titleStyles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 10,
    color: theme.palette.grey.subText
  },
  text: {
    fontSize: 16,
    fontWeight: 600
  }
});

const CustomDialogTitle = withStyles(titleStyles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.text}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const CustomDialog = withStyles({
  paper: {
    borderRadius: 16
  }
})(Dialog);

const CustomButton = withStyles({
  root: {
    width: 166,
    height: 40,
    borderRadius: 8,

    fontSize: 18,
    fontWeight: 400,
    textTransform: 'unset'
  },
  containedPrimary: {
    boxShadow: 'none'
  }
})(Button);

const CustomDialogContent = withStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(3, 2)
  }
}))(DialogContent);

const CustomDialogActions = withStyles({
  root: {
    margin: 0,
    padding: 16,
    justifyContent: 'flex-end'
  }
})(DialogActions);

const CustomInputLabel = withStyles({
  root: {
    color: '#666666',
    marginBottom: 8,
    fontWeight: 600
  }
})(InputLabel);

export const LAB_PROVIDERS = {
  Innoquest: 'Innoquest',
  Eurofins: 'Eurofins'
};

export const LAB_NUMBERS_MODES = {
  AutoAssign: 'AutoAssign',
  AutoEdit: 'AutoEdit',
  ManualAssign: 'ManualAssign',
  ManualEdit: 'ManualEdit'
};

const ModalAssignLabNumber = ({
  open,
  onClose,
  onAssignLabNumber,
  onSubmitLabNumber,
  currentLabNumber,
  currentLabProvider,
  labNumbersMode
}) => {
  const [localCurrentLabNumber, setLocalCurrentLabNumber] = useState('');
  const [localCurrentLabProvider, setLocalCurrentLabProvider] = useState('');
  const [errors, setErrors] = useState(null);

  const isAutoMode = [
    LAB_NUMBERS_MODES.AutoAssign,
    LAB_NUMBERS_MODES.AutoEdit
  ].includes(labNumbersMode);

  const isManualMode = [
    LAB_NUMBERS_MODES.ManualAssign,
    LAB_NUMBERS_MODES.ManualEdit
  ].includes(labNumbersMode);

  const isAssignMode = [
    LAB_NUMBERS_MODES.AutoAssign,
    LAB_NUMBERS_MODES.ManualAssign
  ].includes(labNumbersMode);

  const isEditMode = [
    LAB_NUMBERS_MODES.AutoEdit,
    LAB_NUMBERS_MODES.ManualEdit
  ].includes(labNumbersMode);

  useEffect(() => {
    if (isAutoMode) {
      const newCurrentLabNumber = generateCurrentLabNumber(
        currentLabNumber,
        currentLabProvider
      );

      setLocalCurrentLabNumber(newCurrentLabNumber || '');
      setLocalCurrentLabProvider(currentLabProvider || '');
    }

    if (isManualMode) {
      setLocalCurrentLabNumber('');
      setLocalCurrentLabProvider(currentLabProvider || '');
    }
  }, [currentLabNumber, currentLabProvider]);

  useEffect(() => {
    async function validateLocalLabNumber() {
      try {
        await validateData(
          'labNumberSchemaByProvider',
          {
            labNumber: localCurrentLabNumber,
            labProvider: localCurrentLabProvider
          },
          () => {
            setErrors(null);
          }
        );
      } catch (errors) {
        setErrors(errors);
      }
    }

    validateLocalLabNumber();
  }, [localCurrentLabNumber, localCurrentLabProvider]);

  const handleSubmitLabNumber = () => {
    const params = {
      labNumber: localCurrentLabNumber,
      labProvider: localCurrentLabProvider
    };

    if (localCurrentLabProvider === LAB_PROVIDERS.Eurofins) {
      const yearParam = localCurrentLabNumber.slice(0, 2);

      params.labNumber = localCurrentLabNumber.slice(3);
      params.year = parseInt(yearParam);
    }

    onSubmitLabNumber(params);
  };

  return (
    <CustomDialog
      scroll="body"
      disableEnforceFocus
      open={open}
      onClose={onClose}
      disableBackdropClick
      maxWidth="xs"
      fullWidth
    >
      <CustomDialogTitle onClose={onClose}>
        {isAssignMode && 'Assign lab number'}
        {isEditMode && 'Edit lab number'}
      </CustomDialogTitle>

      <CustomDialogContent dividers>
        {/* <Grid>
          <CustomInputLabel>Provider</CustomInputLabel>
          <CustomSelectContainer
            data={Object.values(LAB_PROVIDERS)}
            inputLabel="Provider"
            height="40px"
            isFullWidth
            isMutiple={false}
            value={localCurrentLabProvider || ''}
            renderValue={localCurrentLabProvider || 'Select provider'}
            filterValue={localCurrentLabProvider}
            onChange={event => {
              const newProvider = event.target.value;

              if (isAutoMode) {
                onAssignLabNumber(newProvider);
              }

              setLocalCurrentLabProvider(newProvider);
            }}
          />
        </Grid> */}

        <Grid>
          <CustomInputLabel>Lab number</CustomInputLabel>

          {isAutoMode ? (
            <CustomTextField
              size="small"
              fullWidth
              variant="outlined"
              placeholder="Auto-generated"
              disabled={!localCurrentLabProvider}
              value={localCurrentLabNumber}
              InputProps={{
                endAdornment: localCurrentLabNumber ? (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      onClick={() => {
                        onAssignLabNumber(localCurrentLabProvider);
                      }}
                    >
                      <ReloadIcon />
                    </IconButton>
                  </InputAdornment>
                ) : null
              }}
              inputProps={{
                readOnly: true
              }}
            />
          ) : null}

          {isManualMode ? (
            <CustomTextField
              size="small"
              fullWidth
              variant="outlined"
              placeholder="Enter or auto-assign"
              disabled={!localCurrentLabProvider}
              value={localCurrentLabNumber}
              onChange={event => {
                const newLabNumber = event.target.value;
                setLocalCurrentLabNumber(newLabNumber);
              }}
              InputProps={{
                endAdornment: localCurrentLabProvider ? (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      onClick={() => {
                        onAssignLabNumber(localCurrentLabProvider);
                      }}
                    >
                      <ReloadIcon />
                    </IconButton>
                  </InputAdornment>
                ) : null
              }}
            />
          ) : null}
        </Grid>
      </CustomDialogContent>

      <CustomDialogActions>
        <CustomButton
          variant="contained"
          color="primary"
          onClick={onClose}
          hidden={isManualMode}
        >
          Done
        </CustomButton>
        <CustomButton
          variant="contained"
          color="primary"
          onClick={handleSubmitLabNumber}
          hidden={isAutoMode}
          disabled={!!errors}
        >
          Save
        </CustomButton>
      </CustomDialogActions>
    </CustomDialog>
  );
};

ModalAssignLabNumber.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onAssignLabNumber: PropTypes.func,
  onSubmitLabNumber: PropTypes.func,
  currentLabNumber: PropTypes.string,
  currentLabProvider: PropTypes.string,
  labNumbersMode: PropTypes.string
};

ModalAssignLabNumber.defaultProps = {
  open: false,
  onClose: () => console.warn('Not implemented yet'),
  onAssignLabNumber: () => console.warn('Not implemented yet'),
  onSubmitLabNumber: () => console.warn('Not implemented yet'),
  currentLabNumber: '',
  currentLabProvider: '',
  labNumbersMode: LAB_NUMBERS_MODES.AutoAssign
};

export default ModalAssignLabNumber;
