import CustomWeekPicker from './CustomWeekPicker';
import { Box, Button, Fab, Toolbar, withStyles } from '@material-ui/core';
import {
  KeyboardArrowLeftSharp,
  KeyboardArrowRightSharp
} from '@material-ui/icons';
import React from 'react';

const CustomButton = withStyles({
  root: {
    width: 160,
    height: 36,

    '&.MuiButton-outlinedPrimary': {
      border: '1px solid #AD5E99'
    }
  }
})(Button);

const CustomFab = withStyles({
  root: {
    width: 40,
    height: 40,
    borderRadius: 8,
    boxShadow: 'none',
    border: '1px solid #AD5E99',
    backgroundColor: 'white',

    '&:hover': {
      backgroundColor: '#FFE9FB'
    }
  }
})(Fab);

const GroupToolbar = ({
  setWeekPicker,
  weekPicker,
  onChangeWeekPicker,
  onClickToday,
  onCountWeekPicker
}) => {
  return (
    <Toolbar>
      <Box
        display="flex"
        flex={1}
        justifyContent="space-between"
        alignItems="center"
        gridGap={16}
      >
        <CustomButton color="primary" variant="outlined" onClick={onClickToday}>
          Today
        </CustomButton>
        <Box>
          <CustomWeekPicker
            selectedDate={weekPicker}
            onChange={onChangeWeekPicker}
            setWeekPicker={setWeekPicker}
          />
        </Box>
        <Box width={160} textAlign="right">
          <CustomFab
            style={{ marginRight: 12 }}
            onClick={() => onCountWeekPicker(false)}
          >
            <KeyboardArrowLeftSharp color="primary" />
          </CustomFab>
          <CustomFab onClick={() => onCountWeekPicker(true)}>
            <KeyboardArrowRightSharp color="primary" />
          </CustomFab>
        </Box>
      </Box>
    </Toolbar>
  );
};

export default GroupToolbar;
