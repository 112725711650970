import fetchHelper from '@/helpers/FetchHelper';
import { Typography } from '@material-ui/core';
import { get } from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

const NormalRoute = ({ component: Component, path, ...rest }) => {
  useEffect(() => {
    const accessToken = get(rest, 'auth.accessToken');
    if (accessToken) {
      fetchHelper.addDefaultHeader('Authorization', `Bearer ${accessToken}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest.auth.accessToken]);
  return (
    <React.Fragment>
      {Component ? (
        <Route path={path} exact component={Component} {...rest} />
      ) : (
        <Route path="*">
          <div style={{ padding: 10 }}>
            <Typography variant="h3">Not Found Page</Typography>
          </div>
        </Route>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    privilege: state.userPermission.privilege
  };
};
export const WrapperRoute = connect(mapStateToProps)(NormalRoute);
