import { synthesize } from 'redux-dispatcher';

const mapDispatchToAC = {
  getDataSuccess: data => ({ data }),
  resetData: () => ({}),
  getData: (search, paging, sortOption = {}, filterOption = {}) => async ({
    Api,
    getState
  }) => {
    let pagingData = paging || getState().counsellorsEX.paging;
    let { result, status } = await Api.post(`SystemAdmins/filter`, {
      pageIndex: pagingData.pageIndex,
      pageSize: pagingData.pageSize,
      search,
      ...filterOption,
      ...sortOption,
      roleType: ['CounsellorExecutive']
    });
    status === 200 && counsellorsEXManagementDispatcher.getDataSuccess(result);
  },
  editCounsellorEx: (id, data, onSuccess) => async ({ Api }) => {
    let { status } = await Api.put(`SystemAdmins/${id}`, {
      ...data,
      fullName: `${data.firstName} ${data.lastName}`
    });
    status === 200 && onSuccess();
  },
  createCounsellorEx: (data, onSuccess, onFail) => async ({ Api }) => {
    let { status } = await Api.post(`SystemAdmins`, {
      ...data,
      fullName: `${data.firstName} ${data.lastName}`
    });
    status === 200 ? onSuccess() : onFail();
  },
  deleteCounsellorEx: (id, onSuccess) => async ({ Api, customToast }) => {
    let { status } = await Api.delete(`SystemAdmins/${id}`);
    if (status === 200) {
      customToast('success', 'Deleted successfully');
      onSuccess();
    }
  }
};

const counsellorsEXManagementDispatcher = synthesize(
  'counsellorsEX',
  mapDispatchToAC
);
export default counsellorsEXManagementDispatcher;
