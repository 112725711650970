import InputAddressEnhance from '../../new-components/InputAddressEnhance';
import { ButtonEnhance } from '../common/componentUI/commonStyleComponents';
import LoadingButton from '@/components/LoadingButton';
import {
  DialogContent,
  Grid,
  TextField,
  makeStyles,
  withStyles
} from '@material-ui/core';
import { StaticDatePicker } from '@material-ui/pickers';
import styled from 'styled-components';

export const useStyles = makeStyles({
  container: {
    color: '#192637',
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    paddingBottom: 10
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #e9e9e9'
  },
  head: {
    fontWeight: 600,
    padding: '10px 20px',
    fontSize: '24px',
    color: '#333',
    display: 'flex',
    alignItems: 'center'
  },
  main: {},
  wrapper: {
    marginBottom: 32
  }
});

export const ButtonSecondary = styled(ButtonEnhance)`
  margin: 0;
  height: 36px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 8px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  background: #fff;
  color: #ad5e99;
  border: 1px solid #ad5e99;
  font-weight: 400;
  margin-top: ${p => p.mt} svg {
    margin-right: 5px;
  }
  &:hover {
    background-color: #ffe9fb;
  }
`;
export const ButtonPrimary = styled(LoadingButton)`
  margin: 5px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 8px;
  font-size: 16px;
  display: flex;
  align-items: center;
  width: auto;
  min-width: 50px;
  font-weight: 400;
  svg {
    margin-right: 5px;
  }
`;
export const CustomSearchInput = withStyles(theme => ({
  root: {
    marginBottom: 15,
    '& .MuiOutlinedInput-root': {
      borderRadius: 8
    },
    '& .Mui-disabled': {
      background: '#f6f6f6'
    },
    '& input': {
      minWidth: 335,
      height: 48,
      padding: 0
    }
  }
}))(TextField);

export const CustomSearchInputModal = withStyles(theme => ({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      fontSize: 16
    },
    '& .Mui-disabled': {
      background: '#f6f6f6',
      color: '#8F9BA9'
    },
    '& input': {
      minWidth: 335,
      height: 40,
      padding: 0
    },
    '& fieldset': {
      border: '1px solid #e1e1e1!important'
    }
  }
}))(TextField);

//General Information

export const GeneralInformationContainer = styled.div`
  max-width: 664px;
  margin: 25px auto 40px;
  .MuiAutocomplete-root {
    margin-bottom: 15px !important;
    border-radius: 8px !important;
    border: 1px solid #e1e1e1 !important;
    height: 48px;
  }
  .MuiInput-underline:before {
    display: none;
  }
  .custom-select {
    height: 48px;
    width: 100%;
  }
  .MuiFormControl-root.MuiTextField-root.MuiFormControl-fullWidth {
    height: 100%;
    > div {
      height: 100%;
    }
  }
`;

export const CustomInputAddressEnhance = styled(InputAddressEnhance)`
  > * {
    height: 100%;
  }
`;
export const GeneralInformationTitle = styled.div`
  margin-bottom: 20px;
  color: #333;
  font-size: 20px;
  font-family: 'Lato';
  font-weight: 600;
`;
export const InputLabel = styled.div`
  margin-bottom: 5px;
  color: #666;
  font-weight: 600;
  margin-top: ${p => p.mt};
  margin-left: 0 !important;
`;
export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const UploadContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  .ant-upload-list-item.ant-upload-list-item-undefined.ant-upload-list-item-list-type-text {
    display: none;
  }
`;
export const ImageContainer = styled.div`
  width: 130px;
  height: 80px;
  border-radius: 8px;
  background-color: #f6f6f6;
  border: 1px solid #e1e1e1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  z-index: 1;
`;
export const PreviewContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 75px;
  border-radius: 8px;
  background-color: rgba(51, 51, 51, 0.4);
  z-index: 2;
  cursor: pointer;
`;
export const StyledDialogContent = styled(DialogContent)`
  position: relative;
  .MuiDialogContent-root {
    padding: 0;
  }
  .icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
`;
export const UploadText = styled.div`
  width: calc(100% - 280px);
  margin-left: 12px;
  margin-right: 12px;
`;
export const UploadAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const StyledDatepicker = styled(StaticDatePicker)`
  .MuiPickersBasePicker-pickerView {
    border: 1px solid #e1e1e1;
    border-radius: 12px;
    width: 100%;
  }
  .MuiPickersCalendarHeader-root {
    padding-bottom: 10px;
    border-bottom: 1px solid #e1e1e1;
    max-height: unset;
  }
  h6.MuiTypography-root.MuiPickersCalendarHeader-monthText.MuiTypography-subtitle1.MuiTypography-alignCenter,
  h6.MuiTypography-root.MuiTypography-subtitle1.MuiTypography-alignCenter {
    font-weight: 600;
    color: #333;
    font-size: 16px;
  }
  .MuiSvgIcon-root.MuiPickersCalendarHeader-switchViewDropdown {
    display: none;
  }
  .MuiButtonBase-root {
    border-radius: 4px;
  }
  .MuiPickersCalendar-root {
    min-height: 245px;
  }
`;
export const OperationTitle = styled.div`
  font-family: 'Lato';
  font-size: 20px;
  color: #333;
  font-weight: 600;
`;
export const OperationTitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
  .custom-select {
    margin-right: 0;
    width: 160px;
  }
  > div:last-child {
    width: 150px;
    > div > div {
      color: #333;
    }
  }
`;
export const ClosingDateContainer = styled.div`
  width: 100%;
  border: 1px solid #e1e1e1;
  border-radius: 12px;
  margin-bottom: 10px;
  margin-left: ${p => p?.ml};
`;
export const ClosingHeader = styled(Grid)`
  background: #f1f1f1;
  font-size: 12px;
  color: #333;
  width: 100%;
  padding: 10px 0 10px 15px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`;
export const NoDateText = styled.div`
  color: #666;
`;

export const DateText = styled.div`
  font-size: 16px;
`;
export const ClosingRow = styled(Grid)`
  padding: 10px 0 10px 15px;
  color: #333;
  font-size: 14px;
  border-bottom: 1px solid #e1e1e1;
  min-height: 30px;
  svg {
    cursor: pointer;
  }
  &:last-child {
    border: none;
  }
  .MuiGrid-item {
    display: flex;
    align-items: center;
  }
`;
//Restrict package
export const RestrictPackageContainer = styled.div`
  width: 100%;
  border: 1px solid #e1e1e1;
  border-radius: 12px;
  margin-bottom: 40px;
`;
export const PackageTableHeader = styled(Grid)`
  background: #f1f1f1;
  font-size: 12px;
  color: #333;
  width: 100%;
  padding: 10px 36px 10px 15px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`;
export const PackageTableBody = styled.div`
  /* height: 60vh; */
  overflow: auto;
`;
export const PackageTableRow = styled(Grid)`
  padding: 10px 0 10px 15px;
  color: #333;
  font-size: 14px;
  border-bottom: 1px solid #e1e1e1;
  padding-right: 20px;
  min-height: 30px;
  &:last-child {
    border: none;
  }
  .MuiGrid-item {
    display: flex;
    align-items: center;
  }
`;
export const RestrictPackageTitle = styled(OperationTitle)`
  // margin-top: 20px;
`;
export const RestrictPackageDescription = styled.div`
  color: #666;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 15px;
`;

export const TabsLeftContainer = styled.div`
  width: 200px;
  padding: 20px 20px 10px 10px;
  height: calc(100vh - 282px);
`;
export const Tab = styled.div`
  font-size: 16px;
  color: #333;
  font-weight: 600;
  padding: 10px 0;
  text-transform: capitalize;
  padding-left: 20px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  svg {
    margin-right: 20px;
  }
  cursor: pointer;
  &.active {
    background-color: #ffe9fb;
    border-radius: 12px;
    color: #ad5e99;
  }
`;
export const FilterContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  margin-top: 10px;
  align-items: center;
  & > div {
    margin-bottom: 0;
  }
`;

export const RestrictPackageWrapper = styled.div`
  width: 664px;
  // margin: auto;
  padding: 20px 16px;
`;
export const CheckmarkContainer = styled.div`
  width: 16px;
  height: 16px;
  background: #ad5e99;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 10px;
`;
export const SetupTimeTitle = styled(OperationTitle)`
  margin-top: 0;
`;
export const SetupTimeContainer = styled.div`
  display: flex;
  height: 100%;
`;
export const SetupRightContainer = styled.div`
  padding-left: 20px;
  width: 700px;
  padding-top: 20px;
  overflow: auto;
  border-left: 1px solid #f1f1f1;
  height: calc(100vh - 282px);
  .custom-select {
    height: 48px;
    margin-right: 0;
  }
`;
export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const UseTemplate = styled.div`
  color: #ad5e99;
  font-size: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    margin-right: 6px;
  }
`;
export const CustomGrid = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 15px;
`;
export const CustomGridItem = styled.div`
  &.grid-2 {
    flex-grow: 0;
    max-width: 20%;
    flex-basis: 20%;
    > div {
      width: 95%;
      &.disabled {
        > div {
          color: #8f9ba9;
        }
      }
    }
  }
  &.grid-1 {
    flex-grow: 0;
    max-width: 10%;
    flex-basis: 10%;
    display: flex;
    align-items: center;
  }
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-fullWidth.MuiInputBase-formControl {
    /* width: 90%; */
    input {
      height: 36px;
      min-width: 50px;
    }
  }
  .Mui-disabled {
    /* background: white !important; */
    pointer-events: none;
  }
  > div {
    margin-bottom: 0;
  }
`;
export const AddTimeSlot = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  color: #ad5e99;
  cursor: pointer;
  svg {
    margin-right: 5px;
  }
`;

export const OtherDay = styled.div`
  width: 60px;
  height: 32px;
  background: #f1f1f1;
  border-radius: 16px;
  padding: 10px 12px;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover,
  &.active {
    color: #ad5e99;
    background-color: #ffe9fb;
    pointer-events: ${p => (p?.disabled ? 'none' : 'auto')};
  }
`;
export const OtherDayContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 5px;
  margin-bottom: 40px;
`;
export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;
