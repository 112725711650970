import { withStyles } from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import React from 'react';

const StyledToggleButtonGroup = withStyles(theme => ({
  root: {
    flexWrap: 'wrap',
    gap: theme.spacing(1)
  },
  grouped: {
    // margin: theme.spacing(1),
    padding: '5px 8px',
    border: `2px solid ${theme.palette.grey.disableBackground}`,
    backgroundColor: theme.palette.grey.disableBackground,
    color: theme.palette.grey.mainText,
    textTransform: 'capitalize',

    '&:not(:first-child)': {
      borderRadius: 35
    },
    '&:first-child': {
      borderRadius: 35
    },
    '&.Mui-selected': {
      border: `2px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main
    }
  }
}))(ToggleButtonGroup);

const CustomQuickRangeGroup = ({ value, handleChange, listButtons }) => {
  return (
    <StyledToggleButtonGroup
      size="small"
      value={value}
      exclusive
      onChange={handleChange}
    >
      {listButtons.map(btn => (
        <ToggleButton key={btn} value={btn}>
          {btn}
        </ToggleButton>
      ))}
    </StyledToggleButtonGroup>
  );
};

export default CustomQuickRangeGroup;
