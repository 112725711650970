import { Box } from '@material-ui/core';
import React from 'react';

const AvatarInCall = ({ children, isPatient = false }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      borderRadius="50% !important"
      border={`1px solid ${
        isPatient ? 'rgba(173,94,153,0.2)' : 'rgba(80,165,237,0.1)'
      }  !important`}
      width={isPatient ? 140 : 332}
      minHeight={isPatient ? 140 : 332}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        borderRadius="50% !important"
        border={`1px solid ${
          isPatient ? 'rgba(173,94,153,0.4)' : 'rgba(80,165,237,0.3)'
        }  !important`}
        width={isPatient ? 101.2 : 240}
        minHeight={isPatient ? 101.2 : 240}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius="50% !important"
          border={`1px solid ${
            isPatient ? 'rgba(173,94,153,0.5)' : 'rgba(80,165,237,0.5)'
          } !important`}
          width={isPatient ? 78.43 : 186}
          minHeight={isPatient ? 78.43 : 186}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderRadius="50% !important"
            width={isPatient ? 67.47 : 160}
            minHeight={isPatient ? 67.47 : 160}
            style={{
              background: isPatient
                ? 'linear-gradient(180deg, #D16AB9 0%, #AD5E99 100%)'
                : 'linear-gradient(180deg, #50A5ED 0%, #3776AB 100%)'
            }}
            border="unset !important"
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius="50% !important"
              border={`1px solid ${
                isPatient ? '#FFE9FB' : '#e2f2ff'
              } !important`}
              width={isPatient ? 56.23 : 133.33}
              minHeight={isPatient ? 56.23 : 133.33}
              // color={isPatient ? "#FFE9FB" : "#e2f2ff"}
              // fontFamily="Lato"
              // fontSize={isPatient ? 14 : 48}
              // fontWeight={700}
            >
              {children}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AvatarInCall;
