import { DAYS_PER_WEEK } from '../constants';
import BookingItem from './BookingItem';
import {
  Box,
  TableBody,
  TableCell,
  TableRow,
  makeStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles({
  itemContainer: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box'
  },
  wrapItem: {
    marginBottom: 6
  },
  styleRow: {
    '& .MuiTableCell-root': {
      borderRight: '1px solid #f1f1f1'
    }
  },

  wrapCell: {
    paddingTop: 20,
    paddingBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    gap: 8,
    fontSize: 16,
    color: '#333333'
  },

  textContainer: {
    lineHeight: '40px'
  }
});

const TableBodyComponent = ({
  data,
  keys,
  openEditModal,
  setCurrentBookingItem
}) => {
  const classes = useStyles();

  const renderChildren = (data, key) => {
    const lastIndex = data[key].length - 1;
    const onClickBtnUpdate = dataBooking => {
      openEditModal();
      setCurrentBookingItem(dataBooking);
    };
    return data[key].map((item, index) => {
      if (index === lastIndex) {
        return (
          <BookingItem
            key={item.startDate}
            item={item}
            onClick={() => onClickBtnUpdate(item)}
          />
        );
      }
      return (
        <BookingItem
          key={item.startDate}
          item={item}
          onClick={() => onClickBtnUpdate(item)}
          className={classes.wrapItem}
        />
      );
    });
  };

  const renderColumnByDay = (rowData, index) => {
    const arrElements = [
      <TableCell key={index} component="th" scope="row">
        <Box className={classes.wrapCell}>
          {rowData.map(row => (
            <div className={classes.textContainer}>{row.key}</div>
          ))}
        </Box>
      </TableCell>
    ];

    for (let i = 0; i < DAYS_PER_WEEK; i += 1) {
      arrElements.push(
        <TableCell align="center" key={keys[i]}>
          <Box className={classes.wrapCell}>
            {rowData.map(row => (
              <div className={classes.itemContainer}>
                {renderChildren(row, keys[i])}
              </div>
            ))}
          </Box>
        </TableCell>
      );
    }

    return arrElements;
  };

  const renderTableBody = classes => {
    if (keys.length > 0) {
      const groups = [];

      for (let i = 0; i < data.length; i += 4) {
        groups.push(data.slice(i, i + 4));
      }

      return groups.map((group, inx) => (
        <TableRow className={classes.styleRow} component="th" key={inx}>
          {renderColumnByDay(group, inx)}
        </TableRow>
      ));
    }
    return null;
  };

  return <TableBody>{renderTableBody(classes)}</TableBody>;
};

TableBodyComponent.propTypes = {
  data: PropTypes.array.isRequired,
  keys: PropTypes.array.isRequired,
  openEditModal: PropTypes.func.isRequired,
  setCurrentBookingItem: PropTypes.func.isRequired
};

export default React.memo(TableBodyComponent);
