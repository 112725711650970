import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import configureStore from './redux/store';
import App from './App';

import './index.css';
import './assets/scss/global.scss';
// import * as serviceWorker from './serviceWorker';
import { initializeFirebase } from './firebase';
// import { unregister } from './serviceWorker';

export const configure = configureStore();

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#AD5E99',
      light: '#FFE9FB'
    },
    secondary: {
      main: '#3776AB',
      light: '#E2F2FF'
    },
    general: {
      black: '#191919',
      white: '#FFFFFF'
    },
    feedback: {
      error: '#DB3D49',
      success: '#0B8E5E',
      warning: '#E37F2A'
    },
    grey: {
      mainText: '#333333',
      subText: '#666666',
      disableText: '#999999',
      disableIcon: '#C1C1C1',
      line: '#E1E1E1',
      disableBackground: '#F1F1F1',
      background: '#F6F6F6'
    },
    states: {
      purple: '#9365E8',
      blue: '#23A6CC',
      green: '#83CC26'
    }
  },
  typography: {
    useNextVariants: true,
    fontSize: 14,
    fontFamily: ['OpenSans', 'Lato'],
    body1: {
      fontSize: 14
    }
  },
  overrides: {
    MuiAutocomplete: {
      option: {
        '& span[class="max-line"]': {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          display: '-webkit-inline-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 2
        }
      }
    },
    MuiDrawer: {
      paper: {
        width: '300px'
      }
    },
    MuiInputBase: {
      input: {
        marginLeft: 12,
        paddingBottom: 9,
        paddingTop: 9
      }
    },
    MuiInput: {
      underline: {
        '&:after': {
          borderBottom: '1px solid #AD5E99'
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: '1px solid rgba(0, 0, 0, 0.87)'
        }
      }
    },
    MuiOutlinedInput: {
      root: { borderRadius: 8 },
      notchedOutline: {
        borderColor: '#E1E1E1'
      }
    },
    MuiFilledInput: {
      input: {
        marginLeft: 0
      }
    },
    MuiInputLabel: {
      filled: {
        top: 0,
        left: -10,
        position: 'absolute'
      },
      formControl: {
        top: 0,
        left: 12,
        position: 'absolute',
        transform: 'translate(0, 24px) scale(1)'
      },
      shrink: {
        transform: 'translate(0, 1.5px) scale(0.75)',
        transformOrigin: 'top left',
        left: 0
      }
    },
    MuiPaper: {
      rounded: {
        borderRadius: 10
      },
      outlined: {
        border: '1px solid #F1F1F1'
      }
    },
    MuiTableCell: {
      root: {
        padding: 12
      },
      head: {
        fontWeight: 600,
        verticalAlign: 'bottom'
      },
      body: {
        color: 'rgba(0, 0, 0, 0.87)',
        verticalAlign: 'top'
      },
      stickyHeader: {
        top: 0,
        left: 0,
        zIndex: 2,
        position: 'sticky',
        backgroundColor: '#ffffff'
      }
    },
    MuiSelect: {
      select: {
        background: 'none !important'
      }
    },

    MuiMenuItem: {
      root: {
        minWidth: 190
      }
    },
    MuiButton: {
      root: {
        height: 40,
        minWidth: 105,
        borderRadius: 8,
        padding: '10px 20px',

        '&:hover': {
          backgroundColor: 'unset'
        }
      },
      textPrimary: {
        '&:hover': {
          color: '#D16AB9',
          backgroundColor: 'transparent'
        },
        '&$disabled': {
          opacity: 0.3,
          color: '#AD5E99'
        }
      },
      label: {
        fontSize: 16,
        fontWeight: 400,
        textTransform: 'none'
      },

      outlined: {
        borderColor: '#E1E1E1',

        '&$disabled': {
          color: '#AD5E994D',
          borderColor: '#AD5E994D'
        }
      },
      outlinedPrimary: {
        '&:hover': {
          backgroundColor: '#FFE9FB'
        }
      },
      contained: {
        boxShadow: 'none',

        '&:hover': {
          boxShadow: 'none'
        },

        '&$disabled': {
          color: '#FFFFFF',
          backgroundColor: '#AD5E994D'
        }
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: '#D16AB9'
        }
      },
      containedError: {},
      sizeLarge: {
        height: 48,
        minWidth: 160
      }
    },
    MuiDivider: {
      light: {
        height: 0.5,
        color: '#F1F1F1'
      }
    },
    MuiIconButton: {
      root: {
        padding: 4
      }
    },
    MuiMenu: {
      paper: {
        border: '1px solid #E1E1E1',
        boxShadow: '0 4px 16px 0 rgba(0,0,0,0.1)'
      }
    }
  }
});

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <Provider store={configure.store}>
      <PersistGate loading={null} persistor={configure.persistor}>
        <App />
      </PersistGate>
    </Provider>
  </MuiThemeProvider>,

  document.getElementById('root')
);

// serviceWorker.register();
// unregister();
initializeFirebase();
