import { urlLabel } from '@/enum/PermissionEnum';
import CounsellorExecutivesManagement from '@/module/counsellor-executives-management';
import CounsellorsManagement from '@/module/counsellors-management';
import TelecounselAllCounsels from '@/module/telecounsel-all-counsels';
import TelecounselAppointmentConfig from '@/module/telecounsel-appointment-configuration';
import TelecounselAppointments from '@/module/telecounsel-appointments';
import TelecounselQueue from '@/module/telecounsel-queue';

export const telecounselRoutes = [
  {
    path: `/${urlLabel.telecounselQueue}`,
    component: TelecounselQueue,
    exact: true
  },
  {
    path: `/${urlLabel.telecounselAllCounsels}`,
    component: TelecounselAllCounsels,
    exact: true
  },
  {
    path: `/${urlLabel.telecounselAppointments}`,
    component: TelecounselAppointments,
    exact: true
  },
  {
    path: `/${urlLabel.telecounseRMCounsellors}`,
    component: CounsellorsManagement,
    exact: true
  },
  {
    path: `/${urlLabel.telecounseRMCounsellorExecutives}`,
    component: CounsellorExecutivesManagement,
    exact: true
  },
  {
    path: `/${urlLabel.telecounselAppointmentConfig}`,
    component: TelecounselAppointmentConfig,
    exact: true
  }
];
