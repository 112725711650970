import { CloseIcon, EyeIcon } from '@/assets/svg';
import Image from '@/components/Image';
import { blobUrl } from '@/helpers';
import {
  Box,
  Card,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
  makeStyles,
  withStyles
} from '@material-ui/core';
import React, { useRef } from 'react';
import { useState } from 'react';

const CustomCard = withStyles({
  root: {
    padding: 20,
    boxShadow: 'none',
    borderRadius: 8,
    overflow: 'auto',
    backgroundColor: props => (props.color ? props.color : '#F6F6F6'),
    width: props => (props.width ? props.width : 'unset')
  }
})(Card);

const CustomImageTitle = withStyles({
  root: {
    color: '#333333',
    fontSize: 12,
    marginBottom: 8
  }
})(Typography);

const CustomDialogContent = withStyles({
  root: {
    padding: 40,
    position: 'relative',
    '& .icon': {
      position: 'absolute',
      top: 10,
      right: 10
    },

    '&:first-child': {
      paddingTop: 40
    }
  }
})(DialogContent);

const PreviewContent = withStyles({
  root: {
    width: '100%',
    height: 98,

    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(51,51,51,0.4)',
    borderRadius: 8,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  }
})(Box);

const useStyles = makeStyles({
  image: {
    width: '100%',
    height: 98,
    borderRadius: 8
  }
});

const CorporatePlanImages = ({ data }) => {
  const classes = useStyles();

  const ref = useRef();

  const [isHovered, setIsHovered] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  const handleMouseEnter = value => {
    setIsHovered(true);
    ref.current = value;
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
    ref.current = '';
  };

  const handlePreviewOpen = imgeUrl => {
    setImageUrl(imgeUrl);
    setPreviewOpen(true);
    handleMouseLeave();
  };

  const handlePreviewClose = () => {
    setImageUrl('');
    setPreviewOpen(false);
  };

  return (
    <>
      <CustomCard>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <CustomImageTitle>Front of card</CustomImageTitle>
            <Box
              position="relative"
              onMouseEnter={() => handleMouseEnter('front')}
              onMouseLeave={handleMouseLeave}
            >
              <Image
                src={data?.frontCorperatePlan}
                alt="FrontCorperatePlan"
                className={classes.image}
              />
              {isHovered && ref.current === 'front' && (
                <PreviewContent
                  onClick={() => handlePreviewOpen(data?.frontCorperatePlan)}
                >
                  <EyeIcon color="white" width={24} height={24} />
                </PreviewContent>
              )}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <CustomImageTitle>Back of card</CustomImageTitle>
            <Box
              position="relative"
              onMouseEnter={() => handleMouseEnter('back')}
              onMouseLeave={handleMouseLeave}
            >
              <Image
                src={data?.backCorperatePlan}
                alt="BackCorperatePlane"
                className={classes.image}
              />
              {isHovered && ref.current === 'back' && (
                <PreviewContent
                  onClick={() => handlePreviewOpen(data?.backCorperatePlan)}
                >
                  <EyeIcon color="white" width={24} height={24} />
                </PreviewContent>
              )}
            </Box>
          </Grid>
        </Grid>
      </CustomCard>
      {previewOpen && (
        <Dialog
          maxWidth="lg"
          disableBackdropClick
          open={previewOpen}
          onClose={handlePreviewClose}
        >
          <CustomDialogContent>
            <IconButton className="icon" onClick={handlePreviewClose}>
              <CloseIcon />
            </IconButton>

            <Image
              src={
                imageUrl
                  ? `${
                      typeof imageUrl === 'object'
                        ? blobUrl(imageUrl)
                        : imageUrl
                    }`
                  : null
              }
              alt="Preview"
            />
          </CustomDialogContent>
        </Dialog>
      )}
    </>
  );
};

export default CorporatePlanImages;
