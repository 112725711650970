import SelectChildrenParticipants from '@/module/waiting-room/SelectChildrenParticipants';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const useStyles = makeStyles({
  root: {
    float: 'right',
    color: '#CACFD3',
    cursor: 'pointer'
  }
});

const NRICPanel = ({ onClose, data, partner, isAppt }) => {
  const iconClass = useStyles();
  return (
    <PanelStyled>
      <header className="nric-container">
        <span className="title">{partner.name || ''}</span>
        <CloseIcon onClick={onClose} classes={iconClass} />
      </header>
      <hr />
      <div className="nric-container">
        <div className="content">
          {/* <p className="id-number">
            NRIC (Patient Upload): <span>{data.IdentityNumber}</span>
          </p>
          <div className="group-img">
            <div className="img-item">
              <img src={data.FrontIdentityUrl} alt="ID Front" />
              <p>ID Front</p>
            </div>
            <div className="img-item">
              <img src={data.BackIdentityUrl} alt="ID Back" />
              <p className="sub-name">ID Back</p>
            </div>
          </div> */}
          <Box marginTop={2}>
            <SelectChildrenParticipants shouldInvokeEvent isAppt={isAppt} />
          </Box>
        </div>
      </div>
    </PanelStyled>
  );
};

export default NRICPanel;

const PanelStyled = styled.div`
  width: 360px;
  hr {
    background: #8f9ba9;
    margin: 0;
  }
  .title {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
  }
  .nric-container {
    padding: 15px 20px;
    .id-number {
      color: #8f9ba9;
      font-size: 14px;
      margin-bottom: 10px;
      span {
        color: #192637;
        margin-left: 10px;
      }
    }
    .group-img {
      .img-item {
        margin-bottom: 20px;
        img {
          height: 180px;
          width: 320px;
          border-radius: 5px;
        }
        .sub-name {
          color: #192637;
          font-size: 14px;
        }
      }
    }
  }
`;
