import CustomSelect from '../../../components/CustomSelect';
import MutipleSelection from '../../../components/CustomSelect/MutipleSelection';
import ModalPresenter from '../../../components/ModalPresenter';
import TableContainer from '../../../components/Table/TableContainer';
import { momentFormatted, getNationalNumber } from '../../../helpers';
import { DATE_FORMAT } from '../../common';
import TableCellFilted from '../../common/componentUI/TableCellFilted';
import { StatusWrapper } from '../../common/componentUI/commonStyleComponents';
import patientDispatcher from '../actions/index';
import {
  ACTIVE_STATUS,
  APPROVE_STATUS,
  SEARCH_PARAM,
  REGISTER_LEVEL2
} from '../constants';
import FormPatientPage from './FormPatientPage';
import globalDispatcher from '@/redux/global/actions';
import {
  Dialog,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Modal,
  Typography
} from '@material-ui/core';
import {
  DeleteRounded,
  MoreVertRounded,
  CheckCircleRounded,
  CancelRounded,
  CreateRounded
} from '@material-ui/icons';
import { ArtTrackOutlined } from '@material-ui/icons';
import { cloneDeep, isEmpty } from 'lodash';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const PatientListPage = () => {
  const { roleType } = useSelector(state => state.auth);
  const listPatient = useSelector(state => state.patient.list);
  const paging = useSelector(state => state.patient.paging);

  const [searchKey, setSearchKey] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [showModalConfirm, setShowModalConfirm] = useState(false);

  const [patientSelected, setPatientSelected] = useState(null);
  const [showModalForm, setShowModalForm] = useState(false);
  const [filterOption, setFilterOption] = useState(SEARCH_PARAM);

  const [activeStatusOpen, setActiveStatusOpen] = useState(false);
  const [activeStatusFilter, setActiveStatusFilter] = useState([]);

  const [approveStatusOpen, setApproveStatusOpen] = useState(false);
  const [approveStatusFilter, setApproveStatusFilter] = useState([]);

  const [registerLv2Open, setRegisterLv2Open] = useState(false);
  const [registerLv2Filter, setRegisterLv2Filter] = useState([]);
  const [hiddenRows, setHiddenRows] = useState({});
  const [lstIdentityUrl, setLstIdentityUrl] = useState([]);

  const TableCellFiltedWrapper = ({ ...props }) => (
    <TableCellFilted
      {...{
        hiddenRows,
        setHiddenRows
      }}
      {...props}
    />
  );
  const TableHeader = () => (
    <TableHead>
      <TableRow>
        <TableCellFiltedWrapper label="Full Name" stateValue="fullName" />
        <TableCellFiltedWrapper label="NRIC" stateValue="identityNumber" />
        <TableCellFiltedWrapper label="User Level" stateValue="userLevel" />
        <TableCellFiltedWrapper label="Email" stateValue="email" />
        <TableCellFiltedWrapper
          label="Date Of Birth"
          stateValue="dateOfBirth"
        />
        <TableCellFiltedWrapper label="Gender" stateValue="gender" />
        <TableCellFiltedWrapper label="Address" stateValue="address" />
        <TableCellFiltedWrapper
          label="Approve Status"
          stateValue="approveStatus"
        />
        <TableCellFiltedWrapper
          label="Active Status"
          stateValue="activeStatus"
        />
        <TableCellFiltedWrapper label="Mobile" stateValue="mobile" />
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );

  const onShowModalConfirm = () => {
    setShowModalConfirm(true);
  };

  const handleDeletePatient = () => {
    patientDispatcher.deletePatient(patientSelected.id, () => {
      setFilterOption(cloneDeep(filterOption));
    });
    setShowModalConfirm(false);
  };

  const renderMenuAction = () => {
    if (!patientSelected) {
      return [];
    }
    const listActions = [
      {
        key: 'edit',
        icon: CreateRounded,
        label: 'Edit',
        onClick: () => setShowModalForm(true)
      }
    ];
    listActions.push({
      key: 'delete',
      icon: DeleteRounded,
      label: 'Delete',
      onClick: () => onShowModalConfirm()
    });

    // if (patientSelected.isRegisterLv2) {
    //   if (
    //     patientSelected.approveStatus === APPROVE_STATUS.Reject ||
    //     patientSelected.approveStatus === APPROVE_STATUS.Pending
    //   ) {
    //     listActions.push({
    //       key: 'approve',
    //       icon: CheckCircleRounded,
    //       label: 'Approve',
    //       onClick: () => {
    //         const payload = {
    //           userId: patientSelected.id,
    //           isApprove: true
    //         };
    //         patientDispatcher.approvePatient(payload, () => {});
    //       }
    //     });
    //   }

    //   // if (patientSelected.approveStatus === APPROVE_STATUS.Approved) {
    //   //   listActions.push({
    //   //     key: 'reject',
    //   //     icon: CancelRounded,
    //   //     label: 'Reject',
    //   //     onClick: () => {
    //   //       const payload = {
    //   //         userId: patientSelected.id,
    //   //         isApprove: false
    //   //       };
    //   //       patientDispatcher.approvePatient(payload, () => {});
    //   //     }
    //   //   });
    //   // }
    // }

    if (patientSelected.activeStatus === 'Active') {
      listActions.push({
        key: 'inActive',
        icon: CancelRounded,
        label: 'InActive',
        onClick: () => {
          patientDispatcher.inActiveOrActivePatient({
            userId: patientSelected.id,
            isActive: false
          });
        }
      });
    } else {
      listActions.push({
        key: 'active',
        icon: CheckCircleRounded,
        label: 'Active',
        onClick: () => {
          patientDispatcher.inActiveOrActivePatient({
            userId: patientSelected.id,
            isActive: true
          });
        }
      });
    }

    return listActions.map(action => {
      const Icon = action.icon;
      return (
        <MenuItemAction
          key={action.key}
          onClick={() => {
            action.onClick();
            setAnchorEl(null);
          }}
        >
          <ListItemMenuIcon style={{ minWidth: 34 }}>
            <Icon className="icon" />
          </ListItemMenuIcon>
          {action.label}
        </MenuItemAction>
      );
    });
  };

  const onApprovePatient = payload => {
    setShowModalForm(false);
    patientDispatcher.approvePatient(payload, () => {});
  };

  const renderSearchSection = () => {
    const registerFilter = {
      true: 'Level 2',
      false: 'Level 1'
    };
    return (
      <>
        <CustomSelect
          onClick={() => setRegisterLv2Open(!registerLv2Open)}
          clearable={!isEmpty(registerLv2Filter)}
          onClear={() => {
            setRegisterLv2Filter([]);
            setFilterOption({
              ...filterOption,
              isRegisterLv2: []
            });
          }}
          prefixIcon={
            <ArtTrackOutlined
              style={{
                fontSize: 20,
                color: '#E9E9E9',
                marginRight: 8
              }}
            />
          }
          renderValue={
            <>
              {registerLv2Filter
                .join(', ')
                .replace(/true|false/gi, matched => registerFilter[matched]) ||
                'Select Register Level 2'}
            </>
          }
        >
          <MutipleSelection
            value={registerLv2Filter}
            data={REGISTER_LEVEL2}
            open={registerLv2Open}
            onChange={e => {
              setFilterOption({
                ...filterOption,
                registerLevel2: e.target.value
              });
              setRegisterLv2Filter(e.target.value);
            }}
          />
        </CustomSelect>

        <CustomSelect
          onClick={() => setActiveStatusOpen(!activeStatusOpen)}
          clearable={!isEmpty(activeStatusFilter)}
          onClear={() => {
            setActiveStatusFilter([]);
            setFilterOption({
              ...filterOption,
              activeStatuses: []
            });
          }}
          prefixIcon={
            <ArtTrackOutlined
              style={{
                fontSize: 20,
                color: '#E9E9E9',
                marginRight: 8
              }}
            />
          }
          renderValue={
            <>{activeStatusFilter.join(', ') || 'Select Active Status'}</>
          }
        >
          <MutipleSelection
            value={activeStatusFilter}
            data={ACTIVE_STATUS}
            open={activeStatusOpen}
            onChange={e => {
              setFilterOption({
                ...filterOption,
                activeStatuses: e.target.value
              });
              setActiveStatusFilter(e.target.value);
            }}
          />
        </CustomSelect>

        <CustomSelect
          onClick={() => setApproveStatusOpen(!approveStatusOpen)}
          clearable={!isEmpty(approveStatusFilter)}
          onClear={() => {
            setApproveStatusFilter([]);
            setFilterOption({
              ...filterOption,
              approveStatuses: []
            });
          }}
          prefixIcon={
            <ArtTrackOutlined
              style={{
                fontSize: 20,
                color: '#E9E9E9',
                marginRight: 8
              }}
            />
          }
          renderValue={
            <>{approveStatusFilter.join(', ') || 'Select Approved Status'}</>
          }
        >
          <MutipleSelection
            value={approveStatusFilter}
            data={APPROVE_STATUS}
            open={approveStatusOpen}
            onChange={e => {
              setFilterOption({
                ...filterOption,
                approveStatuses: e.target.value
              });
              setApproveStatusFilter(e.target.value);
            }}
          />
        </CustomSelect>
      </>
    );
  };

  useEffect(() => {
    if (patientSelected) {
      globalDispatcher.getIdentityImages(patientSelected.id, data => {
        setLstIdentityUrl(data);
      });
    }
  }, [patientSelected]);

  return (
    <>
      {showModalConfirm && (
        <Modal
          open={showModalConfirm}
          onClose={() => setShowModalConfirm(false)}
        >
          <div>
            <ModalPresenter
              onClose={() => setShowModalConfirm(false)}
              Icon={
                <DeleteRounded style={{ fontSize: 80, color: '#EA6B75' }} />
              }
              title="Delete Patient?"
              onClick={handleDeletePatient}
            >
              <Typography>Click “Continue” to delete</Typography>
              <Typography>
                <Typography style={{ fontWeight: 600 }} display={'inline'}>
                  {patientSelected.fullName}
                </Typography>
              </Typography>
            </ModalPresenter>
          </div>
        </Modal>
      )}
      <TableContainer
        title="Patient Management"
        paging={paging}
        data={listPatient}
        header={TableHeader}
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        query={patientDispatcher.getPatientList}
        ToolbarComponent={renderSearchSection()}
        filterOption={filterOption}
        renderRow={row => (
          <>
            <TableCell>{!hiddenRows.fullName && row.fullName}</TableCell>
            <TableCell>
              {!hiddenRows.identityNumber && row.identityNumber}
            </TableCell>
            <TableCell>
              {!hiddenRows.userLevel && (row.isRegisterLv2 ? 2 : 1)}
            </TableCell>
            <TableCell>{!hiddenRows.email && row.email}</TableCell>
            <TableCell>
              {!hiddenRows.dateOfBirth &&
                row.dateOfBirth !== '0001-01-01T00:00:00+00:00' &&
                momentFormatted(row.dateOfBirth, DATE_FORMAT)}
            </TableCell>
            <TableCell>{!hiddenRows.gender && row.gender}</TableCell>
            <TableCell>{!hiddenRows.address && row.address}</TableCell>
            <TableCell>
              {!hiddenRows.approveStatus && (
                <StatusWrapper status={row.approveStatus}>
                  {row.approveStatus}
                </StatusWrapper>
              )}
            </TableCell>
            <TableCell>
              {!hiddenRows.activeStatus && (
                <StatusWrapper status={row.activeStatus}>
                  {row.activeStatus}
                </StatusWrapper>
              )}
            </TableCell>
            <TableCell>
              {!hiddenRows.mobile &&
                getNationalNumber(row.mobile, row.countryCode, true)}
            </TableCell>
            <TableCell align="right">
              <IconButton
                onClick={e => {
                  setPatientSelected(row);
                  setAnchorEl(e.currentTarget);
                }}
              >
                <MoreVertRounded />
              </IconButton>
            </TableCell>
          </>
        )}
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {renderMenuAction()}
      </Menu>
      {showModalForm && (
        <Dialog
          open={showModalForm}
          onClose={() => setShowModalForm(false)}
          maxWidth="lg"
          fullWidth
          scroll="body"
          disableEnforceFocus
        >
          <FormPatientPage
            lstIdentityUrl={lstIdentityUrl}
            setShowModalForm={setShowModalForm}
            patientDetail={patientSelected}
            closeModal={() => setShowModalForm(false)}
            reGetListPatient={() => setFilterOption({ ...filterOption })}
          />
        </Dialog>
      )}
    </>
  );
};

const MenuItemAction = styled(MenuItem)`
  display: flex;
  align-items: center;
  color: #192637;
`;

const ListItemMenuIcon = styled(ListItemIcon)`
  .icon {
    color: #cacfd3;
  }
`;

export default PatientListPage;
