import CustomModalTitle from '../CustomModal/CustomModalTitle';
import CustomTooltip from '../CustomTooltip';
import { Box, Card, Grid, makeStyles, withStyles } from '@material-ui/core';
import React from 'react';

const rowStyles = makeStyles({
  title: {
    paddingBottom: 10,
    minWidth: 116,
    verticalAlign: 'top',
    fontWeight: 500,
    color: '#666666'
  },
  text: {
    paddingBottom: 10,
    fontSize: 16,
    verticalAlign: 'top'
  },
  icon: {
    verticalAlign: 'middle',
    marginRight: 8,
    color: '#666666',
    width: 24,
    height: 24
  }
});

const CustomCard = withStyles({
  root: {
    boxShadow: 'none',
    borderRadius: 8,
    background: '#e2f2ff',
    width: props => (props.width ? props.width : 'unset'),
    zIndex: -1,
    padding: '16px 16px 0'
  }
})(Card);

const RowItem = ({ children, title }) => {
  const classes = rowStyles();
  return (
    <tr>
      <td className={classes.title}>{title}</td>
      {children === 'None' ? (
        <td className={classes.text} style={{ color: '#999999' }}>
          {children}
        </td>
      ) : (
        <td className={classes.text}>
          <CustomTooltip content={children} />
        </td>
      )}
    </tr>
  );
};

const StreamInforPanel = ({ onClose, data, partner }) => {
  return (
    <Box>
      <CustomModalTitle
        onClose={onClose}
        style={{ padding: '20px 20px 16px' }}
        styleButton={{ top: 20 }}
      >
        Client information
      </CustomModalTitle>
      <Box px={2.5} py={1.5}>
        <Grid container>
          <Grid item xs={12}>
            <CustomCard>
              <RowItem title="Full name">
                {data?.FullName || data?.PatientFullName || 'None'}
              </RowItem>
              <RowItem title="ID number">
                {data?.IdentityNumber || 'None'}
              </RowItem>
            </CustomCard>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default StreamInforPanel;
