import { StatusComponent } from '../all-screenings/component/StatusComponent';
import TableCellFilted from '../common/componentUI/TableCellFilted';
import counsellorsEXManagementDispatcher from './action';
import GroupToolbar from './component/GroupToolbar';
import {
  ACTIVE_STATUSES,
  COLUMN_COUNSELLOR_EXECUTIVES,
  APPROVE_STATUSES,
  ALL_COUNSELLOR_STATUSES,
  COUNSELLOR_STATUS,
  COUNSELLOR_STATUS_COLORS,
  TYPE_MODAL
} from './constants';
import {
  CloseCircle,
  CreateNewIcon,
  DeleteModalIcon,
  EditIcon,
  NoDataCounsellors
} from '@/assets/svg';
import { getNationalNumber, handleSelectAll } from '@/helpers';
import CustomMenu from '@/new-components/CustomMenu';
import CustomMenuActions from '@/new-components/CustomMenuActions';
import ModalAddOrEditCounsellorExecutive from '@/new-components/CustomModal/ModalAddOrEditCounsellorExecutive';
import ModalConfirmation from '@/new-components/CustomModal/ModalConfirmation';
import customToast from '@/new-components/CustomNotification';
import CustomPaperContainer from '@/new-components/CustomPaperContainer';
import CustomHeader from '@/new-components/CustomPaperContainer/CustomHeader';
import CustomTable from '@/new-components/CustomTable';
import CustomTooltip from '@/new-components/CustomTooltip';
import { validateMobile } from '@/utils/validation';
import {
  Button,
  IconButton,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import { MoreVertRounded } from '@material-ui/icons';
import { parsePhoneNumber } from 'libphonenumber-js';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const CounsellorExecutivesManagement = () => {
  const { list: data, paging } = useSelector(state => state.counsellorsEX);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState({});
  const [typeModal, setTypeModal] = useState(null);

  const [sortOption, setSortOption] = useState({});
  const [searchKey, setSearchKey] = useState('');
  const [filterOption, setFilterOption] = useState({});
  const [loading, setLoading] = useState(false);

  const [counsellorsStatuses, setCounsellorsStatuses] = useState([]);
  const [showCounsellorModal, setShowCounsellorModal] = useState(false);
  const [showDeleteCounsellorModal, setDeleteShowCounsellorModal] = useState(
    false
  );

  const onFilterOptionChange = (key, setState) => e => {
    let value = e.target.value;

    if (key === 'counsellorsStatuses') {
      value = handleSelectAll(
        value,
        ALL_COUNSELLOR_STATUSES.map(item => item.key),
        counsellorsStatuses
      );
      let activeStatuses = [];
      let approveStatuses = [];
      value.forEach(filterItem => {
        if (filterItem === APPROVE_STATUSES.PENDING) {
          approveStatuses = approveStatuses.concat(APPROVE_STATUSES.PENDING);
        }
        if (filterItem === APPROVE_STATUSES.REJECT) {
          approveStatuses = approveStatuses.concat(APPROVE_STATUSES.REJECT);
        }
        if (filterItem === ACTIVE_STATUSES.ACTIVE) {
          approveStatuses = approveStatuses.concat(APPROVE_STATUSES.APPROVED);
          activeStatuses = activeStatuses.concat(ACTIVE_STATUSES.ACTIVE);
        }

        if (filterItem === ACTIVE_STATUSES.IN_ACTIVE) {
          approveStatuses = approveStatuses.concat(APPROVE_STATUSES.APPROVED);
          activeStatuses = activeStatuses.concat(ACTIVE_STATUSES.IN_ACTIVE);
        }
      });

      activeStatuses = activeStatuses.filter(
        (item, pos, self) => self.indexOf(item) === pos
      );
      approveStatuses = approveStatuses.filter(
        (item, pos, self) => self.indexOf(item) === pos
      );

      setFilterOption({
        ...filterOption,
        activeStatuses,
        approveStatuses
      });
    } else
      setFilterOption({
        ...filterOption,
        [key]: value
      });

    setState && setState(value);
  };

  const onResetFilterOps = () => {
    setCounsellorsStatuses([]);
    setFilterOption({});
  };

  const onShowCounsellorModal = type => {
    setShowCounsellorModal(true);
    setTypeModal(type);
  };

  const renderToast = (data, type) => {
    switch (type) {
      case TYPE_MODAL.Create:
        return customToast(
          'success',
          <span>
            Counsellor executive{' '}
            <strong style={{ fontWeight: 600 }}>{data}</strong> has been
            successully created.
          </span>,
          'New counsellor executive created'
        );

      case TYPE_MODAL.Edit:
        return customToast(
          'success',
          <span>
            Counsellor executive{' '}
            <strong style={{ fontWeight: 600 }}>{data}</strong> has been
            successully updated.
          </span>,
          'Counsellor executive updated'
        );

      default:
        break;
    }
  };

  const handleUpdateCounsellor = (type, data) => {
    setLoading(true);
    switch (type) {
      case TYPE_MODAL.Create:
        counsellorsEXManagementDispatcher.createCounsellorEx(
          { ...data, fullName: `${data.firstName} ${data.lastName}` },
          () => {
            setLoading(false);
            fetchData(searchKey, paging, sortOption, filterOption);
            renderToast(`${data.firstName} ${data.lastName}`, type);
            setShowCounsellorModal(false);
          },
          () => {
            setLoading(false);
          }
        );

        break;

      case TYPE_MODAL.Edit:
        counsellorsEXManagementDispatcher.editCounsellorEx(
          data.id,
          { ...data, fullName: `${data.firstName} ${data.lastName}` },
          () => {
            setLoading(false);
            fetchData(searchKey, paging, sortOption, filterOption);
            renderToast(`${data.firstName} ${data.lastName}`, type);
            setShowCounsellorModal(false);
          },
          () => {
            setLoading(false);
          }
        );
        break;

      default:
        break;
    }
  };

  const onDeleteCounsellor = () => {
    counsellorsEXManagementDispatcher.deleteCounsellorEx(
      selectedItem.id,
      () => {
        fetchData(searchKey, paging, sortOption, filterOption);
        setDeleteShowCounsellorModal(false);
      }
    );
  };

  const fetchData = async (
    search = searchKey,
    pagingData = paging,
    sortOptionData = sortOption,
    filterOptionData = filterOption
  ) => {
    counsellorsEXManagementDispatcher.getData(
      search,
      pagingData,
      ...[sortOptionData, filterOptionData].filter(i => i)
    );
  };

  const debounceLoadData = useCallback(
    debounce((searchKey, paging, sortOption, filterOption) => {
      fetchData(
        searchKey,
        {
          pageIndex: 0,
          pageSize: paging.pageSize
        },
        sortOption,
        filterOption
      );
    }, 500),
    []
  );

  useEffect(() => {
    debounceLoadData(searchKey, paging, sortOption, filterOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey, sortOption, filterOption]);

  useEffect(() => {
    return () => {
      counsellorsEXManagementDispatcher.resetData();
    };
  }, []);

  const renderListActions = [
    {
      key: 'edit',
      icon: EditIcon,
      label: 'Edit counsellor executive',
      styles: { width: 18, height: 18 },
      onClick: () => {
        setAnchorEl(null);
        setSelectedItem(selectedItem);
        onShowCounsellorModal(TYPE_MODAL.Edit);
      }
    },
    {
      key: 'delete',
      icon: CloseCircle,
      label: 'Delete counsellor executive',
      color: '#DB3D49',
      styles: { width: 18, height: 18 },
      onClick: () => {
        setAnchorEl(null);
        setSelectedItem(selectedItem);
        setDeleteShowCounsellorModal(true);
      }
    }
  ];

  const renderCounsellorStatuses = selectedItem => {
    if (!selectedItem) return;
    const { activeStatus, approveStatus } = selectedItem;

    if (approveStatus === APPROVE_STATUSES.APPROVED)
      return COUNSELLOR_STATUS[activeStatus];
    return COUNSELLOR_STATUS[approveStatus];
  };

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        {COLUMN_COUNSELLOR_EXECUTIVES.map(item =>
          item.sortBy ? (
            <TableCellFilted
              key={item.stateValue}
              label={item.label}
              stateValue={item.stateValue}
              sortBy="consultantDate"
              sortOption={sortOption}
              onSortChange={() => {
                let newSortOption = {
                  by: 'Date',
                  dir: sortOption.dir !== 'asc' ? 'asc' : 'desc'
                };
                setSortOption(newSortOption);
              }}
              style={{
                minWidth: item.minWidth || 'unset',
                width: item.width || 'unset'
              }}
              isHidden={false}
            />
          ) : (
            <TableCellFilted
              key={item.stateValue}
              label={item.label}
              stateValue={item.stateValue}
              style={{
                minWidth: item.minWidth || 'unset',
                width: item.width || 'unset'
              }}
              isHidden={false}
            />
          )
        )}
      </TableRow>
    </TableHead>
  );

  const renderTableBody = row => {
    const isValidPhone = validateMobile(row.mobile, row?.countryCode);
    return (
      <>
        <TableCell>
          <CustomTooltip content={row.fullName || '—'} />
        </TableCell>
        <TableCell>
          {row.mobile && row?.countryCode && isValidPhone
            ? parsePhoneNumber(
                getNationalNumber(row?.mobile, row?.countryCode, true)
              ).formatInternational()
            : '—'}
        </TableCell>
        <TableCell>
          <CustomTooltip content={row.email || '—'} />
        </TableCell>
        <TableCell>
          <StatusComponent
            status={renderCounsellorStatuses(row)}
            filterList={COUNSELLOR_STATUS}
            filterListColor={COUNSELLOR_STATUS_COLORS}
          />
        </TableCell>

        <TableCell>
          <IconButton
            onClick={e => {
              setAnchorEl(e.currentTarget);
              setSelectedItem(row);
            }}
          >
            <MoreVertRounded />
          </IconButton>
        </TableCell>
      </>
    );
  };

  return (
    <>
      <CustomMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <CustomMenuActions listActions={renderListActions} />
      </CustomMenu>
      <CustomPaperContainer
        header={
          <CustomHeader
            searchPlaceholder="Search by name or email..."
            title="Counsellor executives"
            setSearchKey={setSearchKey}
            searchKey={searchKey}
            filterOption={filterOption}
            setFilterOption={setFilterOption}
            sortOption={sortOption}
            fetchData={fetchData}
            renderToolbar={() => (
              <GroupToolbar
                filterOption={filterOption}
                onFilterOptionChange={onFilterOptionChange}
                onResetFilterOps={onResetFilterOps}
                counsellorsStatuses={counsellorsStatuses}
                setCounsellorsStatuses={setCounsellorsStatuses}
              />
            )}
            renderButton={() => (
              <Button
                color="primary"
                variant="contained"
                startIcon={<CreateNewIcon />}
                onClick={() => {
                  setSelectedItem(null);
                  onShowCounsellorModal(TYPE_MODAL.Create);
                }}
              >
                Create new
              </Button>
            )}
          />
        }
      >
        <CustomTable
          data={data}
          paging={paging}
          header={TableHeader}
          renderRow={(row, i) => renderTableBody(row)}
          noDataHelperText="No counsellor executive"
          noDataHelperSubText="Please create a new counsellor executive."
          Icon={NoDataCounsellors}
          totalCols={COLUMN_COUNSELLOR_EXECUTIVES.length}
          fetchData={fetchData}
        />
      </CustomPaperContainer>
      {showCounsellorModal && (
        <ModalAddOrEditCounsellorExecutive
          open={showCounsellorModal}
          onClose={() => setShowCounsellorModal(false)}
          typeModal={typeModal}
          selectedItem={selectedItem}
          onSubmit={handleUpdateCounsellor}
          loading={loading}
        />
      )}
      {showDeleteCounsellorModal && (
        <ModalConfirmation
          open={showDeleteCounsellorModal}
          onClick={onDeleteCounsellor}
          onClose={() => setDeleteShowCounsellorModal(false)}
          mainContent="Delete counsellor executive"
          subContent="Are you sure you want to delete this counsellor executive?"
          cancelLabel="Cancel"
          confirmLabel="Delete"
          confirmColor="red"
          Icon={<DeleteModalIcon />}
        />
      )}
    </>
  );
};

export default CounsellorExecutivesManagement;
