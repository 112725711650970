import { urlLabel } from '@/enum/PermissionEnum';
import AllUsers from '@/module/all-users/component';
import UserDetail from '@/module/all-users/component/UserDetail';

export const allUsersRoutes = [
  {
    path: `/${urlLabel.userDetails}`,
    component: AllUsers,
    exact: true
  },
  {
    path: `/${urlLabel.editUserDetail}`,
    component: UserDetail,
    exact: true
  }
];
