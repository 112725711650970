import { createDownloadableLink } from '@/helpers';
import { synthesize } from 'redux-dispatcher';

const mapDispatchToAC = {
  getDataSuccess: data => ({ data }),
  getData: (search, paging, sortOption = {}, filterOption = {}) => async ({
    Api,
    getState
  }) => {
    let pagingData = paging || getState().telecounselAppointment.paging;
    let { result, status } = await Api.post(`DoctorAppointments/filter`, {
      pageIndex: pagingData.pageIndex,
      pageSize: pagingData.pageSize,
      search,
      ...filterOption,
      sort: {
        ...sortOption
      }
    });
    status === 200 && telecounselAppointmentDispatcher.getDataSuccess(result);
  },
  getAvailableDoctor: () => async ({ Api, getState }) => {
    let { result, status } = await Api.post(`Users/filter`, {
      roleType: 'Doctor',
      activeStatus: ['Active'],
      approveStatus: ['Approved']
    });

    status === 200 &&
      telecounselAppointmentDispatcher.getAvailableDoctorSuccess(result);
  },
  getAvailableDoctorSuccess: data => ({ data }),
  getAvailableUser: roleType => async ({ Api, getState }) => {
    let { result, status } = await Api.post(`Users/filter`, {
      roleType,
      activeStatus: ['Active'],
      approveStatus: ['Approved']
    });
    status === 200 &&
      telecounselAppointmentDispatcher.getAvailableUserSuccess(result);
  },
  getAvailableUserSuccess: data => ({ data }),
  cancelAppointment: (id, onSuccess) => async ({ Api }) => {
    let { status } = await Api.put(`DoctorAppointments/${id}/reject`);
    status === 200 && onSuccess();
  },
  assignDoctorToAppointment: (id, doctorId, onSuccess) => async ({ Api }) => {
    let { status } = await Api.put(`DoctorAppointments/${id}/assign`, {
      doctorId
    });
    status === 200 && onSuccess();
  },
  editDoctorToAppointment: (id, payload, onSuccess) => async ({ Api }) => {
    let { status } = await Api.put(`DoctorAppointments/${id}`, payload);
    status === 200 && onSuccess();
  },
  createDoctorToAppointment: (payload, onSuccess) => async ({ Api }) => {
    let { status } = await Api.post(`DoctorAppointments`, payload);
    status === 200 && onSuccess();
  },
  doctorAcceptAppointment: (id, onSuccess) => async ({ Api }) => {
    let { status } = await Api.put(`DoctorAppointments/${id}/pick`);
    status === 200 && onSuccess();
  },
  getExportExcel: (search, sortOption = {}, filterOption = {}) => async ({
    Api,
    getState
  }) => {
    let { result, status } = await Api.post(`DoctorAppointments/csv-report`, {
      search,
      ...filterOption,
      sort: {
        ...sortOption
      }
    });
    status === 200 && createDownloadableLink(result);
  }
};

const telecounselAppointmentDispatcher = synthesize(
  'telecounselAppointment',
  mapDispatchToAC
);
export default telecounselAppointmentDispatcher;
