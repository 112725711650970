import teleQueueRoomDispatcher from '../action';
import { roomStatus } from '@/enum/RoomStatusEnum';
import _unionBy from 'lodash/unionBy';

const initialState = {
  waitingList: [],
  calling: false,
  localStream: [],
  remoteStreams: [],
  isMuteAudio: false,
  isMuteVideo: false,
  isMuteSpeaker: false,
  isPatientMuteAudio: false,
  isPatientMuteVideo: false,
  isSharing: false,
  showChat: false,
  showNRIC: false,
  showSharing: false,
  channelId: null,
  callId: null,
  patientId: null,
  memberAppointment: null,
  consConfirmVisibility: false,
  verifyModalVisibility: false,
  waitingModalVisibility: false,
  verifyingUserData: {},
  rejoinCallData: null,
  startCallTime: '',
  reconnectRejoinCall: false,
  teleCounselStatus: true,
  startCheckApptPending: null
};

const teleQueueRoomRoomReducer = teleQueueRoomDispatcher(initialState, {
  [teleQueueRoomDispatcher.setState]: (state, payload) => ({
    [payload.state]: payload.value
  }),
  [teleQueueRoomDispatcher.updateLocalStream]: (state, payload) => ({
    localStream: [...payload.streams]
  }),
  [teleQueueRoomDispatcher.updateRemoteStream]: (state, payload) => ({
    remoteStreams: [...payload.streams]
  }),
  [teleQueueRoomDispatcher.onGetWaitingList]: (state, payload) => {
    return {
      waitingList: _unionBy([...state.waitingList, ...payload.data], 'UserId')
    };
  },
  [teleQueueRoomDispatcher.onPTJoinRoom]: (
    state,
    { data, isJoin, isCurrentPT }
  ) => {
    if (isJoin)
      return {
        waitingList: [...state.waitingList, ...data]
      };
    else if (
      state.verifyModalVisibility &&
      data[0] === state.verifyingUserData.UserId
    ) {
      return {
        waitingList: [...state.waitingList].filter(
          i => !data.includes(i.UserId)
        ),
        verifyModalVisibility: false,
        // verifyingUserData: {},
        waitingModalVisibility:
          state.waitingModalVisibility && isCurrentPT
            ? false
            : state.waitingModalVisibility
      };
    } else
      return {
        waitingList: [...state.waitingList].filter(
          i => !data.includes(i.UserId)
        ),
        waitingModalVisibility:
          state.waitingModalVisibility && isCurrentPT
            ? false
            : state.waitingModalVisibility
      };
  },
  [teleQueueRoomDispatcher.onRemovePT]: (
    state,
    { data, shouldRemove, isCurrentPT }
  ) => {
    let condition = i => i.UserId !== data.UserId;
    if (typeof data === 'string') condition = i => i.UserId !== data;
    if (shouldRemove)
      return {
        waitingList: [...state.waitingList].filter(condition),
        waitingModalVisibility:
          state.waitingModalVisibility && isCurrentPT
            ? false
            : state.waitingModalVisibility
      };
    else
      return {
        waitingList: [...state.waitingList].map(i =>
          condition(i)
            ? i
            : {
                ...data,
                PatientRoomStatus: roomStatus.Waiting
              }
        )
      };
  },
  [teleQueueRoomDispatcher.clearWaitingList]: () => ({ waitingList: [] }),
  [teleQueueRoomDispatcher.getMemberAppointmentSuccess]: (state, payload) => ({
    memberAppointment: payload.data
  }),
  [teleQueueRoomDispatcher.resetState]: () => {
    return initialState;
  },
  [teleQueueRoomDispatcher.getTeleCounselStatusSuccess]: (state, { data }) => ({
    teleCounselStatus: data
  })
});

export default teleQueueRoomRoomReducer;
