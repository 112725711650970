import { renderHeadTableBooking } from '../utils';
import { TableCell, TableHead, TableRow, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const useStyle = makeStyles({
  txtCell: {
    fontSize: '16px',
    lineHeight: '21px',
    color: '#333333',
    textTransform: 'uppercase'
  },
  txtDay: {
    fontFamily: 'Lato',
    fontSize: '24px',
    lineHeight: '32px',
    color: '#333333'
  },
  styleRow: {
    '& .MuiTableCell-root': {
      borderRight: '1px solid #f1f1f1'
    }
  }
});

const TableHeadBooking = ({ keys }) => {
  const classes = useStyle();

  return (
    <TableHead>
      <TableRow className={classes.styleRow}>
        <TableCell
          className={classes.txtCell}
          style={{ textTransform: 'unset', minWidth: 100 }}
        >
          Hour
        </TableCell>
        {renderHeadTableBooking(keys, classes, 'D', 'ddd')}
      </TableRow>
    </TableHead>
  );
};

TableHeadBooking.propTypes = {
  keys: PropTypes.array.isRequired
};

export default TableHeadBooking;
