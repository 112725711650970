import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyle = makeStyles(theme => ({
  title: {
    fontFamily: 'Lato',
    padding: '18px 20px',
    fontWeight: 600
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    boxShadow: 'none',
    background: theme.palette.common.white
  },
  toolbar: {
    minHeight: 80,
    display: 'flex',
    justifyContent: 'flex-end',
    borderTop: '2px solid #e9e9e9'
  }
}));

const CustomButton = withStyles({
  root: {
    minWidth: 160,
    borderRadius: 8,

    fontSize: 18,
    textTransform: 'unset'
  }
})(Button);

const CustomPaperContainer = ({
  title = '',
  children,
  isDialog = false,
  btnLabel = 'Submit',
  onClick,
  anotherButton,
  header
}) => {
  const classes = useStyle();

  return (
    <Box position="relative">
      {header ? (
        header
      ) : (
        <Typography variant="h5" className={classes.title}>
          {title}
        </Typography>
      )}
      <Box overflow="auto">{children}</Box>
      {isDialog && (
        <AppBar position="absolute" className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            {anotherButton ? (
              anotherButton()
            ) : (
              <CustomButton
                color="primary"
                variant="contained"
                onClick={onClick}
              >
                {btnLabel}
              </CustomButton>
            )}
          </Toolbar>
        </AppBar>
      )}
    </Box>
  );
};

export default CustomPaperContainer;
