import { ArrowDown, CheckboxActive, CheckboxDefault } from '@/assets/svg';
import CustomAsyncAutoComplete from '@/new-components/CustomAsyncAutoComplete';
import CustomDate from '@/new-components/CustomDate';
import { sortAlphabetically } from '@/utils/sort';
import { CloseCircleFilled } from '@ant-design/icons';
import { Checkbox, Paper } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import { isEmpty } from 'lodash';
import React from 'react';

const useStyles = makeStyles(theme => ({
  selectWrapper: {
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'transparent'
    },

    '& .MuiAutocomplete-option': {
      padding: 8,
      borderRadius: 8,
      margin: '2px 8px',
      '&:first-child': {
        marginTop: 8
      },
      '&:last-child': {
        marginBottom: 8
      },
      '&.MuiAutocomplete-option[aria-selected="true"]': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main
      }
    }
  },
  checkboxIcon: {
    padding: 0,
    marginRight: 4
  },
  tagStyle: {
    maxWidth: 'calc(100% - 60px)', // Adjust based on your needs
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  multiSelectWrapper: {
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'transparent'
    },

    '& .MuiAutocomplete-option': {
      padding: 8,
      borderRadius: 8,
      margin: '2px 8px',
      '&:first-child': {
        marginTop: 8
      },
      '&:last-child': {
        marginBottom: 8
      },
      '&.MuiAutocomplete-option[aria-selected="true"]': {
        backgroundColor: '#ffffff'
      }
    }
  }
}));

const CustomButton = withStyles({
  label: {
    fontWeight: 700
  }
})(Button);

const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 8
    },
    '& input': {
      padding: 8
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 0
    }
  }
})(TextField);

const CustomAutocomplete = withStyles({
  root: {
    '&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      paddingRight: 6,
      height: 36,

      '& .MuiAutocomplete-input:first-child': {
        display: prop => (prop.isSelected ? 'none' : '')
      }
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
      paddingRight: prop => (prop.isSelected ? 0 : 42)
    }
  }
})(Autocomplete);

const CustomFilterInput = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      height: 40,
      borderRadius: 8,

      '& input': {
        padding: 0,
        marginLeft: 0
      },
      '& input::placeholder': {
        opacity: 1,
        color: '#999999'
      }
    }
  }
})(TextField);

const GroupToolbar = ({
  filterOption,
  onResetFilterOps,
  onFilterOptionChange,
  listLocations,
  locationFilter,
  setLocationFilter,
  listProjects,
  setListProjects,
  projectFilter,
  setProjectFilter
}) => {
  const classes = useStyles();
  return (
    <Toolbar>
      <Box
        display="flex"
        flex={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" gridGap={12}>
          <CustomDate
            selectedDate={filterOption.date}
            placeholder="Select appointment date"
            clearable={false}
            onChange={onFilterOptionChange('date')}
          />

          <CustomAsyncAutoComplete
            multiple
            disableCloseOnSelect
            isLocation
            endpoint="Locations/filter"
            method="post"
            nameKey="name"
            value={locationFilter}
            onChange={(e, value) => {
              setLocationFilter(value);
            }}
            defaultFilter={{
              sortByName: true
            }}
            getOptionLabel={option => option?.name}
            renderInput={params => (
              <CustomFilterInput
                {...params}
                variant="outlined"
                placeholder={isEmpty(locationFilter) ? 'All locations' : ''}
              />
            )}
            renderOption={(option, { selected }) => {
              return (
                <Box display="flex" gridGap={4}>
                  <Checkbox
                    icon={<CheckboxDefault width={20} height={20} />}
                    checkedIcon={<CheckboxActive width={20} height={20} />}
                    checked={selected}
                    color="primry"
                    className={classes.checkboxIcon}
                  />
                  <span>{option.name}</span>
                </Box>
              );
            }}
            renderTags={(value, getTagProps) => {
              let newValue = value.filter(it => locationFilter?.includes(it));
              return newValue.length === 1 ? (
                <div className={classes.tagStyle}>{newValue[0].name}</div>
              ) : newValue.length > 1 ? (
                <Box>Locations ({newValue.length})</Box>
              ) : (
                ''
              );
            }}
            PaperComponent={({ children, ...other }) => (
              <Paper
                {...other}
                style={{ width: 320 }}
                className={classes.multiSelectWrapper}
              >
                {children}
              </Paper>
            )}
            style={{ width: locationFilter.length === 1 ? 200 : 160 }}
            isSelected={locationFilter.length === 1}
            closeIcon={
              <CloseCircleFilled
                style={{ margin: 0, padding: 0 }}
                className="ant-input-clear-icon"
                onClick={e => {
                  setProjectFilter('');
                  setLocationFilter([]);
                  setListProjects([]);
                }}
              />
            }
          />

          <CustomAutocomplete
            value={
              !isEmpty(projectFilter)
                ? listProjects.find(it => it?.id === projectFilter)
                : null
            }
            autoComplete
            options={sortAlphabetically(listProjects, 'name')}
            getOptionLabel={option => option?.name || ''}
            onChange={(event, value) => {
              value && setProjectFilter(value?.id);
            }}
            popupIcon={<ArrowDown />}
            closeIcon={
              <CloseCircleFilled
                style={{ margin: 0, padding: 0 }}
                className="ant-input-clear-icon"
                onClick={e => {
                  setProjectFilter('');
                }}
              />
            }
            renderInput={params => (
              <CustomTextField
                fullWidth
                variant="outlined"
                placeholder="Select project"
                {...params}
              />
            )}
            style={{ width: !isEmpty(projectFilter) ? 200 : 170 }}
            PaperComponent={({ children, ...other }) => (
              <Paper
                {...other}
                style={{ width: 320 }}
                className={classes.selectWrapper}
              >
                {children}
              </Paper>
            )}
          />
        </Box>

        <CustomButton
          onClick={() => onResetFilterOps()}
          disabled={isEmpty(locationFilter)}
        >
          Reset
        </CustomButton>
      </Box>
    </Toolbar>
  );
};

export default GroupToolbar;
