import AvatarInCall from './AvatarInCall';
import { LocalStreamContainer } from './styled';
import AgoraInstanceTelecounsel from '@/agora/agoraInstanceTelecounsel';
import {
  CallEndIcon,
  VideoCam,
  VideoCamOff,
  Mirco,
  MircoOff,
  NRICInforIcon,
  SharingIcon,
  ChatMessIcon,
  MincAdmin
} from '@/assets/svg';
import { useTimer } from '@/helpers/useHookHelpers';
import {
  Box,
  Fab,
  Grid,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles({
  gridWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end'
  },

  textName: {
    fontSize: 24,
    fontFamily: 'Lato',
    fontWeight: 600,
    color: '#333333'
  },
  textTime: {
    fontSize: 20,
    color: '#333333'
  },

  callEndBtn: {
    width: 56,
    height: 56,
    backgroundColor: '#db3d49',
    borderRadius: 16,
    boxShadow: 'none'
  },
  btn: {
    width: 56,
    height: 56,
    backgroundColor: 'white',
    borderRadius: 16,
    boxShadow: 'none'
  },
  btnSelected: {
    backgroundColor: '#ad5e99'
  },

  localStream: {
    width: '100%',
    height: '100%',

    borderRadius: 16,
    // backgroundColor: "#333333",

    '&div:nth-child(1)': {
      zIndex: 0,
      borderRadius: 16,
      position: 'absolute'
    },

    '& .text': {
      zIndex: 1,
      color: 'white',
      position: 'absolute',
      bottom: 20,
      left: 20,

      fontSize: 24,
      fontFamily: 'Lato',
      fontWeight: 600
    }
  },

  iconNewMessage: {
    position: 'absolute',
    width: 15,
    height: 15,
    borderRadius: '50%',
    background: '#ea6b75',
    top: 8,
    right: 8
  }
});

const StreamFooter = ({
  verifyingUserData,
  transform,
  isMuteVideo,
  isMuteAudio,
  isMuteSpeaker,
  videoChannelId,
  isSharing,
  showChat,
  showNRIC,
  setEndModal,
  handleToggleChatMessage,
  handleToggleNRIC,
  isPatientMuteVideo,
  startCallTime,
  hasNewMessage
}) => {
  const classes = useStyles();
  const timer = useTimer(startCallTime, 'HH:mm:ss');

  const theme = useTheme();
  const mdBreakpoints = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      position="absolute"
      bottom={isSharing ? 20 : 0}
      p={5}
      width={isSharing ? 'calc(100% - 385px)' : '100%'}
    >
      <Grid container>
        <Grid xs={4} className={classes.gridWrapper}>
          <Typography
            className={classes.textName}
            style={{
              color:
                isSharing || (!isSharing && isPatientMuteVideo)
                  ? '#ffffff'
                  : '#333333'
            }}
          >
            {verifyingUserData.FullName || verifyingUserData.PatientFullName}
          </Typography>
          <Typography
            className={classes.textTime}
            style={{
              color:
                isSharing || (!isSharing && isPatientMuteVideo)
                  ? '#ffffff'
                  : '#333333'
            }}
          >
            {timer}
          </Typography>
        </Grid>
        <Grid xs={4} className={classes.gridWrapper}>
          <Box display="flex" justifyContent="space-between" gridGap={80}>
            <Box display="flex" gridGap={16}>
              <Fab
                className={classes.callEndBtn}
                onClick={() => setEndModal(true)}
              >
                <CallEndIcon />
              </Fab>

              <Fab
                className={classes.btn}
                aria-label="enable-video"
                onClick={() => {
                  AgoraInstanceTelecounsel.instance.muteVideo(!isMuteVideo);
                }}
              >
                {isMuteVideo ? (
                  <VideoCamOff color="#ad5e99" />
                ) : (
                  <VideoCam color="#ad5e99" />
                )}
              </Fab>
              <Fab
                className={classes.btn}
                aria-label="mute-audio"
                onClick={() => {
                  AgoraInstanceTelecounsel.instance.muteAudio(!isMuteAudio);
                }}
              >
                {isMuteAudio ? (
                  <MircoOff color="#ad5e99" />
                ) : (
                  <Mirco color="#ad5e99" />
                )}
              </Fab>
            </Box>
            <Box display="flex" gridGap={16}>
              <Fab
                className={clsx(classes.btn, {
                  [classes.btnSelected]: isSharing
                })}
                aria-label="sharing"
                onClick={() => {
                  if (!isSharing) {
                    AgoraInstanceTelecounsel.instance.joinChannel(
                      videoChannelId,
                      true
                    );
                    // shareScreenToPatient(channelId)
                  } else {
                    AgoraInstanceTelecounsel.instance.leaveChannel({
                      leaveOnlyShareScreen: true
                    });
                  }
                }}
              >
                <SharingIcon color={isSharing ? 'white' : '#ad5e99'} />
              </Fab>
              <Fab
                className={clsx(classes.btn, {
                  [classes.btnSelected]: showNRIC
                })}
                aria-label="NRIC"
                onClick={handleToggleNRIC}
              >
                <NRICInforIcon color={showNRIC ? 'white' : '#ad5e99'} />
              </Fab>
              <Fab
                className={clsx(classes.btn, {
                  [classes.btnSelected]: showChat
                })}
                aria-label="chat"
                onClick={handleToggleChatMessage}
                style={{ position: 'relative' }}
              >
                {hasNewMessage && (
                  <div className={classes.iconNewMessage}></div>
                )}
                <ChatMessIcon color={showChat ? 'white' : '#ad5e99'} />
              </Fab>
            </Box>
          </Box>
        </Grid>
        {isSharing ? null : (
          <Grid
            xs={4}
            className={classes.gridWrapper}
            style={{ alignItems: 'flex-end' }}
          >
            <LocalStreamContainer
              key="sub_stream"
              id="sub_stream"
              transform={transform}
              isMuteVideo={isMuteVideo}
              isHighlight={showNRIC || showChat}
              isResponsive={mdBreakpoints}
            >
              <Box className="mute-video-layer">
                <Box
                  position="absolute"
                  top="50%"
                  left="50%"
                  style={{ transform: 'translate(-50%, -50%)' }}
                  zIndex={2}
                  border="unset !important"
                >
                  <AvatarInCall isPatient>
                    <MincAdmin color={'#FFE9FB'} />
                  </AvatarInCall>
                </Box>
                <Typography className="text">You</Typography>
              </Box>
            </LocalStreamContainer>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default StreamFooter;
