import Api from '../../helpers/Api';
import { debounced } from '../../helpers/CommonHelper';
import { ArrowDown } from '@/assets/svg';
import { CloseCircleFilled } from '@ant-design/icons';
import { withStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { get } from 'lodash';
import React, { useEffect } from 'react';

const PAGING_OPTIONS = {
  pageIndex: 0,
  pageSize: 10
};

const CustomAutocomplete = withStyles({
  root: {
    '&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      height: 36,
      paddingRight: 6,
      paddingLeft: 16,

      '& .MuiAutocomplete-input:first-child': {
        display: prop => (prop.isSelected ? 'none' : '')
      }
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
      padding: 0
      // paddingRight: prop => (prop.isSelected ? 0 : 42)
    }
  }
})(Autocomplete);

const CustomAsyncAutoComplete = ({
  label,
  endpoint,
  method = 'get',
  defaultFilter,
  value,
  error,
  getOptionLabel,
  hasPayload = true,
  nameKey = 'name',
  renderInput,
  multiple = false,
  disableCloseOnSelect,
  renderOption,
  style,
  onChange,
  renderTags,
  PaperComponent,
  isLocation = false,
  closeIcon
}) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [inputValue, setInputValue] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [pageOptions, setPageOptions] = React.useState(PAGING_OPTIONS);
  const [totalItem, setTotalItem] = React.useState(0);

  React.useEffect(() => {
    if (!open) return;
    !loading && setLoading(true);
    debounced(async () => {
      const { result, status } = await Api[method](
        endpoint,
        hasPayload && {
          ...defaultFilter,
          ...pageOptions,
          search: inputValue
        }
      );

      let newOptions = get(result, 'list', []);
      if (isLocation) {
        newOptions = get(result, 'list', []).filter(
          item => item?.name && item?.address
        );
      }

      setLoading(false);
      setOptions(newOptions);
      setTotalItem(get(result, 'totalItem', 0));
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, open]);

  const loadMoreResults = async () => {
    if (totalItem === options.length) return;
    setPageOptions(pageOptions => ({
      ...pageOptions,
      pageSize: pageOptions.pageSize + 10
    }));
    debounced(async () => {
      const { result, status } = await Api[method](
        endpoint,
        hasPayload && {
          ...defaultFilter,
          pageIndex: 0,
          pageSize: pageOptions.pageSize + 10,
          search: inputValue
        }
      );
      let newOptions = get(result, 'list', []);
      if (isLocation) {
        newOptions = get(result, 'list', []).filter(
          item => item?.name && item?.address
        );
      }

      setLoading(false);
      setOptions(newOptions);
    }, 500);
  };

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
      setPageOptions(PAGING_OPTIONS);
    }
  }, [open]);

  return (
    <CustomAutocomplete
      multiple={multiple}
      id="asynchronous-autocomplete"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={getOptionLabel}
      options={options}
      loading={loading}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        setPageOptions(PAGING_OPTIONS);
      }}
      value={value}
      onChange={onChange}
      renderInput={
        renderInput ||
        (params => (
          <TextField
            {...params}
            label={label}
            error={!!error}
            helperText={error}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
          />
        ))
      }
      ListboxProps={{
        style: { maxHeight: 330 },
        onScroll: event => {
          const { scrollHeight, scrollTop, clientHeight } = event.currentTarget;
          const scroll = scrollHeight - scrollTop - clientHeight;

          if (scroll < 1) loadMoreResults();
        }
      }}
      renderOption={
        renderOption ||
        (option => {
          return nameKey ? option[nameKey] : option.fullName;
        })
      }
      popupIcon={<ArrowDown />}
      style={style ? style : { marginLeft: 0 }}
      disableCloseOnSelect={disableCloseOnSelect}
      PaperComponent={PaperComponent}
      renderTags={renderTags}
      closeIcon={
        closeIcon || (
          <CloseCircleFilled
            style={{ margin: 0, padding: 0 }}
            className="ant-input-clear-icon"
          />
        )
      }
    />
  );
};

export default CustomAsyncAutoComplete;
