import telecounselAppointmentConfigDispatch from '../action';

const initialState = {
  bookings: [],
  headingKeys: [],
  startTime: '',
  endTime: ''
};

const telecounselAppointmentConfigReducer = telecounselAppointmentConfigDispatch(
  initialState,
  {
    [telecounselAppointmentConfigDispatch.getDataSuccess]: (state, payload) => {
      const { bookings, keys, startTime, endTime } = payload.data;
      return { ...state, bookings, headingKeys: keys, startTime, endTime };
    },
    [telecounselAppointmentConfigDispatch.updateListBooking]: (
      state,
      payload
    ) => {
      const { startDate, isActive, baseSlot } = payload;
      const newBookings = state.bookings.map(item => {
        if (item.startDate === startDate) {
          return { ...item, isActive, baseSlot };
        }
        return item;
      });
      return { ...state, bookings: newBookings };
    }
  }
);

export default telecounselAppointmentConfigReducer;
