import Enum from '@/enum/Enum';

export const HS_COLUMN_UPCOMING = [
  {
    label: 'Name',
    stateValue: 'name'
  },
  {
    label: 'ID number',
    stateValue: 'idNumber',
    minWidth: 112
  },
  {
    label: 'Service',
    stateValue: 'service'
  },
  {
    label: 'Date & Time',
    stateValue: 'date',
    sortBy: 'Date',
    minWidth: 112
  },
  {
    label: 'Package',
    stateValue: 'package'
  },
  {
    label: 'Location',
    stateValue: 'location'
  },
  {
    label: 'Total cost',
    stateValue: 'totalCost',
    minWidth: 112
  },
  {
    label: 'Status',
    stateValue: 'status'
  },
  {
    label: 'Action'
  }
];

export const HS_SERVICE_TYPES = Enum({
  MMS: 'MMS',
  HBS: 'HBS',
  EHS: 'EHS',
  CHS: 'CHS',
  Others: 'Others'
});

export const HS_PAXSCREENING_STATUS = {
  Upcoming: 'Upcoming',
  CheckInLocation: 'CheckInLocation',
  CheckIn: 'CheckIn',
  InProgress: 'InProgress',
  Completed: 'Completed',
  Cancelled: 'Cancelled',
  Missed: 'Missed'
};

export const HS_PAXSCREENING_DISPLAY_STATUS = {
  Upcoming: 'Upcoming',
  CheckIn: 'Registered',
  InProgress: 'In Progress',
  Completed: 'Completed',
  Cancelled: 'Cancelled',
  Missed: 'Missed',
  CheckInLocation: 'Checked In'
};

export const STATUS_DISPLAY_STATION = {
  UnAssigned: 'Pending',
  Awaiting: 'Upcoming',
  Done: 'Done',
  InProgress: 'In progress'
};

export const STATUS_STATION = {
  UnAssigned: 'UnAssigned',
  Awaiting: 'Awaiting',
  Done: 'Done'
};

export const STATUS_DISPLAY_COUNSEL = {
  Upcoming: 'Upcoming',
  InProgress: 'In Progress',
  Completed: 'Completed',
  Cancelled: 'Cancelled',
  Missed: 'Missed',
  Rejected: 'Rejected'
};

export const HS_PAXSCREENING_STATUS_COLORS = {
  [HS_PAXSCREENING_DISPLAY_STATUS.Upcoming]: {
    txt: '#AD5E99',
    background: '#FFE9FB'
  },
  [HS_PAXSCREENING_DISPLAY_STATUS.CheckIn]: {
    txt: '#3C7D0E',
    background: '#83cc2629'
  },
  [HS_PAXSCREENING_DISPLAY_STATUS.InProgress]: {
    txt: '#23A6CC',
    background: '#23a6cc29'
  },
  [HS_PAXSCREENING_DISPLAY_STATUS.Completed]: {
    txt: '#0B8E5E',
    background: '#15a47029'
  },
  [HS_PAXSCREENING_DISPLAY_STATUS.Cancelled]: {
    txt: '#333333',
    background: '#F1F1F1'
  },
  [HS_PAXSCREENING_DISPLAY_STATUS.Missed]: {
    txt: '#DB3D49',
    background: '#db3d4929'
  },
  [HS_PAXSCREENING_DISPLAY_STATUS.CheckInLocation]: {
    txt: '#9365E8',
    background: '#9365e829'
  },
  [STATUS_DISPLAY_STATION.Done]: {
    txt: '#0B8E5E',
    background: '#15a47029'
  },
  [STATUS_DISPLAY_STATION.Awaiting]: {
    txt: '#AD5E99',
    background: '#FFE9FB'
  },
  [STATUS_DISPLAY_STATION.UnAssigned]: {
    txt: '#333333',
    background: '#f1f1f1'
  },
  [STATUS_DISPLAY_STATION.InProgress]: {
    txt: '#9365E8',
    background: '#9365e829'
  },
  [STATUS_DISPLAY_COUNSEL.Rejected]: {
    txt: '#E37F2A',
    background: '#e37f2a29'
  }
};

export const HS_UPCOMING_PAXSCREENING_STATUS = Enum({
  Upcoming: 'Upcoming',
  Cancelled: 'Cancelled',
  Missed: 'Missed'
});

export const HS_ALL_SCREENINGS_PAXSCREENING_STATUS = [
  {
    value: 'Upcoming',
    key: 'Upcoming'
  },
  {
    value: 'Checked In',
    key: 'CheckInLocation'
  },
  {
    value: 'Registered',
    key: 'CheckIn'
  },
  {
    value: 'In station',
    key: 'InProgress'
  },
  {
    value: 'Completed',
    key: 'Completed'
  },
  {
    value: 'Cancelled',
    key: 'Cancelled'
  },
  {
    value: 'Missed',
    key: 'Missed'
  }
];

export const GENDER = Enum({
  Male: 'Male',
  Female: 'Female'
});

export const QUICK_PRICE_VALUE_RANGE = [
  'Under $200',
  '$200 - $500',
  '$1,000 - $2,000',
  '$2,000 up'
];

export const STEPS = [
  {
    name: 'Select patient'
  },
  {
    name: 'Select place and time'
  },
  {
    name: 'Select service'
  }
];

export const STEPS_CONTROL = {
  back: 'Back',
  next: 'Next',
  finish: 'Create'
};

export const SUFFIX_LABNUMBER_TYPES = {
  SE_GF_SUFFIX: 'se-gf-suffix',
  UP_SUFFIX: 'up-suffix'
};

export const FORMAT_LIST_TESTS = {
  lstTests: 'Tests',
  lstMeasurements: 'Measurements',
  lstProcedures: 'Procedures',
  lstRadiologies: 'Radiologies',
  lstReviews: 'Reviews',
  lstVaccinations: 'Vaccinations'
};

export const COLUMN_SERVICES = [
  {
    label: 'Name',
    stateValue: 'name',
    minWidth: 172
  },
  {
    label: 'Type',
    stateValue: 'type',
    minWidth: 80
  },
  {
    label: 'Status',
    stateValue: 'status'
  },
  {
    label: 'Value',
    stateValue: 'value'
  },
  {
    label: 'Opt out',
    stateValue: 'optOut',
    minWidth: 80
  },
  {
    label: 'Action',
    stateValue: 'action',
    minWidth: 70
  }
];

export const SERVICE_TYPES = {
  BMI: 'bmi',
  WAIST_HIP_RADIO: 'waist',
  ACUITY: 'acuity',
  BLOOD_PRESSURE: 'blood',
  URINE_DIPSTICK: 'urinedipstick',
  APR: 'apr',
  PVT: 'pvt',
  EMF: 'emf',
  ISHIHARA: 'ishihara',
  OTHER: 'other',
  FAT: 'fat',
  TONOMETRY: 'tonometry'
};

export const CHECKED_TYPE = {
  default: 'None',
  aided: 'Aided',
  unAided: 'UnAided'
};

export const ORDER_INVOCE_TESTS = {
  Measurements: 0,
  Procedures: 1,
  Radiologies: 2,
  Vaccinations: 3,
  Reviews: 4
};

export const FIELD_ARRAY_VALUE = ['MultiSelect', 'Rating', 'DropdownList'];

export const HS_BILLING_TYPES = Enum({
  Billable: 'Billable',
  Eligible: 'Eligible',
  Subsidy: 'Subsidy'
});
export const LIST_PROVIDER_LABNUMBER = {
  Innoquest: 'Innoquest',
  Eurofins: 'Eurofins'
};

export const LIST_PROVIDER_SUFFIX_PREFIX = {
  Innoquest: ['SE', 'GF'],
  Eurofins: ['SE', 'GF', 'ET']
};

export const LIST_PROVIDER_UP_PREFIX = {
  Innoquest: ['UP'],
  Eurofins: ['UP']
};
