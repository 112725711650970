import { appointmentManagementDispatcher } from '..';
import AsyncAutoComplete from '../../../components/AsyncAutoComplete';
import { renderIf } from '../../../helpers';
import validateData from '../../../helpers/validationHelpers/validationSchema';
import { WrapperInput } from '../../category/component/CategoryModal/styled';
import ControlDatepicker from '../../common/component/ControlDatepicker';
import FormTimePicker from '../../common/component/FormTimePicker';
import {
  ButtonEnhance,
  ModalPage,
  ModalFooter
} from '../../common/componentUI/commonStyleComponents';
import { TYPE_MODAL, renderAppointmentType } from '../constants';
import { WrapperFormControl, WrapperSelect } from './styled';
import {
  MIN_TIME_PICK,
  MAX_TIME_PICK
} from '@/module/booking-management/constants';
import {
  DialogContent,
  Grid,
  InputLabel,
  MenuItem,
  FormHelperText
} from '@material-ui/core';
import { ClearRounded } from '@material-ui/icons';
import { LocalizationProvider } from '@material-ui/pickers';
import MomentAdapter from '@material-ui/pickers/adapter/moment';
import { get } from 'lodash';
import moment from 'moment';
import React, { useState, useEffect, useMemo } from 'react';

const AppointmentForm = ({
  onClose,
  selectedItem,
  typeModal,
  onSubmit,
  listAvailableUsers,
  isDoctor,
  isSwabber,
  isHiddenAppointmentTypes
}) => {
  const [roleType, setRoleType] = useState(isSwabber ? 'Swabber' : 'Doctor');
  const getMinTime = value => {
    if (!value) return MIN_TIME_PICK;

    const currentDate = moment().format('DD-MM-YYYY');
    const comparedDate = moment(value).format('DD-MM-YYYY');
    if (currentDate === comparedDate) return moment();
    return MIN_TIME_PICK;
  };

  const [formValue, setFormValue] = useState({
    appointmentDate: moment(),
    appointmentTime: moment()
      .add(1, 'hour')
      .set({ minutes: 0, millisecond: 0 })
  });
  const [errors, setErrors] = useState({});

  const canAssignDoctor = useMemo(() => typeModal === TYPE_MODAL.AssignDoctor, [
    typeModal
  ]);

  useEffect(() => {
    if (!selectedItem) return;
    const newFormValue = { ...selectedItem } || {};

    if (newFormValue.appointmentDate) {
      const currentMinutes = moment(selectedItem.appointmentDate).get('minute');
      const minMinutesSet = (Math.round(currentMinutes / 15) * 15) % 60;
      newFormValue.appointmentTime = moment(selectedItem.appointmentDate).set({
        minute: minMinutesSet
      });
      newFormValue.appointmentDate = moment(selectedItem.appointmentDate);
    }

    setFormValue(newFormValue);
  }, [selectedItem]);

  const handleSubmit = async () => {
    const hours = moment(formValue.appointmentTime).get('hour');
    const minutes = moment(formValue.appointmentTime).get('minute');
    const appointmentTime = moment(formValue.appointmentDate).set({
      hours,
      minutes
    });

    try {
      await validateData(
        'appointmentSchema',
        {
          ...formValue,
          appointmentTime,
          typeModal
        },
        () => {
          const { appointmentTime, appointmentDate, ...body } = formValue;
          const newAppointmentDate = moment(appointmentDate).set({
            hours,
            minutes,
            seconds: 0
          });
          onSubmit(typeModal, {
            ...body,
            appointmentDate: newAppointmentDate
          });
          setErrors({});
        }
      );
    } catch (errs) {
      setErrors(errs);
    }
  };

  const onHandleChangeTime = (key, valueTime) => {
    setFormValue({ ...formValue, [key]: valueTime });
  };

  const handleChangeForm = key => e => {
    let value = get(e, 'target.value');

    if (key === 'appointmentDate') {
      value = e;
    } else if (key === 'appointmentType') {
      formValue.doctorId = '';
      formValue.doctorFullName = '';
      value = get(e, 'target.value');
    } else {
      value = get(e, 'target.value');
    }
    setFormValue({ ...formValue, [key]: value });
  };

  useEffect(() => {
    let newRoleType = roleType;

    if (formValue.appointmentType) {
      formValue.appointmentType === 'TeleArt'
        ? (newRoleType = 'Swabber')
        : (newRoleType = 'Doctor');
      appointmentManagementDispatcher.getAvailableUser(newRoleType);
    }

    setRoleType(newRoleType);
  }, [formValue]);

  return (
    <LocalizationProvider dateAdapter={MomentAdapter}>
      <ModalPage style={{ maxWidth: 'none' }}>
        <DialogContent>
          <div className="test-header">
            {typeModal === TYPE_MODAL.Edit && 'Edit Appointment'}
            {typeModal === TYPE_MODAL.AssignDoctor && 'Assign Doctor'}
            {typeModal === TYPE_MODAL.View && 'View Details'}
            {typeModal === TYPE_MODAL.Create && 'Create Appointment'}
            <ClearRounded
              style={{ cursor: 'pointer', float: 'right', color: '#CACFD3' }}
              onClick={onClose}
            />
          </div>
          <div className="test-body">
            <Grid container spacing={2} style={{ marginTop: 10 }}>
              {typeModal !== TYPE_MODAL.Create ? (
                <Grid item xs={4}>
                  <WrapperInput
                    label="Patient"
                    disabled
                    value={
                      formValue.patientFullName
                        ? `${formValue.patientFullName} (${formValue.patientShortId})`
                        : ''
                    }
                  />
                </Grid>
              ) : (
                <Grid item xs={6} style={{ marginTop: 5 }}>
                  <AsyncAutoComplete
                    label="Patient"
                    endpoint="Patients/filter-for-teleconsult"
                    method="post"
                    value={formValue.patient}
                    defaultFilter={{
                      appointmentType: 'TeleConsult'
                    }}
                    setValue={newValue =>
                      setFormValue({
                        ...formValue,
                        patient: newValue,
                        appointmentType: '',
                        doctorId: ''
                      })
                    }
                    error={errors.patient}
                    getOptionLabel={option => option.fullName || ''}
                    renderKey="fullName"
                  />
                </Grid>
              )}
              {typeModal !== TYPE_MODAL.Create && (
                <Grid item xs={2}>
                  <WrapperInput
                    label="Patient NRIC"
                    disabled
                    value={formValue.patientNRIC || ''}
                  />
                </Grid>
              )}
              <Grid item xs={6}>
                <WrapperFormControl
                  error={!!errors.appointmentType}
                  disabled={canAssignDoctor || typeModal === TYPE_MODAL.View}
                >
                  <InputLabel id="appointment-type-label">
                    Appointment Type
                  </InputLabel>
                  <WrapperSelect
                    disabled={!formValue?.patient}
                    className="select-custom"
                    onChange={handleChangeForm('appointmentType')}
                    labelId="appointment-type-label"
                    value={formValue.appointmentType || ''}
                    renderValue={value =>
                      renderAppointmentType(
                        false,
                        isHiddenAppointmentTypes,
                        typeModal !== TYPE_MODAL.Create
                          ? typeModal !== TYPE_MODAL.Create
                          : formValue?.patient?.hasPayment,
                        typeModal === TYPE_MODAL.Create
                      )[value]
                    }
                  >
                    {renderAppointmentType(
                      false,
                      isHiddenAppointmentTypes,
                      formValue?.patient?.hasPayment,
                      typeModal === TYPE_MODAL.Create
                    ).map(item => {
                      return (
                        <MenuItem key={item.value} value={item.value}>
                          {item.key}
                        </MenuItem>
                      );
                    })}
                  </WrapperSelect>
                  <FormHelperText>{errors.appointmentType}</FormHelperText>
                </WrapperFormControl>
              </Grid>
              <ControlDatepicker
                disabled={canAssignDoctor || typeModal === TYPE_MODAL.View}
                disablePast
                value={formValue.appointmentDate}
                onChange={handleChangeForm('appointmentDate')}
                label="Date"
                error={errors.appointmentDate}
              />
              <Grid item xs={6}>
                <FormTimePicker
                  minutesStep={15}
                  minTime={getMinTime(formValue.appointmentDate)}
                  maxTime={MAX_TIME_PICK}
                  disabled={canAssignDoctor || typeModal === TYPE_MODAL.View}
                  value={formValue.appointmentTime || ''}
                  onChange={value => {
                    onHandleChangeTime('appointmentTime', value);
                  }}
                  label="Time"
                  error={errors.appointmentTime}
                />
              </Grid>
              {/* <Grid item xs={6}>
                <WrapperFormControl
                  error={!!errors.doctorId}
                  disabled={
                    !(canAssignDoctor || typeModal === TYPE_MODAL.Create) ||
                    typeModal === TYPE_MODAL.View ||
                    !(formValue?.patient || formValue?.patientId)
                  }
                >
                  <InputLabel id="appointment-type-label">
                    {roleType}
                  </InputLabel>
                  <WrapperSelect
                    className="select-custom"
                    labelId="appointment-type-label"
                    value={formValue.doctorId || ''}
                    onChange={handleChangeForm('doctorId')}
                  >
                    {listAvailableUsers &&
                      listAvailableUsers.map((item, index) => {
                        return (
                          <MenuItem value={item.id} key={`${item.id}-${index}`}>
                            {item.fullName}
                          </MenuItem>
                        );
                      })}

                    {renderIf(isDoctor)(
                      <MenuItem
                        value={formValue.doctorId}
                        key={`${formValue.doctorId}`}
                      >
                        {formValue.doctorFullName}
                      </MenuItem>
                    )}
                  </WrapperSelect>
                  {errors.doctorId && (
                    <FormHelperText>{errors.doctorId}</FormHelperText>
                  )}
                </WrapperFormControl>
              </Grid> */}
            </Grid>
          </div>
          <ModalFooter>
            <ButtonEnhance
              background="#E9E9E9"
              color="black"
              backgroundHover="#ccc"
              onClick={onClose}
            >
              Cancel
            </ButtonEnhance>

            {renderIf(typeModal !== TYPE_MODAL.View)(
              <ButtonEnhance onClick={handleSubmit}>
                {typeModal === TYPE_MODAL.Edit
                  ? 'Save'
                  : typeModal === TYPE_MODAL.Create
                  ? 'Create'
                  : 'Assign'}
              </ButtonEnhance>
            )}
          </ModalFooter>
        </DialogContent>
      </ModalPage>
    </LocalizationProvider>
  );
};

export default AppointmentForm;
