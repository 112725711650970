import { BILLING_TYPE, COLUMN_STAFFTYPE } from '../../constants';
import { renderActionsStaffType } from '../../renderColumn';
import { isBillableProject } from '../../utils';
import ModalImportStaffType from '../ModalImportStaffType';
import { IOSSwitch } from '../mainScreen.styled';
import BillingTypeConfirmationModal from './BillingTypeConfirmationModal';
import { RemoveIcon } from '@/assets/svg';
import { CombinedIcon } from '@/assets/svg';
import { generateDedicatedLink } from '@/helpers';
import { TYPE_MODAL } from '@/module/common/constants/typeModal';
import CustomDate from '@/new-components/CustomDate';
import CustomMenuActions from '@/new-components/CustomMenuActions';
import customToast from '@/new-components/CustomNotification';
import CustomSelectContainer from '@/new-components/CustomSelectOld/CustomSelectContainer';
import TableWithFooter from '@/new-components/CustomTable/TableWithFooter';
import CustomTooltip from '@/new-components/CustomTooltip';
import { Menu, Radio } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { MoreVertRounded } from '@material-ui/icons';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { LAB_PROVIDERS } from '@/new-components/CustomModal/ModalAssignLabNumber';

const CustomTextField = withStyles({
  root: {
    fontSize: 20,

    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      height: 48
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      paddingLeft: 5,
      fontSize: 16
    },
    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6'
      }
    }
  }
})(TextField);

const CustomFormControlLabel = withStyles({
  label: {
    fontSize: 16,
    color: '#333333'
  }
})(FormControlLabel);

const CustomInputLabel = withStyles({
  root: {
    color: '#666666',
    marginBottom: 8,
    fontWeight: 600
  }
})(InputLabel);

const CustomTitleLabel = withStyles({
  root: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 24
  }
})(Typography);

const GeneralInformationStep = ({
  errors,
  setErrors,
  formValue,
  setFormValue,
  listConsentForms,
  copyLinkClipboard
}) => {
  const [open, setOpen] = useState(false);
  const [itemSelected, setItemSelected] = useState({});
  const [typeModal, setTypeModal] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const [billingType, setBillingType] = useState('');
  const [
    showBillingTypeConfirmationModal,
    setShowBillingTypeConfirmationModal
  ] = useState(false);

  const handleChange = key => e => {
    let value;
    const { lstHealthScreenings } = formValue;
    let firstTimeValue = '';
    let endTimeValue = '';
    if (lstHealthScreenings.length > 0) {
      /**
       * firstTimeValue is the most small startDate in the list of screening date
       * endTimeVlue is the biggest endDate in the list of screening dates
       */
      const startDateUnixArray = lstHealthScreenings?.map(item =>
        moment(item.startDate)
          .startOf('date')
          .unix()
      );
      const endDateUnixArray = lstHealthScreenings?.map(item =>
        moment(item.endDate)
          .endOf('date')
          .unix()
      );

      firstTimeValue = Math.min(...startDateUnixArray);
      endTimeValue = Math.max(...endDateUnixArray);
    }

    switch (key) {
      case 'startDate': {
        const startDateCompare = moment(e)
          .startOf('date')
          .unix();
        if (firstTimeValue && firstTimeValue < startDateCompare) {
          customToast(
            'info',
            'Start time of project is not valid. Please check data from screening dates again.'
          );
        }
        value = moment(e);
        if (
          moment(value)
            .startOf('date')
            .unix() >
          moment(formValue.endDate)
            .startOf('date')
            .unix()
        ) {
          return setFormValue({
            ...formValue,
            startDate: value,
            endDate: value
          });
        }
        break;
      }
      case 'endDate': {
        const endDateCompare = moment(e)
          .endOf('date')
          .unix();
        if (endTimeValue && endTimeValue > endDateCompare) {
          customToast(
            'info',
            'End time of project is not valid. Please check data from screening dates again.'
          );
        }
        value = moment(e);
        break;
      }

      case 'isSuppression':
      case 'payAtScreening': {
        value = get(e, 'target.checked');
        break;
      }
      case 'isPersonal': {
        value = get(e, 'target.value') === 'personal';
        break;
      }
      default:
        value = get(e, 'target.value');
        break;
    }

    setFormValue({
      ...formValue,
      [key]: value
    });
  };

  const handleChangeBillingType = billingType => {
    setFormValue(formValue => {
      return [
        'lstPackages',
        'lstProfiles',
        'lstMeasurements',
        'lstProcedures',
        'lstRadiologies',
        'lstReviews',
        'lstTests',
        'lstVaccinations'
      ].reduce(
        (previous, current) => ({
          ...previous,
          [current]: (formValue[current] || []).map(item => ({
            ...item,
            billableAmount: isBillableProject(billingType) ? item.price || 0 : 0
          }))
        }),
        {
          ...formValue,
          billingType
        }
      );
    });
  };

  const handleChangePersonal = (e, type) => {
    const value = type === 'personal' ? true : false;
    let newFormValue = {
      ...formValue,
      isPersonal: value
    };
    if (!value) newFormValue.isSuppression = value;
    setFormValue(newFormValue);
  };

  const changeTableValue = (action, value, position) => {
    let lstStaffTypeItems = [...formValue.lstStaffTypeItems];
    switch (action) {
      case TYPE_MODAL.Create:
        lstStaffTypeItems.push(value);
        break;
      case TYPE_MODAL.Edit:
        lstStaffTypeItems[position] = value;
        break;
      case TYPE_MODAL.Delete:
        lstStaffTypeItems.splice(position, 1);
        break;
      default:
        break;
    }
    setFormValue({ ...formValue, lstStaffTypeItems });
    setOpen(false);
  };

  const listActions = renderActionsStaffType({
    onEdit: () => {
      setAnchorEl(null);
      setOpen(true);
      setTypeModal(TYPE_MODAL.Edit);
    },
    onDelete: () => {
      setAnchorEl(null);
      changeTableValue(
        TYPE_MODAL.Delete,
        itemSelected.item,
        itemSelected.position
      );
    },
    isEditPrice: false
  });

  const clearConsentData = () => {
    setFormValue({
      ...formValue,
      consentId: ''
    });
  };

  const renderRow = (row, i) => (
    <>
      <TableCell>
        <CustomTooltip content={row.type} />
      </TableCell>
      <TableCell>
        {row.subsidy &&
          (row.staffValueType === 'Value'
            ? `$${row.subsidy}`
            : `${row.subsidy}%`)}
      </TableCell>
      <TableCell align="right">
        <IconButton
          style={{ width: 20, height: 20 }}
          onClick={e => {
            setAnchorEl(e.currentTarget);
            setItemSelected({ item: row, position: i });
            setTypeModal(TYPE_MODAL.Edit);
          }}
        >
          <MoreVertRounded />
        </IconButton>
      </TableCell>
    </>
  );

  return (
    <Box px={2.25} py={2}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CustomTitleLabel>Project information</CustomTitleLabel>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <CustomInputLabel>Select screening type</CustomInputLabel>
              <Grid container>
                <Grid item xs={6}>
                  <CustomFormControlLabel
                    control={
                      <Radio
                        name="Corporate"
                        color="primary"
                        checked={!formValue.isPersonal}
                        onChange={e => handleChangePersonal(e, 'corporate')}
                      />
                    }
                    label="Corporate screening"
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomFormControlLabel
                    control={
                      <Radio
                        name="Personal"
                        color="primary"
                        checked={formValue.isPersonal}
                        onChange={e => handleChangePersonal(e, 'personal')}
                      />
                    }
                    label="Personal screening"
                  />
                  {formValue.isPersonal && (
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      color="#666666"
                      fontSize={16}
                      ml={4}
                    >
                      Free payment
                      <IOSSwitch
                        checked={formValue.isSuppression}
                        onChange={handleChange('isSuppression')}
                      />
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <CustomInputLabel>Project name</CustomInputLabel>
              <CustomTextField
                fullWidth
                variant="outlined"
                placeholder="Enter project name"
                error={errors.name}
                helperText={errors.name}
                value={formValue.name || ''}
                onChange={handleChange('name')}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomInputLabel>Project code</CustomInputLabel>
              <CustomTextField
                disabled
                fullWidth
                variant="outlined"
                value={formValue.code || 'Auto-generated'}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomInputLabel>Start date</CustomInputLabel>
              <CustomDate
                disablePast
                selectedDate={formValue.startDate}
                placeholder="Select date"
                error={errors.startDate}
                style={{ height: 48, fontSize: 16 }}
                onChange={handleChange('startDate')}
                clearable={false}
              />
              {errors.startDate && (
                <Typography color="error">{errors.startDate}</Typography>
              )}
            </Grid>
            <Grid item xs={6}>
              <CustomInputLabel>End date</CustomInputLabel>
              <CustomDate
                disablePast
                selectedDate={formValue.endDate}
                placeholder="Select date"
                error={errors.endDate}
                style={{ height: 48, fontSize: 16 }}
                onChange={handleChange('endDate')}
                clearable={false}
              />
              {errors.endDate && (
                <Typography color="error">{errors.endDate}</Typography>
              )}
            </Grid>
            <Grid item xs={6}>
              <CustomInputLabel>Consent form</CustomInputLabel>
              <CustomSelectContainer
                data={listConsentForms}
                isMutiple={false}
                height="48px"
                clearable={!isEmpty(formValue.consentId)}
                renderValue={
                  (!isEmpty(listConsentForms) &&
                    listConsentForms.find(
                      test => test.id === formValue.consentId
                    )?.title) ||
                  'Select consent form'
                }
                filterValue={formValue.consentId}
                onChange={handleChange('consentId')}
                onClear={clearConsentData}
                label="title"
              />
            </Grid>
            <Grid item xs={6}>
              <CustomInputLabel>Billing type</CustomInputLabel>
              <CustomSelectContainer
                disabled={formValue.hasServedAppointment}
                data={Object.values(BILLING_TYPE)}
                isMutiple={false}
                value={formValue.billingType}
                renderValue={formValue.billingType}
                filterValue={formValue.billingType}
                onChange={event => {
                  setBillingType(event.target.value);
                  setShowBillingTypeConfirmationModal(true);
                }}
                height="48px"
              />
              {errors.billingType && (
                <Typography color="error">{errors.billingType}</Typography>
              )}
            </Grid>
            <Grid item xs={6}>
              <CustomInputLabel>Dedicated URL</CustomInputLabel>
              <CustomTextField
                fullWidth
                variant="outlined"
                value={generateDedicatedLink(formValue.code || '')}
                style={{ pointerEvents: 'none' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton
                        style={{ pointerEvents: 'auto' }}
                        onClick={() =>
                          copyLinkClipboard(
                            generateDedicatedLink(formValue.code || '')
                          )
                        }
                      >
                        <CombinedIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomInputLabel>Lab provider</CustomInputLabel>
              <CustomSelectContainer
                data={Object.values(LAB_PROVIDERS)}
                inputLabel="Provider"
                height="48px"
                isFullWidth
                isMutiple={false}
                clearable={false}
                value={formValue.labProvider || ''}
                renderValue={formValue.labProvider || 'Select provider'}
                filterValue={formValue.labProvider}
                onChange={handleChange('labProvider')}
                disabled={formValue.hasAssignedLabNumber}
              />
            </Grid>
            <Grid item xs={6}>
              <Box
                display="flex"
                justifyContent="space-between"
                color="#666666"
                fontSize={16}
              >
                <CustomInputLabel>Pay at Screening</CustomInputLabel>
                <IOSSwitch
                  checked={formValue.payAtScreening}
                  color="primary"
                  disabled={formValue.hasServedAppointment}
                  onChange={handleChange('payAtScreening')}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider light />
        </Grid>
        <Grid item xs={12} hidden={isBillableProject(formValue.billingType)}>
          <CustomTitleLabel>Staff discounts</CustomTitleLabel>
          <TableWithFooter
            data={formValue.lstStaffTypeItems || []}
            labelButton="Add staff type"
            columns={COLUMN_STAFFTYPE}
            onClick={e => {
              e && e.stopPropagation();
              setItemSelected({});
              setOpen(true);
              setTypeModal(TYPE_MODAL.Create);
            }}
            renderRow={renderRow}
          />
        </Grid>
      </Grid>
      <ModalImportStaffType
        typeModal={typeModal}
        title={`${typeModal === TYPE_MODAL.Create ? 'Add' : 'Edit'} staff type`}
        open={open}
        onClose={e => {
          e.stopPropagation();
          setOpen(false);
          setItemSelected({});
          setTypeModal(null);
        }}
        onSubmit={changeTableValue}
        selectedItem={itemSelected}
        setSelectedItem={setItemSelected}
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <CustomMenuActions listActions={listActions} />
      </Menu>

      <BillingTypeConfirmationModal
        billingType={billingType}
        onClose={() => setShowBillingTypeConfirmationModal(false)}
        onConfirm={() => {
          handleChangeBillingType(billingType);
          setShowBillingTypeConfirmationModal(false);
        }}
        open={showBillingTypeConfirmationModal}
      />
    </Box>
  );
};

export default GeneralInformationStep;
