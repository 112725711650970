/* eslint-disable react-hooks/exhaustive-deps */
import minkConfig from '../mink.config.json';
import { loadFromStorage } from './StorageHelper';
import moment from 'moment';
import { useRef, useEffect, useState } from 'react';

function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export { usePrevious };

export const useContainerDimensions = myRef => {
  const getDimensions = () => [
    myRef.current.offsetWidth,
    myRef.current.offsetHeight,
    myRef.current.offsetHeight / myRef.current.offsetWidth
  ];

  const [dimensions, setDimensions] = useState([0, 0, 1]);

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions());
    };

    if (myRef.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [myRef, myRef.current]);

  return dimensions;
};

export function useAbortableEffect(effect, dependencies) {
  const _isMounted = useRef(true); // mutable status object
  useEffect(() => {
    // pass the mutable object to the effect callback
    // store the returned value for cleanup
    const cleanUpFn = effect(_isMounted);
    return () => {
      // mutate the object to signal the consumer
      // this effect is cleaning up
      _isMounted.current = false;
      if (typeof cleanUpFn === 'function') {
        // run the cleanup function
        cleanUpFn();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies]);
}

export function useHover() {
  const [value, setValue] = useState(false);
  const ref = useRef(null);
  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);
  useEffect(
    () => {
      const node = ref.current;
      if (node) {
        node.addEventListener('mouseover', handleMouseOver);
        node.addEventListener('mouseout', handleMouseOut);
        return () => {
          node.removeEventListener('mouseover', handleMouseOver);
          node.removeEventListener('mouseout', handleMouseOut);
        };
      }
    },
    [ref.current] // Recall only if ref changes
  );
  return [ref, value];
}

export function useTimer(time, format = 'mm:ss') {
  const [duration, setDuration] = useState(0);

  useEffect(
    () => {
      const serverTimeOffset = loadFromStorage('serverTimeOffset');
      const joinedTimestamp = moment.utc(time).unix() * 1000;

      const intervalId = setInterval(() => {
        const currentTimestamp = moment.utc().unix() * 1000 + serverTimeOffset;
        setDuration(() => currentTimestamp - joinedTimestamp);
      }, 1000);

      return () => clearInterval(intervalId);
    },
    [] // Recall only if ref changes
  );
  return moment.utc(duration).format(format);
}

export function useTimerRejoin(time, format = 'mm:ss') {
  const [duration, setDuration] = useState(
    isNaN(time)
      ? 300
      : time + minkConfig.DOCTOR_REJOIN_TIMEOUT - new moment().unix()
  );
  useEffect(() => {
    const interval = setInterval(() => {
      if (duration <= 0) {
        clearInterval(interval);
      }
      setDuration(duration => duration - 1);
    }, 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration]);
  return moment
    .utc(moment.duration(duration, 'seconds').asMilliseconds())
    .format(format);
}
