import { ButtonEnhance } from '../../module/common/componentUI/commonStyleComponents';
import styled from 'styled-components';

export const StepperContainer = styled.div`
  height: 58px;
  width: 100%;

  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  display: grid;
  grid-template-columns: repeat(${p => p.numberItem}, 1fr);
`;

export const Step = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &.passed {
    background-color: white;
  }
  &.current {
    background-color: #ffe9fb;
  }
  &.incoming {
    background-color: #f6f6f6;
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.3;
  }
`;

export const Number = styled.span`
  width: 32px;
  height: 32px;
  background: #ad5e99;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 18px;
  font-family: 'Lato';
  margin-right: 12px;
  &.incoming {
    background-color: #c1c1c1;
  }
  &.passed {
    /* opacity: 0.3; */
  }
`;
export const Text = styled.span`
  font-size: 18px;
  font-weight: 600;
  font-family: 'Lato';

  &.current {
    color: #ad5e99;
  }
  &.passed {
    /* opacity: 0.3; */
  }
  &.incoming {
    color: #999999;
  }
`;
export const StepContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StepperControl = styled.div`
  border-top: 2.5px solid #e1e1e1;
  //padding-top: 10px;
  // margin-top: 20px;
`;
export const StepperButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const StepperButton = styled(ButtonEnhance)`
  justify-content: center;
  display: inline-flex;
  align-items: center;
  border-radius: 8px;
  height: 48px;
  font-size: 18px;
  font-weight: 400;
  margin-left: 16px;
  &.secondary {
    background: white;
    color: #ad5e99;
    border: 1px solid #ad5e99;
    &:hover {
      background: #ad5e99;
      color: white;
    }
  }
  &:disabled {
    opacity: 0.3;
  }
`;
