import CustomCountryCodeInput from '../CustomCountryCodeInput';
import validateData from '@/helpers/validationHelpers/validationSchema';
import { TYPE_MODAL } from '@/module/counsellors-management/constants';
import CustomButton from '@/new-components/CustomButton';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { get, isBoolean, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import NumberFormat from 'react-number-format';

const titleStyles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey.subText
  },
  text: {
    fontWeight: 600
  }
});

const CustomFormHelperText = withStyles({
  root: {
    alignItems: 'center',
    display: 'flex',
    fontFamily: 'OpenSans',
    fontSize: 12,
    gap: 6,
    marginLeft: 4,
    marginTop: 0,

    '& .MuiSvgIcon-root': {
      fontSize: 'unset'
    }
  }
})(FormHelperText);

const CustomDialogTitle = withStyles(titleStyles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.text}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const CustomDialog = withStyles({
  paper: {
    borderRadius: 16,
    width: 480
  }
})(Dialog);

const CustomDialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  }
}))(DialogContent);

const CustomDialogActions = withStyles({
  root: {
    margin: 0,
    padding: 16,
    justifyContent: 'flex-end'
  }
})(DialogActions);

const CustomTitleLabel = withStyles({
  root: {
    fontSize: 16,
    fontWeight: 600,
    color: '#333333'
  }
})(Typography);

const CustomTitleSubLabel = withStyles({
  root: {
    fontWeight: 600,
    color: '#666666'
  }
})(Typography);

const CustomTextField = withStyles({
  root: {
    fontSize: 20,
    width: '100%',

    '& .MuiOutlinedInput-root': {
      height: 48,
      borderRadius: 8
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      padding: '8px 5px',
      fontSize: 16
    },
    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6'
      }
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 0
    },
    '& .MuiFormHelperText-root': {
      alignItems: 'center',
      display: 'flex',
      fontFamily: 'OpenSans',
      fontSize: 12,
      gap: 6,
      marginLeft: 2,

      '& .MuiSvgIcon-root': {
        fontSize: 'unset'
      }
    }
  }
})(TextField);

const ModalAddOrEditCounsellorExecutive = ({
  typeModal,
  open,
  onClose,
  onSubmit,
  selectedItem,
  loading
}) => {
  const [errors, setErrors] = useState({});

  const [formValue, setFormValue] = useState({
    mobile: '',
    countryCode: 'SG',
    email: '',
    firstName: '',
    lastName: '',
    roleType: 'CounsellorExecutive'
  });

  useEffect(() => {
    if (selectedItem?.id) {
      setFormValue(selectedItem);
    }
  }, []);

  const handleChangeForm = key => (e, selectVal) => {
    let value = get(e, 'target.value');

    switch (key) {
      case 'mobile':
        value = e.value;
        break;

      default:
        break;
    }

    setFormValue({ ...formValue, [key]: value });
  };

  const handleSubmit = async () => {
    try {
      await validateData('counsellorEXSchema', formValue, () => {
        onSubmit(typeModal, formValue);
        setErrors({});
      });
    } catch (errs) {
      setErrors(errs);
    }
  };

  const isEmptyParams = useMemo(() => {
    const filterKeys = ['mobile', 'email', 'firstName', 'lastName'];
    return filterKeys.some(key =>
      isBoolean(formValue[key]) ? !formValue[key] : isEmpty(formValue[key])
    );
  }, [formValue]);

  return (
    <CustomDialog
      scroll="body"
      disableEnforceFocus
      open={open}
      onClose={onClose}
      disableBackdropClick
      maxWidth="md"
    >
      <CustomDialogTitle onClose={onClose}>
        {typeModal === TYPE_MODAL.Create ? 'Create new' : typeModal} counsellor
        executive
      </CustomDialogTitle>
      <CustomDialogContent dividers>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CustomTitleLabel>Personal information</CustomTitleLabel>
          </Grid>
          <Grid item xs={12}>
            <CustomTitleSubLabel>First name</CustomTitleSubLabel>
            <CustomTextField
              fullWidth
              variant="outlined"
              placeholder="Enter first name"
              value={formValue.firstName || ''}
              onChange={handleChangeForm('firstName')}
              error={errors.firstName}
              helperText={errors.firstName}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTitleSubLabel>Last name</CustomTitleSubLabel>
            <CustomTextField
              fullWidth
              variant="outlined"
              placeholder="Enter last name"
              value={formValue.lastName || ''}
              onChange={handleChangeForm('lastName')}
              error={errors.lastName}
              helperText={errors.lastName}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTitleSubLabel>Phone number</CustomTitleSubLabel>
            <Box display="flex" gridGap={8}>
              <CustomCountryCodeInput
                country={formValue.countryCode}
                disabled={false}
                onChange={handleChangeForm('countryCode')}
              />
              <NumberFormat
                customInput={CustomTextField}
                variant="outlined"
                placeholder="Enter number"
                onValueChange={handleChangeForm('mobile')}
                allowNegative={false}
                value={formValue.mobile || ''}
              />
            </Box>
            {errors.mobile && (
              <CustomFormHelperText error>{errors.mobile}</CustomFormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <CustomTitleSubLabel>Email</CustomTitleSubLabel>
            <CustomTextField
              fullWidth
              variant="outlined"
              placeholder="Enter email address"
              value={formValue.email || ''}
              onChange={handleChangeForm('email')}
              error={errors.email}
              helperText={errors.email}
            />
          </Grid>
        </Grid>
      </CustomDialogContent>

      <CustomDialogActions>
        <CustomButton
          type="submit"
          variant="contained"
          color="primary"
          onClick={() => handleSubmit()}
          disabled={isEmptyParams || loading}
        >
          {typeModal === TYPE_MODAL.Create ? 'Create' : 'Save'}
        </CustomButton>
      </CustomDialogActions>
    </CustomDialog>
  );
};

ModalAddOrEditCounsellorExecutive.propTypes = {
  typeModal: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  selectedItem: PropTypes.object
};

ModalAddOrEditCounsellorExecutive.defaultProps = {
  typeModal: TYPE_MODAL.Create,
  open: false,
  onClose: () => console.warn('Not implemented yet'),
  onSubmit: () => console.warn('Not implemented yet'),
  selectedItem: null
};

export default ModalAddOrEditCounsellorExecutive;
