import { makeStyles, withStyles } from '@material-ui/core';
import { ToggleButton } from '@material-ui/lab';
import classnames from 'classnames';
import React, { useState } from 'react';

const useStyles = makeStyles({
  root: {
    width: 'fit-content',
    height: 32,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    padding: '10px 12px',
    margin: '10px 5px',
    marginTop: 4,
    marginBottom: 4,
    borderRadius: 35,
    color: '#192637',
    fontSize: 14,
    lineHeight: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    textTransform: 'unset',
    background: '#FFFFFF'
  },
  active: {
    border: '2px solid #AD5E99',
    color: '#AD5E99'
  },
  inActive: {
    border: 'none',
    color: '#333',
    background: '#F1F1F1'
  }
});

const CustomToggleButton = withStyles(theme => ({
  root: {
    '&.Mui-selected': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.common.white
    }
  }
}))(ToggleButton);

const CustomChip = ({
  selectedData: value,
  onChange,
  label,
  selected,
  setSelected,
  ...props
}) => {
  const classes = useStyles();

  return (
    <CustomToggleButton
      value={label}
      selected={label === selected?.label && selected?.isSelected}
      onChange={e => {
        e.stopPropagation();
        setSelected({ label, isSelected: !selected.isSelected });
        onChange(label, !selected.isSelected);
      }}
      className={classnames(
        classes.root,
        label === selected?.label && selected.isSelected
          ? classes.active
          : classes.inActive
      )}
      {...props}
    >
      {label}
    </CustomToggleButton>
  );
};

export default CustomChip;
