import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const titleStyles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey.subText
  },
  text: {
    fontWeight: 600
  }
});

const CustomDialogTitle = withStyles(titleStyles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.text}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const CustomDialog = withStyles({
  paper: {
    borderRadius: 16,
    width: 400
  }
})(Dialog);

const CustomButton = withStyles({
  root: {
    width: 166,
    height: 40,
    borderRadius: 8,

    fontSize: 18,
    fontWeight: 400,
    textTransform: 'unset'
  },
  containedPrimary: {
    boxShadow: 'none'
  }
})(Button);

const CustomDialogContent = withStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}))(DialogContent);

const CustomDialogActions = withStyles({
  root: {
    margin: 0,
    padding: 16,
    justifyContent: 'flex-end'
  }
})(DialogActions);

const CustomInputLabel = withStyles(theme => ({
  root: {
    color: theme.palette.grey.mainText,
    fontWeight: 600,
    marginBottom: theme.spacing(1)
  }
}))(InputLabel);

const CustomTextFieldPrice = withStyles({
  root: {
    fontSize: 20,
    width: '100%',

    '& .MuiInputBase-input': {
      marginLeft: 0
    },

    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 6
    },

    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      height: 36
    },
    '& .MuiOutlinedInput-input': {
      fontSize: 16
    },
    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6'
      }
    }
  }
})(TextField);

const ModalEditBillableAmount = ({
  billableAmount,
  subTotal,
  open,
  onClose,
  onSubmit
}) => {
  const [localBillableAmount, setLocalBillableAmount] = useState(
    billableAmount
  );

  const handleChangeLocalBillableAmount = event => {
    const value = event.target.value;

    // Regex test number contain decimal. Maximum length number of decimal is 2
    const regexNumber = /^([\d]{0,9})(\.[\d]{1,2})?$/g;

    if (regexNumber.test(value)) {
      const billableAmount = parseFloat(value);
      setLocalBillableAmount(
        Number.isNaN(billableAmount) ? value : billableAmount
      );
    }
  };

  const error =
    Number.isNaN(localBillableAmount) || subTotal < localBillableAmount;

  return (
    <CustomDialog
      scroll="body"
      disableEnforceFocus
      open={open}
      onClose={onClose}
    >
      <CustomDialogTitle onClose={onClose}>
        Edit billable amount
      </CustomDialogTitle>
      <CustomDialogContent dividers>
        <CustomInputLabel>Billable amount</CustomInputLabel>

        <CustomTextFieldPrice
          type="number"
          variant="outlined"
          value={localBillableAmount}
          onChange={handleChangeLocalBillableAmount}
          error={error}
          helperText={
            error && 'Must be less than or equal to the total amount.'
          }
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>
          }}
        />
      </CustomDialogContent>

      <CustomDialogActions>
        <CustomButton
          type="submit"
          variant="contained"
          color="primary"
          disabled={error}
          onClick={() => onSubmit(parseFloat(localBillableAmount))}
        >
          Save
        </CustomButton>
      </CustomDialogActions>
    </CustomDialog>
  );
};

ModalEditBillableAmount.propTypes = {
  billableAmount: PropTypes.number,
  subTotal: PropTypes.number,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
};

ModalEditBillableAmount.defaultProps = {
  billableAmount: 0,
  subTotal: 0,
  open: false,
  onClose: () => console.warn('Not implemented yet'),
  onSubmit: () => console.warn('Not implemented yet')
};

export default ModalEditBillableAmount;
