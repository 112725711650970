import { Popover } from '@material-ui/core';
import React from 'react';

const CustomMenu = ({ children, anchorEl, onClose, open, id }) => {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      PaperProps={{
        style: {
          border: '1px solid #E1E1E1',
          boxShadow: '0 4px 16px 0 rgba(0,0,0,0.1)'
        }
      }}
    >
      {children}
    </Popover>
  );
};

export default CustomMenu;
