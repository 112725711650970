import { urlLabel } from '@/enum/PermissionEnum';
import { CAQueue } from '@/module/ca-queue';
import ClinicListPage from '@/module/clinic/pages/ClinicListPage';

export const clinicRoutes = [
  {
    path: `/${urlLabel.clinicManagement}`,
    component: ClinicListPage,
    exact: true
  },
  {
    path: `/${urlLabel.queueManagement}`,
    component: CAQueue,
    exact: true
  }
];
