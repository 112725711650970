import { AuthLayout } from '@/module/common';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const AuthRoute = ({ component: Component, publicComponent, ...rest }) => {
  return (
    <Route
      render={matchProps => {
        if (publicComponent || !rest.auth.accessToken)
          return (
            <AuthLayout>
              <Component {...matchProps} {...rest} />
            </AuthLayout>
          );
        return <Redirect to="/" />;
      }}
    />
  );
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    privilege: state.userPermission.privilege
  };
};

export const WrapperAuthRoute = connect(mapStateToProps)(AuthRoute);
