import telecounselAllCounselsDispatcher from '../action';

const initialState = {
  list: [],
  paging: {
    pageIndex: 0,
    pageSize: 10,
    totalItem: 0,
    totalPage: 0
  }
};

const telecounselAllCounselsReducer = telecounselAllCounselsDispatcher(
  initialState,
  {
    [telecounselAllCounselsDispatcher.getDataSuccess]: (state, payload) => ({
      list: payload.data.list,
      paging: {
        ...payload.data,
        list: undefined
      }
    }),
    [telecounselAllCounselsDispatcher.setState]: (state, payload) => ({
      [payload.state]: payload.value
    }),
    [telecounselAllCounselsDispatcher.resetData]: (state, _) => ({
      ...initialState
    })
  }
);

export default telecounselAllCounselsReducer;
