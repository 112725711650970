import { ArrowDown, CheckboxActive, CheckboxDefault } from '@/assets/svg';
import { CustomAutocomplete } from '@/module/company/component/CreateAndEditCompany/styled';
import {
  Box,
  Checkbox,
  Chip,
  Grid,
  Paper,
  TextField,
  Typography,
  makeStyles,
  withStyles
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import React from 'react';

const CustomTitleSubLabel = withStyles({
  root: {
    fontWeight: 600,
    color: '#666666'
  }
})(Typography);

const CustomTextField = withStyles({
  root: {
    fontSize: 20,

    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      minHeight: 48
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      paddingLeft: 5,
      fontSize: 16
    },

    '& .MuiInputBase-inputMultiline': {
      '&.MuiInputBase-input': {
        marginLeft: 0
      }
    },

    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6'
      }
    },

    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
      padding: 0,
      paddingLeft: 4,
      marginLeft: 0,
      height: 30
    },

    '& .MuiFormHelperText-contained': {
      marginLeft: 0
    }
  }
})(TextField);

const CustomPaper = withStyles(theme => ({
  root: {
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'transparent'
    },

    '& .MuiAutocomplete-option': {
      padding: 8,
      borderRadius: 8,
      margin: '2px 8px',
      '&:first-child': {
        marginTop: 8
      },
      '&:last-child': {
        marginBottom: 8
      },
      '&.MuiAutocomplete-option[aria-selected="true"]': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main
      }
    }
  }
}))(Paper);

const CustomLanguagePaper = withStyles(theme => ({
  root: {
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'transparent'
    },

    '& .MuiAutocomplete-option': {
      padding: 8,
      borderRadius: 8,
      margin: '2px 8px',
      '&:first-child': {
        marginTop: 8
      },
      '&:last-child': {
        marginBottom: 8
      },
      '&.MuiAutocomplete-option[aria-selected="true"]': {
        backgroundColor: theme.palette.common.white
      }
    }
  }
}))(Paper);

const useStyles = makeStyles(theme => ({
  isBtnSelected: {
    border: 'none',
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main
  },
  checkboxIcon: {
    padding: 0,
    marginRight: 4
  },
  tagStyle: {
    maxWidth: 'calc(100% - 60px)', // Adjust based on your needs
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  selectWrapper: {
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'transparent'
    },

    '& .MuiAutocomplete-option': {
      padding: 8,
      borderRadius: 8,
      margin: '2px 8px',
      '&:first-child': {
        marginTop: 8
      },
      '&:last-child': {
        marginBottom: 8
      },
      '&.MuiAutocomplete-option[aria-selected="true"]': {
        backgroundColor: '#ffffff'
      }
    }
  }
}));

const CounsellingExperienceStep = ({ formValue, handleChangeForm }) => {
  const classes = useStyles();

  const yearExperiences = Array.from(Array(50), (_, i) => String(i + 1));
  const listLanguages = ['English', 'Malay', 'Mandarin', 'Tamil'];

  return (
    <Box px={2.25} py={2.5}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <CustomTitleSubLabel>Years of experience</CustomTitleSubLabel>

          <CustomAutocomplete
            getOptionSelected={(option, value) => option === value}
            getOptionLabel={option => option || ''}
            options={yearExperiences || []}
            value={
              formValue.yearOfExperience === '0'
                ? ''
                : formValue.yearOfExperience
            }
            onChange={handleChangeForm('yearOfExperience')}
            popupIcon={<ArrowDown />}
            renderInput={params => (
              <CustomTextField
                variant="outlined"
                placeholder="Select number"
                {...params}
              />
            )}
            disableClearable
            PaperComponent={({ children, ...other }) => (
              <CustomPaper {...other}>{children}</CustomPaper>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTitleSubLabel>Language</CustomTitleSubLabel>

          <CustomAutocomplete
            multiple
            disableCloseOnSelect
            value={formValue?.language}
            onChange={handleChangeForm('language')}
            options={listLanguages}
            getOptionLabel={option => option}
            renderInput={params => (
              <CustomTextField
                {...params}
                variant="outlined"
                placeholder={
                  isEmpty(formValue?.language) ? 'Select any language' : ''
                }
              />
            )}
            renderOption={(option, { selected }) => {
              return (
                <Box display="flex" gridGap={4}>
                  <Checkbox
                    icon={<CheckboxDefault width={20} height={20} />}
                    checkedIcon={<CheckboxActive width={20} height={20} />}
                    checked={selected}
                    color="primry"
                    className={classes.checkboxIcon}
                  />
                  <span>{option}</span>
                </Box>
              );
            }}
            renderTags={(value, getTagProps) => {
              return <Box fontSize={16}>{value.join(', ')}</Box>;
            }}
            PaperComponent={({ children, ...other }) => (
              <CustomLanguagePaper {...other}>{children}</CustomLanguagePaper>
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <CustomTitleSubLabel>Therapeutic approach</CustomTitleSubLabel>

          <CustomAutocomplete
            multiple
            freeSolo
            value={formValue.treatmentApproach}
            options={[]}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={params => (
              <CustomTextField
                {...params}
                variant="outlined"
                placeholder="Type Cognitive behavioural and press Enter"
              />
            )}
            onChange={handleChangeForm('treatmentApproach')}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTitleSubLabel>Specialities</CustomTitleSubLabel>

          <CustomAutocomplete
            multiple
            freeSolo
            value={formValue.speciality}
            options={[]}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                return (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                );
              })
            }
            renderInput={params => (
              <CustomTextField
                {...params}
                variant="outlined"
                placeholder="Type Depression and press Enter"
              />
            )}
            onChange={handleChangeForm('speciality')}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CounsellingExperienceStep;
