import CustomButton from '../CustomButton';
import CustomModalTitle from './CustomModalTitle';
import Image from '@/components/Image';
import { renderIf } from '@/helpers';
import { approvePatient } from '@/helpers/telecounsellingRoomSignalR';
import { DOCTOR_STATUS } from '@/module/telecounsel-queue/constants';
import { Box, Card, Divider } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import React from 'react';

const CustomDialog = withStyles(theme => ({
  paper: {
    borderRadius: 16,
    width: 800
  }
}))(Dialog);

const CustomDialogContent = withStyles(theme => ({
  root: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}))(DialogContent);

const CustomDialogActions = withStyles({
  root: {
    margin: 0,
    padding: 16,
    justifyContent: 'flex-end'
  }
})(DialogActions);

const CustomTypography = withStyles({
  root: {
    color: '#333333',
    fontWeight: 600,
    fontSize: 16,
    marginBottom: 8
  }
})(Typography);

const CustomImageTitle = withStyles({
  root: {
    color: '#666666',
    fontWeight: 500,
    fontSize: 12,
    marginTop: 12,
    textAlign: 'center'
  }
})(Typography);

const CustomRow = withStyles({
  root: {
    display: 'flex',
    gap: 80,

    '& .title': {
      color: '#666666',
      fontWeight: 500
    },
    '& .text': {
      fontSize: 16
    }
  }
})(Box);

const CustomCard = withStyles({
  root: {
    padding: 15.5,
    borderRadius: 8,
    backgroundColor: '#f6f6f6',
    border: 'none'
  }
})(Card);

const useStyles = makeStyles({
  image: {
    width: 540,
    height: 222,
    borderRadius: 8
  }
});

const ModalVerifyID = ({
  open,
  onClose,
  verifyingUserData,
  doCalling,
  closeModal,
  setShowModalReject
}) => {
  const classes = useStyles();

  const doVerify = async () => {
    approvePatient(verifyingUserData.UserId);
    doCalling({ data: verifyingUserData });
    closeModal();
  };

  const doReject = () => setShowModalReject(true);

  return (
    <CustomDialog
      maxWidth="lg"
      scroll="body"
      disableEnforceFocus
      open={open}
      onClose={onClose}
      disableBackdropClick
    >
      <CustomModalTitle onClose={onClose}>Verify ID</CustomModalTitle>
      <CustomDialogContent dividers>
        <Grid container spacing={2}>
          {renderIf(
            verifyingUserData.IdentityStatus !== DOCTOR_STATUS.Approved
          )(
            <>
              <Grid item xs={12}>
                <CustomTypography>
                  Please verify if the client’s ID information matches our
                  record
                </CustomTypography>
              </Grid>
              <Grid item xs={12}>
                <CustomRow>
                  <div className="title">ID number</div>
                  <div className="text">{verifyingUserData.IdentityNumber}</div>
                </CustomRow>
              </Grid>
              <Grid item xs={12}>
                <CustomCard variant="outlined">
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Image
                        src={verifyingUserData.FrontIdentityUrl}
                        alt="Client uploaded ID's frontside"
                        className={classes.image}
                      />
                      <CustomImageTitle>Front of ID card</CustomImageTitle>
                    </Grid>
                    <Grid item xs={6}>
                      <Image
                        src={verifyingUserData.BackIdentityUrl}
                        alt="Client uploaded ID's backside"
                        className={classes.image}
                      />
                      <CustomImageTitle>Back of ID card</CustomImageTitle>
                    </Grid>
                  </Grid>
                </CustomCard>
              </Grid>
            </>
          )}

          {renderIf(
            verifyingUserData.CorperatePlan &&
              verifyingUserData.IdentityStatus !== DOCTOR_STATUS.Approved &&
              verifyingUserData.FrontIdentityUrl &&
              verifyingUserData.BackIdentityUrl
          )(
            <Grid item xs={12}>
              <Divider />
            </Grid>
          )}

          {renderIf(verifyingUserData.CorperatePlan)(
            <Grid item xs={12}>
              <CustomRow>
                <div className="title">Corporate plan</div>
                <div className="text">{verifyingUserData.CorperatePlan}</div>
              </CustomRow>
            </Grid>
          )}
          {renderIf(
            verifyingUserData.FrontCorperatePlan &&
              verifyingUserData.BackCorperatePlan
          )(
            <Grid item xs={12}>
              <CustomCard variant="outlined">
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Image
                      src={verifyingUserData.FrontCorperatePlan}
                      alt="FrontCorperatePlan"
                      className={classes.image}
                    />
                    <CustomImageTitle>
                      Front of Corperate Plan card
                    </CustomImageTitle>
                  </Grid>
                  <Grid item xs={6}>
                    <Image
                      src={verifyingUserData.BackCorperatePlan}
                      alt="BackCorperatePlane"
                      className={classes.image}
                    />
                    <CustomImageTitle>
                      Back of Corperate Plan card
                    </CustomImageTitle>
                  </Grid>
                </Grid>
              </CustomCard>
            </Grid>
          )}
        </Grid>
      </CustomDialogContent>
      <CustomDialogActions>
        {verifyingUserData.IdentityStatus !== DOCTOR_STATUS.Approved ? (
          <>
            <CustomButton
              type="submit"
              variant="contained"
              color="error"
              onClick={doReject}
            >
              Reject
            </CustomButton>
            <CustomButton
              type="submit"
              variant="contained"
              color="primary"
              onClick={doVerify}
            >
              Approve
            </CustomButton>
          </>
        ) : (
          <CustomButton
            type="submit"
            variant="contained"
            color="primary"
            onClick={() => {
              doCalling({ data: verifyingUserData });
            }}
          >
            Continue
          </CustomButton>
        )}
      </CustomDialogActions>
    </CustomDialog>
  );
};

export default ModalVerifyID;
