import { ALL_COUNSELLOR_STATUSES } from '../constants';
import CustomSelectContainer from '@/new-components/CustomSelectOld/CustomSelectContainer';
import { Box, Button, Toolbar, withStyles } from '@material-ui/core';
import { isBoolean, isEmpty } from 'lodash';
import React, { useMemo } from 'react';

const CustomButton = withStyles({
  label: {
    fontWeight: 700
  }
})(Button);

const GroupToolbar = ({
  filterOption,
  onResetFilterOps,
  counsellorsStatuses,
  setCounsellorsStatuses,
  onFilterOptionChange
}) => {
  const renderValue = (array, label, defaultLabel) => {
    let value = '';
    if (array.length === 0) value = defaultLabel;
    else if (array.length === 1) value = array[0];
    else value = `${label} (${array.length})`;

    return value;
  };

  const isEmptyFilterParams = useMemo(() => {
    const filterKeys = Object.keys(filterOption);

    return filterKeys.every(key =>
      isBoolean(filterOption[key])
        ? !filterOption[key]
        : isEmpty(filterOption[key])
    );
  }, [filterOption]);

  return (
    <Toolbar>
      <Box
        display="flex"
        flex={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <CustomSelectContainer
          isFullWidth
          filterValue={counsellorsStatuses || []}
          data={ALL_COUNSELLOR_STATUSES}
          renderValue={renderValue(
            counsellorsStatuses,
            'Statuses',
            'Select status'
          )}
          onChange={onFilterOptionChange(
            'counsellorsStatuses',
            setCounsellorsStatuses
          )}
          labelSelectAll={'All statuses'}
          useSelectAll
          checkedAll={
            ALL_COUNSELLOR_STATUSES.length === counsellorsStatuses.length
          }
        />
        <CustomButton
          color="primary"
          disabled={isEmptyFilterParams}
          onClick={onResetFilterOps}
        >
          Reset
        </CustomButton>
      </Box>
    </Toolbar>
  );
};

export default GroupToolbar;
