import './styles.scss';
import { SUB_MENU } from '@/constants/subMenuBreadcrumb';
import { Breadcrumb } from 'antd';
import _ from 'lodash';
import React, { PureComponent } from 'react';
import { withRouter, NavLink } from 'react-router-dom';

class BreadcrumbAnt extends PureComponent {
  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { location: prevLocation } = prevProps;
    if (
      prevLocation.pathname === location.pathname &&
      prevLocation.search === location.search &&
      prevLocation.hash === location.hash &&
      prevLocation.key !== location.key
    ) {
      window.location.reload();
    }
  }
  render() {
    let xhtml = null;
    const { hidden, location } = this.props;
    const routes = location.pathname.split('/').filter(i => i);
    const breadcrumbs = (routes || []).map((item, index) => {
      const router = `/${routes.slice(0, index + 1).join('/')}`;
      let text = item.replace(/-/g, ' ').replace(/\w+/g, _.capitalize);
      if (text === 'Ca') text = 'CA';
      return {
        text,
        router
      };
    });

    if (!hidden) {
      xhtml = (
        <Breadcrumb className="breadcrum-and--wrapper">
          {(breadcrumbs || []).map(br => {
            return (
              br.router && (
                <Breadcrumb.Item key={br.router}>
                  {SUB_MENU.includes(br.text) ? (
                    br.text.replace('_', ' ')
                  ) : breadcrumbs[breadcrumbs.length - 1] === br ? (
                    <NavLink
                      to={`${br.router}`}
                      activeStyle={{
                        color: '#192637',
                        fontWeight: 600
                      }}
                    >
                      {br.text.replace('_', ' ')}
                    </NavLink>
                  ) : (
                    br.text.replace('_', ' ')
                  )}
                </Breadcrumb.Item>
              )
            );
          })}
        </Breadcrumb>
      );
    }
    return xhtml;
  }
}

export default withRouter(BreadcrumbAnt);
