import {
  AddImageOutlineIcon,
  CheckboxActive,
  CheckboxDefault,
} from '../../../assets/svg';
import PhoneInput from '../../../components/PhoneInput';
import { getNumberInfo, renderUnitNumber } from '../../../helpers';
import {
  checkRegexFullName,
  validateDateOfBirth,
  validateEmail,
  validateIdentity,
  validateMobile,
  validateNRIC,
} from '../../../utils/validation';
import { UploadSingle } from '../../common/component';
import ControlDatepicker from '../../common/component/ControlDatepicker';
import { ButtonEnhance } from '../../common/componentUI/commonStyleComponents';
import patientDispatcher from '../actions/index';
import {
  FORM_VALUE,
  LIST_REQUIRED,
  LIST_REQUIRED_CHILDREN,
  LIST_LV1_REQUIRED,
  NATIONALITY,
  renderCorporateColumn,
} from '../constants';
import { doctorStatus } from '@/enum/RequestEnum';
import { ALPHA_NUMERIC_REGEX } from '@/helpers/validationHelpers/validationSchema';
import { FORMAT_DD_MM_YYYY } from '@/module/all-users/constants';
import FiltedBodyCell from '@/module/common/component/FiltedBodyCell';
import InlineTable from '@/module/common/component/InlineTable';
import TableCellFilted from '@/module/common/componentUI/TableCellFilted';
import globalDispatcher from '@/redux/global/actions';
import { Checkbox, FormControlLabel, IconButton } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import { ClearRounded, Delete } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { LocalizationProvider } from '@material-ui/pickers';
import MomentUtils from '@material-ui/pickers/adapter/moment';
import { get, isEmpty, set, cloneDeep } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const FormPatientPage = ({
  lstIdentityUrl,
  patientDetail,
  closeModal,
  setShowModalForm,
  reGetListPatient,
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [formValue, setFormValue] = useState(FORM_VALUE);
  const [isChildren, setIsChildren] = useState(false);
  const [unitNumber, setUnitNumber] = useState('');
  const [hiddenRows, setHiddenRows] = useState(false);

  useEffect(() => {
    if (!isEmpty(patientDetail)) {
      const unitNumber = renderUnitNumber(
        patientDetail.floor,
        patientDetail.unitNumber
      );
      setUnitNumber(unitNumber);
      setFormValue({
        fullName: patientDetail.fullName || '',
        email: patientDetail.email || '',
        notificationEmail: patientDetail.notificationEmail || '',
        dateOfBirth: patientDetail.dateOfBirth || '',
        gender: patientDetail.gender || '',
        address: patientDetail.address || '',
        mobile: patientDetail.mobile || '',
        nationality: patientDetail.nationality || '',
        unitNumber: patientDetail.unitNumber || '',
        countryCode: patientDetail.countryCode || '',
        userId: patientDetail.id || '',
        lstIdentityUrl: patientDetail.lstIdentityUrl,
        identityNumber: patientDetail.identityNumber,
        latitude: patientDetail.latitude || 0,
        longitude: patientDetail.longitude || 0,
        identityType: patientDetail.identityType || 0,
        corporatePlans: patientDetail.corporatePlans || [],
        postalCode: patientDetail.postalCode || '',
        floor: patientDetail.floor || '',
        projectCode: patientDetail.projectCode,
        ignoredUnitNumber: patientDetail.ignoredUnitNumber || false,
      });

      if (!patientDetail.email) {
        setIsChildren(true);
      }
    }
  }, [patientDetail]);

  const onCheckValidForm = () => {
    let valid = true;
    let errors = {};
    let listRequired = [];
    setIsSubmitted(true);
    if (isChildren) {
      listRequired = LIST_REQUIRED_CHILDREN;
    } else if (!patientDetail.isRegisterLv2) {
      listRequired = LIST_LV1_REQUIRED;
    } else {
      listRequired = LIST_REQUIRED;
    }
    listRequired.forEach((field) => {
      if (!formValue[field]) {
        errors = { ...errors, [field]: 'Required' };

        valid = false;
      }
    });

    if (formValue.fullName) {
      const isValidFullName = checkRegexFullName(formValue.fullName);
      if (!isValidFullName) {
        errors = {
          ...errors,
          fullName:
            'Full Name must contain letters only or letters combined with special characters',
        };
        valid = false;
      }
    }

    const statusDateOfBirth = validateDateOfBirth(formValue.dateOfBirth);
    if (statusDateOfBirth && !statusDateOfBirth.valid) {
      errors = { ...errors, dateOfBirth: statusDateOfBirth.message };
      valid = false;
    }
    if (formValue.email) {
      const validEmail = validateEmail(formValue.email);
      if (!validEmail) {
        errors = { ...errors, email: 'Invalid email' };
        valid = false;
      }
    }
    if (formValue.notificationEmail) {
      const validEmail = validateEmail(formValue.notificationEmail);
      if (!validEmail) {
        errors = { ...errors, notificationEmail: 'Invalid email' };
        valid = false;
      }
    }
    if (formValue.mobile) {
      const validMobile = validateMobile(
        formValue.mobile,
        formValue.countryCode
      );
      if (!validMobile) {
        errors = { ...errors, mobile: 'Invalid mobile' };
        valid = false;
      }
    }

    //
    if (!patientDetail.isRegisterLv2 && !isChildren) {
      setErrors(errors);
      return valid;
    }
    //
    if (!formValue.identityType) {
      errors = { ...errors, identityType: 'Type of ID is required' };
      valid = false;
      setErrors(errors);
      return valid;
    }

    if (formValue.identityNumber) {
      const validIdentityNumber =
        formValue.identityType === 'NRIC'
          ? validateNRIC(formValue.identityNumber)
          : validateIdentity(formValue.identityNumber);

      if (!validIdentityNumber) {
        errors = { ...errors, identityNumber: 'Invalid Identity Number' };
        valid = false;
      }
    }

    if (patientDetail.isRegisterLv2 && !isChildren) {
      if (!isEmpty(formValue.corporatePlans)) {
        let newCorporatePlans = cloneDeep(formValue.corporatePlans);

        newCorporatePlans = newCorporatePlans.map((item) => {
          if (!item.name) {
            valid = false;
            return {
              ...item,
              errors: {
                ...errors,
                corporateName: 'Corporate Name is required',
              },
            };
          } else return item;
        });

        formValue.corporatePlans = newCorporatePlans;
      }
    }
    // Unit Number
    if (
      !formValue.ignoredUnitNumber &&
      isEmpty(formValue.floor) &&
      isEmpty(formValue.unitNumber) &&
      !isEmpty(formValue.address)
    ) {
      errors = {
        ...errors,
        unitNumber: 'Unit Number is required',
      };
      valid = false;
    } else if (
      !formValue.ignoredUnitNumber &&
      !isEmpty(formValue.address) &&
      (!(
        (!isEmpty(formValue.floor) && !isEmpty(formValue.unitNumber)) ||
        (isEmpty(formValue.floor) && isEmpty(formValue.unitNumber))
      ) ||
        (!isEmpty(formValue.floor) &&
          !isEmpty(formValue.unitNumber) &&
          (formValue.floor?.length !== 2 ||
            !(unitNumber.indexOf('#') === 0 && unitNumber.indexOf('-') === 3))))
    ) {
      // Must be enter floor and unit number together and
      // If enter both floor and number, musst be follow format #[Floor(2 characters)]-[Unit]
      errors = {
        ...errors,
        unitNumber: 'Invalid Unit Number',
      };
      valid = false;
    }

    if (!formValue.address && patientDetail.hasDefaultAddress) {
      errors = {
        ...errors,
        address: 'Can not delete default delivery address',
      };
      valid = false;
    }

    setErrors(errors);
    return valid;
  };

  const handleChange = (data, field) => {
    let value;
    if (field === 'dateOfBirth') {
      value = moment(data).startOf('date').format();
    } else value = data;

    switch (field) {
      case 'identityType':
        setFormValue({
          ...formValue,
          [field]: value,
          identityNumber: '',
        });
        break;

      case 'ignoredUnitNumber':
        setFormValue({
          ...formValue,
          [field]: value,
          floor: '',
          unitNumber: '',
        });
        setUnitNumber('');
        break;
      default:
        setFormValue({
          ...formValue,
          [field]: value,
        });
        break;
    }
  };

  const handleChangeIdentity = (data, field) => {
    const payload = cloneDeep(formValue);
    set(payload, field, data);
    setFormValue(payload);
  };

  const handleChangeCorporate = (data, field, id) => {
    const payload = cloneDeep(formValue);
    const corporatePlans = payload.corporatePlans.map((item) =>
      item.id === id ? { ...item, [field]: data } : { ...item }
    );
    setFormValue({ ...payload, corporatePlans });
  };

  const handleDeleteCorporate = (id) => {
    const payload = cloneDeep(formValue);
    const corporatePlans = payload.corporatePlans.filter(
      (item) => item.id !== id
    );
    setFormValue({ ...payload, corporatePlans });
  };
  const savePatient = async () => {
    if (!onCheckValidForm()) {
      return;
    }
    setErrors({});
    const corporatePlans = cloneDeep(formValue.corporatePlans).map((item) =>
      item.errors ? delete item.errors : item
    );
    formValue.corporatePlans = corporatePlans;
    const mobile = getNumberInfo(formValue.mobile, formValue.countryCode)[0];

    if (!isEmpty(formValue.lstIdentityUrl)) {
      try {
        const lstIdentityUrl = await uploadFiles(formValue.lstIdentityUrl);
        patientDispatcher.updatePatient(
          { ...formValue, lstIdentityUrl, mobile },
          () => {
            setShowModalForm(false);
            reGetListPatient();
          }
        );
      } catch (error) {
        console.error('Failed to upload files:', error);
      }
    } else {
      patientDispatcher.updatePatient({ ...formValue, mobile }, () => {
        setShowModalForm(false);
        reGetListPatient();
      });
    }
  };

  const uploadFileAndGetURL = async (file) => {
    const timeStamp = new Date().getTime();
    const fileName = `${timeStamp}${file.name}`;
    const formdata = new FormData();
    formdata.append('file', file, fileName);

    return new Promise((resolve, reject) => {
      globalDispatcher.uploadIdentityImage(formdata, (result) => {
        if (result) {
          resolve(result);
        } else {
          reject('Upload failed');
        }
      });
    });
  };

  const uploadFiles = async (fileList) => {
    const uploadPromises = fileList.map((file) => {
      if (file?.constructor === File) {
        return uploadFileAndGetURL(file);
      }
      return Promise.resolve(file); // Trả về file nếu nó không phải là một đối tượng File
    });

    return Promise.all(uploadPromises);
  };
  const handleSelectAddress = (data) => {
    setFormValue({
      ...formValue,
      address: data.description || '',
      latitude: data.lat || 0,
      longitude: data.lng || 0,
    });
  };

  const handleChangeUnitNumber = (e) => {
    const value = e.target.value;
    let floor = value.slice(0, 3).replace('#', '') || '';
    let unitNumber = value.slice(3).replace('-', '') || '';

    if (
      !(
        !ALPHA_NUMERIC_REGEX.test(floor) ||
        !ALPHA_NUMERIC_REGEX.test(unitNumber)
      )
    ) {
      setUnitNumber(value);
      setFormValue({
        ...formValue,
        unitNumber,
        floor,
      });
    }
  };

  const handleInputUnitNumber = (e) => {
    if (
      formValue.floor &&
      e.target.value.indexOf('#') === 0 &&
      e.target.value.length === 3 &&
      !unitNumber.includes('-')
    ) {
      // Need input #[2 characters] and the dash will be auto-gen
      e.target.value = e.target.value.toString() + '-';
    }
  };

  const columnCorporatePlans = renderCorporateColumn();

  const TableCellFiltedWrapper = ({ ...props }) => (
    <TableCellFilted
      {...{
        hiddenRows,
        setHiddenRows,
      }}
      {...props}
    />
  );

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        {columnCorporatePlans.map((item) =>
          item.label ? (
            <TableCellFiltedWrapper
              key={item.stateValue}
              label={item.label}
              stateValue={item.stateValue}
            />
          ) : (
            <TableCell key={item.stateValue}></TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );

  const renderRow = () => (row) =>
    (
      <>
        <FiltedBodyCell hidden={hiddenRows.name}>
          <WrapperInput
            error={isSubmitted && row?.errors?.corporateName ? true : false}
            helperText={isSubmitted && row?.errors?.corporateName}
            value={row.name || ''}
            name="corporateName"
            variant="standard"
            onChange={(e) =>
              handleChangeCorporate(e.target.value, 'name', row.id)
            }
          />
        </FiltedBodyCell>
        <FiltedBodyCell hidden={hiddenRows.frontPhoto}>
          <UploadSingle
            accept=".jpg,.png,.jpeg"
            typeUpload="normal"
            onChange={(data) =>
              handleChangeCorporate(data, 'frontPhoto', row.id)
            }
            imageUrlSelected={get(row, 'frontPhoto')}
          />
        </FiltedBodyCell>
        <FiltedBodyCell hidden={hiddenRows.backPhoto}>
          <UploadSingle
            accept=".jpg,.png,.jpeg"
            typeUpload="normal"
            onChange={(data) =>
              handleChangeCorporate(data, 'backPhoto', row.id)
            }
            imageUrlSelected={get(row, 'backPhoto')}
          />
        </FiltedBodyCell>
        <TableCell>
          <IconButton onClick={() => handleDeleteCorporate(row.id)}>
            <Delete />
          </IconButton>
        </TableCell>
      </>
    );

  return (
    <>
      <HeaderModal>
        <div>Update Patient</div>
        <ClearRounded onClick={closeModal} className="icon-close" />
      </HeaderModal>
      <DialogContent>
        <WrapperForm>
          <HeaderSection>1. Personal Information</HeaderSection>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <WrapperInput
                error={isSubmitted && errors.fullName ? true : false}
                helperText={isSubmitted && errors.fullName}
                value={formValue.fullName || ''}
                name="fullName"
                label="Full Name"
                variant="standard"
                onChange={(e) => handleChange(e.target.value, 'fullName')}
              />
            </Grid>
            {!isChildren && (
              <Grid item xs={6}>
                <WrapperInput
                  error={isSubmitted && errors.email ? true : false}
                  helperText={isSubmitted && errors.email}
                  value={formValue.email || ''}
                  name="email"
                  label="Email"
                  variant="standard"
                  onChange={(e) => handleChange(e.target.value, 'email')}
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <WrapperInput
                error={isSubmitted && errors.notificationEmail ? true : false}
                helperText={isSubmitted && errors.notificationEmail}
                value={formValue.notificationEmail || ''}
                name="notificationEmail"
                label="Notification Email"
                variant="standard"
                onChange={(e) =>
                  handleChange(e.target.value, 'notificationEmail')
                }
              />
            </Grid>
            <LocalizationProvider dateAdapter={MomentUtils}>
              <ControlDatepicker
                value={formValue.dateOfBirth}
                onChange={(date) => handleChange(date, 'dateOfBirth')}
                label="Date Of Birth"
                error={isSubmitted && errors.dateOfBirth}
                inputFormat={FORMAT_DD_MM_YYYY}
                disableFuture
              />
            </LocalizationProvider>
            <Grid item xs={6}>
              <InputLabel id="id-label-gender">Gender</InputLabel>
              <SelectCustom
                className="select-custom"
                labelId="id-label-gender"
                value={formValue.gender || ''}
                onChange={(e) => handleChange(e.target.value, 'gender')}
              >
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
              </SelectCustom>
              {isSubmitted && <FormHelperText>{errors.gender}</FormHelperText>}
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                options={NATIONALITY}
                value={formValue.nationality || ''}
                autoComplete
                onChange={(e, newValue) =>
                  handleChange(newValue, 'nationality')
                }
                renderInput={(params) => (
                  <TextField {...params} label="Nationality" />
                )}
              />
              {errors.roleType && (
                <FormHelperText>{errors.gender}</FormHelperText>
              )}
            </Grid>

            {patientDetail.isRegisterLv2 && (
              <>
                <Grid item xs={6}>
                  <WrapperInput
                    error={isSubmitted && errors.address ? true : false}
                    helperText={isSubmitted && errors.address}
                    value={formValue.address || ''}
                    name="address"
                    label="Address"
                    variant="standard"
                    onChange={(e) => handleChange(e.target.value, 'address')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <WrapperInput
                    error={errors.unitNumber}
                    helperText={errors.unitNumber}
                    value={unitNumber || ''}
                    label="Unit Number"
                    variant="standard"
                    placeholder="#01-123"
                    onChange={handleChangeUnitNumber}
                    onInput={handleInputUnitNumber}
                    disabled={formValue?.ignoredUnitNumber}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formValue?.ignoredUnitNumber}
                        icon={<CheckboxDefault />}
                        checkedIcon={<CheckboxActive />}
                        color="primary"
                        onChange={(e) =>
                          handleChange(e.target.checked, 'ignoredUnitNumber')
                        }
                      />
                    }
                    label="The address doesn't have a unit number"
                  />
                </Grid>
                <Grid item xs={6}>
                  <WrapperInput
                    error={isSubmitted && errors.postalCode ? true : false}
                    variant="standard"
                    value={formValue.postalCode}
                    onChange={(e) => handleChange(e.target.value, 'postalCode')}
                    label="Postal Code"
                    helperText={isSubmitted && errors.postalCode}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">S</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </>
            )}

            <Grid item xs={6}>
              <WrapperInput
                disabled={isChildren}
                error={isSubmitted && errors.mobile ? true : false}
                helperText={isSubmitted && errors.mobile}
                className="input-custom"
                variant="standard"
                label="Phone"
                type="number"
                onChange={(e) => handleChange(e.target.value, 'mobile')}
                value={formValue.mobile || ''}
                InputProps={{
                  startAdornment: (
                    <InputAdornment>
                      <PhoneInput
                        disabled={isChildren}
                        country={formValue.countryCode || ''}
                        onChange={(e) =>
                          handleChange(e.target.value, 'countryCode')
                        }
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          {patientDetail.isRegisterLv2 && (
            <>
              <HeaderSection>2. NRIC/Passport</HeaderSection>

              <Grid container spacing={5}>
                <Grid item xs={6}>
                  <FormControl error={errors.identityType} fullWidth>
                    <InputLabel id="id-label-id-type">Type of ID</InputLabel>
                    <SelectCustom
                      className="select-custom"
                      labelId="id-label-id-type"
                      value={formValue.identityType || ''}
                      onChange={(e) =>
                        handleChange(e.target.value, 'identityType')
                      }
                    >
                      <MenuItem value="NRIC">NRIC/FIN</MenuItem>
                      <MenuItem value="Passport">Passport</MenuItem>
                    </SelectCustom>
                    {isSubmitted && (
                      <FormHelperText>{errors.identityType}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <WrapperInput
                    error={isSubmitted && errors.identityNumber ? true : false}
                    helperText={isSubmitted && errors.identityNumber}
                    value={formValue.identityNumber || ''}
                    name="identityNumber"
                    label="Identity Number"
                    variant="standard"
                    onChange={(e) =>
                      handleChange(e.target.value, 'identityNumber')
                    }
                  />
                </Grid>
              </Grid>
              {patientDetail.approveStatus === doctorStatus.Approved ? null : (
                <Grid container spacing={5}>
                  <Grid item xs={6}>
                    <UploadSingle
                      typeUpload="normal"
                      onChange={(data) =>
                        handleChangeIdentity(data, 'lstIdentityUrl[0]')
                      }
                      imageUrlSelected={
                        !isEmpty(lstIdentityUrl) && lstIdentityUrl[0]
                      }
                      uploadButton={
                        <WrapperButtonUpload>
                          <span className="icon">
                            <AddImageOutlineIcon color="#ccc" />
                          </span>{' '}
                          <span className="text">Add Programme Picture</span>
                        </WrapperButtonUpload>
                      }
                      accept=".jpg,.png,.jpeg"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <UploadSingle
                      typeUpload="normal"
                      onChange={(data) =>
                        handleChangeIdentity(data, 'lstIdentityUrl[1]')
                      }
                      imageUrlSelected={
                        !isEmpty(lstIdentityUrl) && lstIdentityUrl[1]
                      }
                      uploadButton={
                        <WrapperButtonUpload>
                          <span className="icon">
                            <AddImageOutlineIcon color="#ccc" />
                          </span>{' '}
                          <span className="text">Add Programme Picture</span>
                        </WrapperButtonUpload>
                      }
                      accept=".jpg,.png,.jpeg"
                    />
                  </Grid>
                </Grid>
              )}
            </>
          )}
          {patientDetail.isRegisterLv2 && !isChildren && (
            <>
              <HeaderSection>3. Corporate Pictures</HeaderSection>
              <InlineTable
                Header={TableHeader}
                data={formValue.corporatePlans || []}
                renderRow={renderRow()}
                style={{ paddingTop: 0 }}
                noDataHelperText={`This patient does't have any corporate information.`}
              />
            </>
          )}
        </WrapperForm>
      </DialogContent>

      <DialogActions>
        <ButtonEnhance
          background="#E9E9E9"
          color="black"
          backgroundHover="#ccc"
          onClick={closeModal}
        >
          Cancel
        </ButtonEnhance>
        <ButtonEnhance onClick={savePatient}>Save</ButtonEnhance>
      </DialogActions>
    </>
  );
};

FormPatientPage.propTypes = {
  closeModal: PropTypes.func,
  patientDetail: PropTypes.object,
  approveStatus: PropTypes.func,
};

FormPatientPage.defaultProps = {
  patientDetail: {},
  closeModal: () => null,
  approveStatus: () => null,
};

const WrapperButtonUpload = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .text {
    color: #ccc;
  }
  min-height: 200px;
`;

const SelectCustom = styled(Select)`
  width: 100%;
`;

const HeaderModal = styled(DialogTitle)`
  border-bottom: 1px solid rgb(202, 207, 211);
  color: rgb(25, 38, 55);
  padding: 20px;
  width: 100%;
  .icon-close {
    cursor: pointer;
  }
  h2 {
    font-weight: 600;
    font-size: 18px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;
const WrapperForm = styled.div`
  font-size: 14;
  flex-grow: 1;
`;
const HeaderSection = styled.div`
  color: #192637;
  font-weight: 600;
  margin: 10px 0px;
`;
const WrapperInput = styled(TextField)`
  width: 100%;
`;
export default FormPatientPage;
