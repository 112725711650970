export const COUNSEL_APPOINTMENT_STATUS = [
  {
    value: 'Upcoming',
    key: 'Upcoming'
  },
  {
    value: 'In Progress',
    key: 'InProgress'
  },
  {
    value: 'Completed',
    key: 'Completed'
  },
  {
    value: 'Cancelled',
    key: 'Cancelled'
  },
  {
    value: 'Rejected',
    key: 'Rejected'
  },
  {
    value: 'Missed',
    key: 'Missed'
  }
];

export const COLUMN_COUNSEL_APPOINTMENT = [
  {
    label: 'Name',
    stateValue: 'name'
  },
  {
    label: 'Client ID',
    stateValue: 'clientId'
  },
  {
    label: 'ID number',
    stateValue: 'idNumber'
  },
  {
    label: 'Counsellor',
    stateValue: 'counsellor'
  },
  {
    label: 'Date & Time',
    stateValue: 'date',
    sortBy: 'Date'
  },
  {
    label: 'Status',
    stateValue: 'status'
  },
  {
    label: 'Action',
    stateValue: 'action',
    width: 211
  }
];

export const renderCounselApptCol = isCounsellor => {
  let list = [...COLUMN_COUNSEL_APPOINTMENT];
  if (isCounsellor) {
    list = list.filter(it => it.stateValue !== 'counsellor');
  }
  return list;
};

export const TYPE_MODAL = {
  AssignDoctor: 'AssignDoctor',
  Edit: 'Edit',
  View: 'View',
  Create: 'Create'
};
